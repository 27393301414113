import DragModal from './DragModal';
import {
  DialogContent,
  Divider,
  Autocomplete,
  Typography,
  TextField,
  CircularProgress,
  Button,
  Grid,
} from '@mui/material';
import { TagListContext } from '../contexts/TagListContext';
import { useContext } from 'react';

export const TagListSourceModal = ({
  open,
  onClose,
  value,
  setValue,
  load,
}) => {
  const availableTagLists = useContext(TagListContext);

  return (
    <DragModal
      open={open}
      title="editTagsSource"
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      titlecomponent={
        <Typography variant="h5" color="primary">
          Load Tag List(s)
        </Typography>
      }
    >
      <Divider />
      <DialogContent>
        <Grid container spacing={1} columns={1}>
          <Grid item sx={{ minWidth: 300 }} xs={1}>
            <Autocomplete
              multiple
              value={value}
              onChange={(_, newValue) => {
                setValue(newValue);
              }}
              options={
                availableTagLists.TagLists ?? [
                  { Name: 'Loading...', TCTagListId: -1 },
                ]
              }
              disabled={availableTagLists.TagLists == null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tag List(s)"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {availableTagLists.TagLists == null ? (
                          <CircularProgress />
                        ) : null}
                      </>
                    ),
                  }}
                />
              )}
              getOptionLabel={(tagList) =>
                tagList.Name || `Unnamed TagList - ID ${tagList.TCTagListId}`
              }
              getOptionKey={(tagList) => tagList.TCTagListId}
            />
          </Grid>
          <Grid item>
            <Button
              onClick={async () => {
                onClose();
                await load();
              }}
              variant="outlined"
            >
              Load
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </DragModal>
  );
};
