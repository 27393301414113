import { Dialog, Paper } from '@mui/material';
import Draggable from 'react-draggable';
import { useRef } from 'react';

/**
 * Wrapper for the Dialog component to add drag functionality. Accepts props from the Dialog component.
 * @param {*} props 
 * @returns 
 * @example
 * <DragModal
      open={open}
      title="editTagsSource"
      onClose={onClose}
      titlecomponent={
        <Typography variant="h5" color="primary">
          Configure Sources
        </Typography>
      }
    > <>//jsx dom</> </DragModal>
 */

export default function DragModal(props) {
  const { title, titlecomponent, children, disablefocus } = props;
  const handle = `dragdialog-title-${title || '1'}`;

  return (
    <Dialog
      {...props}
      PaperComponent={ModalPaperComponent}
      aria-labelledby={handle}
      disableEnforceFocus={disablefocus === 'true' ?? false}
    >
      <div
        style={{ cursor: 'move', padding: '15px 20px 15px 20px' }}
        id={handle}
      >
        {titlecomponent || title}
      </div>
      {children}
    </Dialog>
  );
}

function ModalPaperComponent(props) {
  const nodeRef = useRef(null);
  return (
    <Draggable
      handle={props.handle}
      nodeRef={nodeRef}
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}
