import {
  Typography,
  TextField,
  DialogContent,
  Divider,
  Grid,
  Button,
} from '@mui/material';
import DragModal from './DragModal';
import { useState } from 'react';
import { UploadFile } from '@mui/icons-material';
import { saveTagListAndTags } from '../helpers/APIHelpers';

export const ConfigureTagList = ({
  open,
  onClose,
  setTagListInfo,
  tagListInfo,
  setInfoMessages,
  constructNewTagListFromCurrentGrid,
  setLoading,
}) => {
  // Name
  // Description
  // Hardware Category
  const [name, setName] = useState(tagListInfo.Name);
  const [desc, setDesc] = useState(tagListInfo.Description);
  const [hwc, setHwc] = useState(tagListInfo.HardwareCategory);

  const saveNewTagList = async (newTagList) => {
    // Do something here to save grid as a new tag list
    setInfoMessages({
      type: 'snackbar',
      severity: 'info',
      message: 'Tag List has been queued for save.',
      open: true,
    });
    try {
      const [tagListToSave, tagLayersToSave] =
        constructNewTagListFromCurrentGrid(newTagList);
      await saveTagListAndTags(tagListToSave, tagLayersToSave);
      setInfoMessages({
        message: 'Tag List saved successfully.',
        severity: 'success',
        type: 'snackbar',
        open: true,
      });
    } catch (error) {
      setInfoMessages({
        type: 'snackbar',
        message:
          'There was an issue saving the tag list. Please try again or contact administrator.',
        severity: 'error',
        open: true,
      });
    }
  };

  return (
    <DragModal
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      titlecomponent={
        <Typography variant="h5" color="primary">
          Configure New Tag List
        </Typography>
      }
    >
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item>
            <TextField
              value={name || ''}
              onChange={(e) => {
                setName(e.target.value);
              }}
              label="Name"
            ></TextField>
          </Grid>
          <Grid item>
            <TextField
              value={desc || ''}
              onChange={(e) => {
                setDesc(e.target.value);
              }}
              label="Description"
            ></TextField>
          </Grid>
          <Grid item>
            <TextField
              value={hwc || ''}
              onChange={(e) => {
                setHwc(e.target.value);
              }}
              label="Hardware Category"
            ></TextField>
          </Grid>
          <Grid item>
            <Button
              onClick={async () => {
                let newTagList = {
                  Name: name,
                  Description: desc,
                  HardwareCategory: hwc,
                };
                setTagListInfo(newTagList);
                onClose();
                await saveNewTagList(newTagList);
              }}
              variant="contained"
              startIcon={<UploadFile />}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </DragModal>
  );
};
