import {
  CssBaseline,
  ThemeProvider,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import './App.css';
import { useDarkMode } from './contexts/GlobalContext';
import { lightTheme, darkTheme } from './components/DetechtionTheme';
import { Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  getAllCodeLegends,
  getAllTagListNames,
  getLookupValues,
} from './helpers/APIHelpers';
import { TagListContext } from './contexts/TagListContext';
import { LookupValueContext } from './contexts/LookupValueContext';
import { CodeLegendContext } from './contexts/CodeLegendContext';

export default function App() {
  const darkModeState = useDarkMode();

  const [tagLists, setTagLists] = useState([]);
  const [lookupValues, setLookupValues] = useState({});
  const [codeLegends, setCodeLegends] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getAllTagListNames()
      .then((result) => {
        setTagLists({ TagLists: result.data });
        // setLoading(false);
      })
      .catch((e) => {});
    getLookupValues()
      .then((result) => {
        setLookupValues(result.data);
        setLoading(false);
      })
      .catch((e) => {});
    getAllCodeLegends()
      .then((result) => {
        let codeLegendDataList = result.data.map((legend) => {
          return {
            id: legend.CodeLegendId,
            value: legend.Title,
            label: legend.Title,
          };
        });
        setCodeLegends(codeLegendDataList);
      })
      .catch((e) => {});
  }, []);

  return (
    <ThemeProvider theme={darkModeState ? darkTheme : lightTheme}>
      <TagListContext.Provider value={tagLists}>
        <CodeLegendContext.Provider value={codeLegends}>
          <LookupValueContext.Provider value={lookupValues}>
            <Backdrop open={loading} sx={{ zIndex: 3 }}>
              <CircularProgress />
            </Backdrop>
            <CssBaseline />
            <Outlet />
          </LookupValueContext.Provider>
        </CodeLegendContext.Provider>
      </TagListContext.Provider>
    </ThemeProvider>
  );
}
