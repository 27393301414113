import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeColorModeProvider } from './contexts/GlobalContext';
import {
  createBrowserRouter,
  RouterProvider,
  redirect,
} from 'react-router-dom';
import MainPage from './pages/main';
import EditTabComponent from './components/EditTabComponent';
import Error404Page from './pages/Error404Page';
import ErrorBoundary from './helpers/ErrorBoundary';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: '',
        element: <MainPage />,
        children: [
          {
            path: '/',
            loader: () => {
              return redirect('/edit');
            },
          },
          {
            path: '/edit/:assetIds?',
            element: <EditTabComponent />,
          },
          {
            path: '/404',
            element: <Error404Page />,
          },
        ],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeColorModeProvider>
      <RouterProvider router={router} />
    </ThemeColorModeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
