import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from '@mui/material';

const ConfirmDialog = ({ onClose, title, content, open }) => {
  const handleClose = async (result) => {
    if (typeof onClose === 'function') {
      await onClose(result);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={async () => await handleClose(false)}>Cancel</Button>
        <Button onClick={async () => await handleClose(true)} autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
