//import { useState } from 'react';
import { AppBar, Box, Toolbar, IconButton } from '@mui/material';
import {
  //Menu as MenuIcon,
  LightMode as LightModeIcon,
  //AccountCircle,
} from '@mui/icons-material';
import DetechLogo from '../assets/2022-detechtion-logo-white.png';
// import MainDrawerComponent from '../components/MainDrawerComponent';
import { useDarkModeDispatch } from '../contexts/GlobalContext';
import { Outlet } from 'react-router-dom';

const MainPage = () => {
  // const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDarkMode = useDarkModeDispatch();

  // const handleTabChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const handleChangeColorTheme = () => {
    toggleDarkMode({ type: 'TOGGLE', value: true });
  };

  return (
    <>
      {/*
      <MainDrawerComponent
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      />
      */}
      <Box sx={{ flexGrow: 1 }} color="secondary">
        <AppBar position="static">
          <Toolbar
            sx={{
              display: 'flex',
              justifyContent: 'space-between', // Distribute content evenly
              alignItems: 'center',
            }}
          >
            {/* 
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setDrawerOpen(true)}
            >
              <MenuIcon />
            </IconButton>
          */}
            <div>
              <img
                src={DetechLogo}
                style={{ width: '240px' }}
                alt="Detechtion logo"
              />
            </div>
            <div>
              {/*
                <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                // onClick={handleMenu}
                color="inherit"
                sx={{ mr: 2 }}
              >
                <AccountCircle />
              </IconButton>
                */}
              <IconButton
                size="large"
                aria-label="toggle theme"
                aria-controls="menu-appbar"
                aria-haspopup="false"
                onClick={handleChangeColorTheme}
                color="inherit"
              >
                <LightModeIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      </Box>
      {/* <AppBar position="static" sx={{ mb: 2 }}>
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="main menu tabs"
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          centered
        >
          <Tab label="Add" to="/add" component={Link} />
          <Tab label="Edit" to="/edit" component={Link} />
          <Tab label="Delete" to="/delete" component={Link} />
        </Tabs>
      </AppBar> */}
      <Outlet />
    </>
  );
};

export default MainPage;
