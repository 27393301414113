export const fl110 = {
  'Analog Control Output': {
    internalName: 'Analog Control Output',
    name: 'Analog Control Output',
    desc: 'Sets the Output Value to the given Target Value at the given rate. Supports switching to Manual Mode to override the Target Value and use the given Manual Output Value',
    category: 'Output Control',
    version: '1.2.1',
    maxScanErrorThreshold: 1,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'TargetValue',
        displayName: 'Target Value',
        desc: 'Value to set Output to',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'RateOfChange',
        displayName: 'Rate of Change',
        desc: 'Rate in engineering units per millisecond at which to change the Output until it reaches Target Value',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Mode',
        displayName: 'Mode',
        desc: 'Either MANUAL (0) or AUTO (1)',
        ioType: 'input',
        dataType: 'String',
        enumEntries: ['MANUAL', 'AUTO'],
        group: 'Inputs',
        required: true,
      },
      {
        name: 'ManualOutputValue',
        displayName: 'Manual Output Value',
        desc: 'Value in engineering units to set output to when the Mode is set to MANUAL',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'MinValue',
        displayName: 'Min Value',
        desc: 'Minimum allowed value for the Output',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'MaxValue',
        displayName: 'Max Value',
        desc: 'Maximum allowed value for the Output',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Output Value',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      TargetValue: {
        bindingType: 'None',
        value: '',
      },
      RateOfChange: {
        bindingType: 'Number',
        value: '0',
      },
      Mode: {
        bindingType: 'String',
        value: 'MANUAL',
      },
      ManualOutputValue: {
        bindingType: 'None',
        value: '',
      },
      MinValue: {
        bindingType: 'ThisTag',
        bindingValueSource: 'MinValue',
      },
      MaxValue: {
        bindingType: 'ThisTag',
        bindingValueSource: 'MaxValue',
      },
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  AnalogSignalDigitizer: {
    internalName: 'AnalogSignalDigitizer',
    name: 'Analog Signal Digitizer',
    desc: 'Convert the given Analog Signal Input into a digitized Square Wave based on given Logic High and Logic Low thresholds.',
    category: 'Statistics and Signal Processing',
    version: '1.2.2',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'AnalogSignalInput',
        displayName: 'Analog Signal Input',
        desc: 'Input that contains analog signal to digitize',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'LogicHighThreshold',
        displayName: 'Logic High Threshold',
        desc: 'Minimum value of the Analog Signal Input that is to be considered a Logic High (1)',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'LogicLowThreshold',
        displayName: 'Logic Low Threshold',
        desc: 'Maximum value of the Analog Signal Input that is to be considered a Logic Low (0)',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'MinimumPulseWidth',
        desc: 'Time an analog signal must be Logic High or Logic Low before being considered high or low.',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Output Value',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      AnalogSignalInput: {
        bindingType: 'None',
        value: '',
      },
      LogicHighThreshold: {
        bindingType: 'None',
        value: '',
      },
      LogicLowThreshold: {
        bindingType: 'None',
        value: '',
      },
      MinimumPulseWidth: {
        bindingType: 'None',
        value: '',
      },
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  BinaryBitwiseOperation: {
    internalName: 'BinaryBitwiseOperation',
    name: 'Binary Bitwise Operation',
    desc: 'Return the result of the defined Operation on the given Operands. The Operands are converted to integers before executing the Operation.',
    category: 'Bitwise Logic',
    version: '1.2.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 25,
    variableDefs: [
      {
        name: 'Operation',
        displayName: 'Operation',
        desc: 'Operation to perform on the given operands',
        ioType: 'input',
        dataType: 'String',
        enumEntries: [
          'AND',
          'NAND',
          'OR',
          'XOR',
          'NOR',
          'XNOR',
          'LSHIFT',
          'RSHIFT',
        ],
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Operand1',
        displayName: 'Operand 1',
        desc: 'First operand',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Operand2',
        displayName: 'Operand 2',
        desc: 'Second operand',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Result of the selected Operation',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      Operation: {
        bindingType: 'String',
        value: 'AND',
      },
      Operand1: {
        bindingType: 'None',
        value: '',
      },
      Operand2: {
        bindingType: 'None',
        value: '',
      },
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  BinaryConditionalOperation: {
    internalName: 'BinaryConditionalOperation',
    name: 'Binary Conditional Operation',
    desc: 'Return the result of the selected Operation on the given Operands. Note that this will result in a value of either 0 (false) or 1 (true). To determine the Boolean value of an Operand for evaluation, if the operand is zero then it is considered false, and if the operand is non-zero it is considered true.',
    category: 'Conditional Logic',
    version: '1.2.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 25,
    variableDefs: [
      {
        name: 'Operation',
        displayName: 'Operation',
        desc: 'Operation to perform on the given operands',
        ioType: 'input',
        dataType: 'String',
        enumEntries: ['AND', 'OR', 'XOR', 'NAND', 'NOR', 'XNOR'],
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Operand1',
        displayName: 'Operand 1',
        desc: 'First operand',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Operand2',
        displayName: 'Operand 2',
        desc: 'Second operand',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Result of the selected Operation',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      Operation: {
        bindingType: 'String',
        value: 'AND',
      },
      Operand1: {
        bindingType: 'None',
        value: '',
      },
      Operand2: {
        bindingType: 'None',
        value: '',
      },
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  BinaryMathOperation: {
    internalName: 'BinaryMathOperation',
    name: 'Binary Math Operation',
    desc: 'Return the result of the selected operation on the given operands.',
    category: 'Math',
    version: '1.2.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 25,
    variableDefs: [
      {
        name: 'Operation',
        displayName: 'Operation',
        desc: 'Operation to perform on the given operands',
        ioType: 'input',
        dataType: 'String',
        enumEntries: [
          'Add [=A+B]',
          'Subtract [=A-B]',
          'Subtract Absolute [=abs(A-B)]',
          'Multiply [=A*B]',
          'Divide [=A/B]',
          'Percentage [=(A/B)*100]',
          'Modulo [=A%B]',
          'Exponent [=A^B]',
        ],
        group: 'Inputs',
        required: true,
      },
      {
        name: 'OperandA',
        displayName: 'Operand A',
        desc: 'First operand',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'OperandB',
        displayName: 'Operand B',
        desc: 'Second operand',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Result of the selected Operation',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      Operation: {
        bindingType: 'String',
        value: 'Add [=A+B]',
      },
      OperandA: {
        bindingType: 'TagName',
        value: '',
      },
      OperandB: {
        bindingType: 'TagName',
        value: '',
      },
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  BitRead: {
    internalName: 'BitRead',
    name: 'Bit Read',
    desc: 'Return the value of the bit of given position in the given input value.',
    category: 'Bitwise Logic',
    version: '1.0.0',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 25,
    variableDefs: [
      {
        name: 'InputValue',
        displayName: 'Input Value',
        desc: 'Number that contains the bit to check',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'BitNumber',
        displayName: 'Bit Number',
        desc: 'Bit position to check, where 1 is the least significant bit',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Value of the bit in the given position (either 0 or 1)',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      InputValue: {
        bindingType: 'None',
        value: '',
      },
      BitNumber: {
        bindingType: 'None',
        value: '',
      },
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  Case: {
    internalName: 'Case',
    name: 'Case',
    desc: 'Attempt to match the given Input Value to one of the specified Case values and return the matching Case output value, or return the given Default Value if not match is found.',
    category: 'Selectors',
    version: '1.0.0',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'InputValue',
        displayName: 'Input Value',
        desc: 'Value for which to find a match',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'DefaultValue',
        displayName: 'Default Value',
        desc: 'Value to return if there is no match',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Cases',
        displayName: 'Cases',
        desc: 'Inputs',
        ioType: 'input',
        dataType: 'Object',
        group: 'Inputs',
        required: true,
        isArray: true,
        minElements: 1,
        members: [
          {
            name: 'InputValue',
            dataType: 'Number',
            desc: 'The Case Value.',
          },
          {
            name: 'OutputValue',
            dataType: 'Number',
            desc: 'The value to return if the Case Value matches the Input Value.',
          },
        ],
      },
      {
        name: 'MatchedCaseOutput',
        displayName: 'Matched Case Output',
        desc: 'Output Value of the matched Case',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'MatchedCaseIndex',
        displayName: 'Matched Case Index',
        desc: 'Index of matched Case, or 0 if no match',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      InputValue: {
        bindingType: 'None',
        value: '',
      },
      DefaultValue: {
        bindingType: 'None',
        value: '',
      },
      Cases: [
        [
          {
            InputValue: {
              bindingType: 'None',
              value: '',
            },
          },
          {
            OutputValue: {
              bindingType: 'None',
              value: '',
            },
          },
        ],
      ],
      MatchedCaseOutput: {
        bindingType: 'None',
        value: '',
      },
      MatchedCaseIndex: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  CenturionRunStatus: {
    internalName: 'CenturionRunStatus',
    name: 'Centurion Run Status',
    desc: 'Calculates a Run Status value by looking at the values of several state registers in the Centurion.',
    category: 'Compressor Analytics',
    version: '2.2.2',
    maxScanErrorThreshold: 2,
    requiredAccessLevel: 25,
    variableDefs: [
      {
        name: 'FaultTag',
        displayName: 'Fault Code Tag',
        desc: 'Centurion Fault Modbus Register that stores cause of shutdown (1-128)',
        dataType: 'TagDef',
        required: true,
      },
      {
        name: 'StateTag',
        displayName: 'State Code Tag',
        desc: 'Centurion State Modbus Register that stores operating state of the controller',
        dataType: 'TagDef',
        required: true,
      },
      {
        name: 'TestTimerTag',
        displayName: 'Test Timer Tag',
        desc: 'Centurion Modbus Register that stores Test Timer accumulator value',
        dataType: 'TagDef',
      },
      {
        name: 'PanelType',
        displayName: 'Panel Type',
        desc: 'Centurion Controller Type - one of C3, C4, C5, C+, C5/ASC',
        dataType: 'String',
        enumEntries: ['C3', 'C4', 'C5', 'C+', 'C5/ASC'],
        required: true,
      },
      {
        name: 'ManualStopCode',
        displayName: 'Manual Stop Code',
        desc: 'Numeric code to return when the panel has been Manually Stopped',
        dataType: 'Number',
      },
      {
        name: 'PanelReadyCode',
        displayName: 'Panel Ready Code',
        desc: 'Numeric code to return when the panel is in Panel Ready but it is not known how it got there (Manual Stop, cleared fault, restart)',
        dataType: 'Number',
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Current Run Status',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      FaultTag: {
        bindingType: 'TagName',
        value: 'FAULT CODE',
      },
      StateTag: {
        bindingType: 'TagName',
        value: 'STATE CODE',
      },
      TestTimerTag: {
        bindingType: 'TagName',
        value: 'TEST TIMER',
      },
      PanelType: {
        bindingType: 'String',
        value: 'C4',
      },
      ManualStopCode: {
        bindingType: 'Number',
        value: '999',
      },
      PanelReadyCode: {
        bindingType: 'Number',
        value: '998',
      },
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  ComparisonOperation: {
    internalName: 'ComparisonOperation',
    name: 'Comparison Operation',
    desc: 'Return to the result of the selected comparison Operation on the given Operands. The return value is 0 if the condition is False and 1 if True.',
    category: 'Comparative Logic',
    version: '1.2.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 25,
    variableDefs: [
      {
        name: 'Operation',
        displayName: 'Operation',
        desc: 'Operation to perform on the given operands',
        ioType: 'input',
        dataType: 'String',
        enumEntries: ['=', '<>', '>=', '>', '<=', '<'],
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Operand1',
        displayName: 'Operand 1',
        desc: 'First operand',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Operand2',
        displayName: 'Operand 2',
        desc: 'Second operand',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Result of the selected Operation',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      Operation: {
        bindingType: 'String',
        value: '=',
      },
      Operand1: {
        bindingType: 'None',
        value: '',
      },
      Operand2: {
        bindingType: 'None',
        value: '',
      },
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  'Control Selector New': {
    internalName: 'Control Selector New',
    name: 'Control Selector',
    desc: 'Return the result of the given aggregation Operation on the given inputs. Supports disabling given inputs.',
    category: 'Selectors',
    version: '2.2.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'Operation',
        displayName: 'Operation',
        desc: 'Operation to perform on the given operands',
        ioType: 'input',
        dataType: 'String',
        enumEntries: ['MIN', 'MAX'],
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Inputs',
        displayName: 'Inputs',
        desc: 'Inputs',
        ioType: 'input',
        dataType: 'Object',
        group: 'Inputs',
        required: true,
        isArray: true,
        minElements: 1,
        members: [
          {
            name: 'Value',
            dataType: 'Number',
            desc: 'Input value.',
          },
          {
            name: 'Enable',
            dataType: 'String',
            enumEntries: ['DISABLED', 'ENABLED'],
            desc: 'Set to DISABLED to exclude this input from the selection process.',
          },
        ],
      },
      {
        name: 'SelectedInputValue',
        displayName: 'Selected Input Value',
        desc: 'Value of the selected Input',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'SelectedInputIndex',
        displayName: 'Selected Input Index',
        desc: 'Index of the selected Input',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      Operation: {
        bindingType: 'String',
        value: 'MIN',
      },
      Inputs: [
        [
          {
            Value: {
              bindingType: 'None',
              value: '',
            },
          },
          {
            Enable: {
              bindingType: 'String',
              value: 'ENABLED',
            },
          },
        ],
      ],
      SelectedInputValue: {
        bindingType: 'None',
        value: '',
      },
      SelectedInputIndex: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  'Control Selector': {
    internalName: 'Control Selector',
    name: 'Control Selector (Deprecated)',
    desc: "Set the Tag's value to the result of the given aggregation function on the given inputs. Supports disabling given inputs. If you need to perform Input Selection on more than the provided number of inputs, you can cascade multiple Input Selectors.",
    category: 'Selectors',
    version: '1.0.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'Operation',
        displayName: 'Operation',
        desc: 'Operation to perform on the given operands',
        ioType: 'input',
        dataType: 'String',
        enumEntries: ['MIN', 'MAX'],
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Input1',
        displayName: 'Input 1',
        desc: 'First input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Input2',
        displayName: 'Input 2',
        desc: 'Second input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input3',
        displayName: 'Input 3',
        desc: 'Third input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input4',
        displayName: 'Input 4',
        desc: 'Fourth input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input5',
        displayName: 'Input 5',
        desc: 'Fifth input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input6',
        displayName: 'Input 6',
        desc: 'Sixth input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input7',
        displayName: 'Input 7',
        desc: 'Seventh input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input8',
        displayName: 'Input 8',
        desc: 'Eighth input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input9',
        displayName: 'Input 9',
        desc: 'Ninth input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input10',
        displayName: 'Input 10',
        desc: 'Tenth input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'EnableInput1',
        displayName: 'Enable Input 1',
        desc: 'Select DISABLE to exclude Input 1 from the selection process',
        ioType: 'input',
        dataType: 'String',
        enumEntries: ['DISABLED', 'ENABLED'],
        group: 'Inputs',
        required: false,
      },
      {
        name: 'EnableInput2',
        displayName: 'Enable Input 2',
        desc: 'Select DISABLE to exclude Input 2 from the selection process',
        ioType: 'input',
        dataType: 'String',
        enumEntries: ['DISABLED', 'ENABLED'],
        group: 'Inputs',
        required: false,
      },
      {
        name: 'EnableInput3',
        displayName: 'Enable Input 3',
        desc: 'Select DISABLE to exclude Input 3 from the selection process',
        ioType: 'input',
        dataType: 'String',
        enumEntries: ['DISABLED', 'ENABLED'],
        group: 'Inputs',
        required: false,
      },
      {
        name: 'EnableInput4',
        displayName: 'Enable Input 4',
        desc: 'Select DISABLE to exclude Input 4 from the selection process',
        ioType: 'input',
        dataType: 'String',
        enumEntries: ['DISABLED', 'ENABLED'],
        group: 'Inputs',
        required: false,
      },
      {
        name: 'EnableInput5',
        displayName: 'Enable Input 5',
        desc: 'Select DISABLE to exclude Input 5 from the selection process',
        ioType: 'input',
        dataType: 'String',
        enumEntries: ['DISABLED', 'ENABLED'],
        group: 'Inputs',
        required: false,
      },
      {
        name: 'EnableInput6',
        displayName: 'Enable Input 6',
        desc: 'Select DISABLE to exclude Input 6 from the selection process',
        ioType: 'input',
        dataType: 'String',
        enumEntries: ['DISABLED', 'ENABLED'],
        group: 'Inputs',
        required: false,
      },
      {
        name: 'EnableInput7',
        displayName: 'Enable Input 7',
        desc: 'Select DISABLE to exclude Input 7 from the selection process',
        ioType: 'input',
        dataType: 'String',
        enumEntries: ['DISABLED', 'ENABLED'],
        group: 'Inputs',
        required: false,
      },
      {
        name: 'EnableInput8',
        displayName: 'Enable Input 8',
        desc: 'Select DISABLE to exclude Input 8 from the selection process',
        ioType: 'input',
        dataType: 'String',
        enumEntries: ['DISABLED', 'ENABLED'],
        group: 'Inputs',
        required: false,
      },
      {
        name: 'EnableInput9',
        displayName: 'Enable Input 9',
        desc: 'Select DISABLE to exclude Input 9 from the selection process',
        ioType: 'input',
        dataType: 'String',
        enumEntries: ['DISABLED', 'ENABLED'],
        group: 'Inputs',
        required: false,
      },
      {
        name: 'EnableInput10',
        displayName: 'Enable Input 10',
        desc: 'Select DISABLE to exclude Input 10 from the selection process',
        ioType: 'input',
        dataType: 'String',
        enumEntries: ['DISABLED', 'ENABLED'],
        group: 'Inputs',
        required: false,
      },
    ],
    variableBindings: {
      Operation: {
        bindingType: 'String',
        value: 'MIN',
      },
      Input1: {
        bindingType: 'None',
        value: '',
      },
      Input2: {
        bindingType: 'None',
        value: '',
      },
      Input3: {
        bindingType: 'None',
        value: '',
      },
      Input4: {
        bindingType: 'None',
        value: '',
      },
      Input5: {
        bindingType: 'None',
        value: '',
      },
      Input6: {
        bindingType: 'None',
        value: '',
      },
      Input7: {
        bindingType: 'None',
        value: '',
      },
      Input8: {
        bindingType: 'None',
        value: '',
      },
      Input9: {
        bindingType: 'None',
        value: '',
      },
      Input10: {
        bindingType: 'None',
        value: '',
      },
      EnableInput1: {
        bindingType: 'String',
        value: 'ENABLED',
      },
      EnableInput2: {
        bindingType: 'String',
        value: 'ENABLED',
      },
      EnableInput3: {
        bindingType: 'String',
        value: 'ENABLED',
      },
      EnableInput4: {
        bindingType: 'String',
        value: 'ENABLED',
      },
      EnableInput5: {
        bindingType: 'String',
        value: 'ENABLED',
      },
      EnableInput6: {
        bindingType: 'String',
        value: 'ENABLED',
      },
      EnableInput7: {
        bindingType: 'String',
        value: 'ENABLED',
      },
      EnableInput8: {
        bindingType: 'String',
        value: 'ENABLED',
      },
      EnableInput9: {
        bindingType: 'String',
        value: 'ENABLED',
      },
      EnableInput10: {
        bindingType: 'String',
        value: 'ENABLED',
      },
    },
    deprecated: true,
  },
  CountDownCounter: {
    internalName: 'CountDownCounter',
    name: 'Count Down Counter',
    desc: 'Implements a Count Down Counter - similar to a PLC Count Down (CTD) counter instruction.',
    category: 'Counters',
    version: '1.2.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'Preset',
        displayName: 'Counter Preset',
        desc: 'The starting value for the count',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Trigger',
        displayName: 'Trigger',
        desc: 'Input signal that triggers the decremet of the counter on every rising edge',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Reset',
        displayName: 'Reset',
        desc: 'Input signal that resets the count to the Preset',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Done',
        displayName: 'Done',
        desc: 'Indicates completion status of counter',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'CurrentCount',
        displayName: 'Current Count',
        desc: 'Indicates current count',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      Preset: {
        bindingType: 'None',
        value: '',
      },
      Trigger: {
        bindingType: 'None',
        value: '',
      },
      Reset: {
        bindingType: 'None',
        value: '',
      },
      Done: {
        bindingType: 'None',
        value: '',
      },
      CurrentCount: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  CountUpCounter: {
    internalName: 'CountUpCounter',
    name: 'Count Up Counter',
    desc: 'Implements a Count Up Counter - similar to a PLC Count Up (CTU) counter instruction.',
    category: 'Counters',
    version: '1.2.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'Preset',
        displayName: 'Counter Preset',
        desc: 'The amount to count up to',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Trigger',
        displayName: 'Trigger',
        desc: 'Input signal that triggers the increment of the counter on every rising edge',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Reset',
        displayName: 'Reset',
        desc: 'Input signal that resets the count to zero',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Done',
        displayName: 'Done',
        desc: 'Indicates completion status of counter',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'CurrentCount',
        displayName: 'Current Count',
        desc: 'Indicates current count',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      Preset: {
        bindingType: 'None',
        value: '',
      },
      Trigger: {
        bindingType: 'None',
        value: '',
      },
      Reset: {
        bindingType: 'None',
        value: '',
      },
      Done: {
        bindingType: 'None',
        value: '',
      },
      CurrentCount: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  CountUpDownCounter: {
    internalName: 'CountUpDownCounter',
    name: 'Count Up/Down Counter',
    desc: 'Implements a Count Up/Down counter - similar to PLC Count Up/Down (CTUD) counter instruction.',
    category: 'Counters',
    version: '1.2.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'Preset',
        displayName: 'Counter Preset',
        desc: 'The amount to count up to',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'CountUpTrigger',
        displayName: 'Trigger',
        desc: 'Input signal that triggers the increment of the counter on every rising edge',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'CountDownTrigger',
        displayName: 'Count Down Trigger',
        desc: 'Input signal that triggers the decrement of the counter on every rising edge',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'ResetToZero',
        displayName: 'Reset to Zero',
        desc: 'Input signal that resets the count to zero',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'ResetToPreset',
        displayName: 'Reset to Preset',
        desc: 'Input signal that resets the count to the Preset value',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'CountUpDone',
        displayName: 'Done',
        desc: 'Indicates completion status of Up counter',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'CountDownDone',
        displayName: 'Done',
        desc: 'Indicates completion status of Down counter',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'CurrentCount',
        displayName: 'Current Count',
        desc: 'Indicates current count',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      Preset: {
        bindingType: 'None',
        value: '',
      },
      CountUpTrigger: {
        bindingType: 'None',
        value: '',
      },
      CountDownTrigger: {
        bindingType: 'None',
        value: '',
      },
      ResetToZero: {
        bindingType: 'None',
        value: '',
      },
      ResetToPreset: {
        bindingType: 'None',
        value: '',
      },
      CountUpDone: {
        bindingType: 'None',
        value: '',
      },
      CountDownDone: {
        bindingType: 'None',
        value: '',
      },
      CurrentCount: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  DE3000RunStatus: {
    internalName: 'DE3000RunStatus',
    name: 'DE-3000 Run Status',
    desc: 'Calculates a Run Status value by looking at the values of two state registers in the DE-3000 to determine if a shutdown is due to a Hi or Lo Fault.',
    category: 'Compressor Analytics',
    version: '2.2.1',
    maxScanErrorThreshold: 2,
    requiredAccessLevel: 25,
    variableDefs: [
      {
        name: 'StatusTag',
        displayName: 'Status Tag',
        desc: 'Tag for DE-3000 Status Modbus Register 40004',
        dataType: 'TagDef',
      },
      {
        name: 'FaultTag',
        displayName: 'Fault Tag',
        desc: 'DE-3000 Fault Modbus Register 40006',
        dataType: 'TagDef',
      },
      {
        name: 'ChannelFaultBase',
        displayName: 'Channel Fault Base',
        desc: 'Base value for mapping channel faults',
        dataType: 'Number',
      },
      {
        name: 'ControllerType',
        displayName: 'Controller Type',
        desc: 'Either DE-3000 or DE-4000',
        dataType: 'String',
        enumEntries: ['DE-3000', 'DE-4000'],
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Current Run Status',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      StatusTag: {
        bindingType: 'TagID',
        value: '',
      },
      FaultTag: {
        bindingType: 'TagID',
        value: '',
      },
      ChannelFaultBase: {
        bindingType: 'Number',
        value: '0',
      },
      ControllerType: {
        bindingType: 'String',
        value: 'DE-3000',
      },
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  DeviceStatusCodeMapper: {
    internalName: 'DeviceStatusCodeMapper',
    name: 'Device Status Code Mapper',
    desc: 'Maps the status codes of secondary devices into the status code range of the primary device to assemble a single combined status code for all devices. A corresponding Shutdown Legend must be created that maps the status codes into text descriptions for all devices.',
    category: 'Specialty',
    version: '2.2.1',
    maxScanErrorThreshold: 2,
    requiredAccessLevel: 25,
    variableDefs: [
      {
        name: 'PrimaryDeviceStatus',
        displayName: 'Primary Device Status',
        desc: 'Status indicator for Primary Device',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'SecondaryDevices',
        displayName: 'Secondary Devices',
        desc: 'Mapping information for Secondary Devices',
        ioType: 'input',
        dataType: 'Object',
        group: 'Inputs',
        required: true,
        isArray: true,
        minElements: 1,
        members: [
          {
            name: 'Status',
            dataType: 'Number',
            desc: 'Unmapped Status Code.',
          },
          {
            name: 'StatusKey',
            dataType: 'Number',
            desc: 'Value of the Primary Device status that indicates a fault in this Secondary Device',
          },
          {
            name: 'StatusCodeOffset',
            dataType: 'Number',
            desc: 'Offset to apply to the Status Codes of this Secondary Device',
          },
        ],
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Mapped Status Code',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      PrimaryDeviceStatus: {
        bindingType: 'None',
      },
      SecondaryDevices: [
        [
          {
            Status: {
              bindingType: 'None',
              value: '',
            },
          },
          {
            StatusKey: {
              bindingType: 'None',
              value: '',
            },
          },
          {
            StatusCodeOffset: {
              bindingType: 'None',
              value: '',
            },
          },
        ],
      ],
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  DeviceStatusTracker: {
    internalName: 'DeviceStatusTracker',
    name: 'Device Status Tracker',
    desc: 'Generate Alarm Events for warnings/shutdowns indicated by the given Status Codes.',
    category: 'Compressor Analytics',
    version: '1.2.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 25,
    variableDefs: [
      {
        name: 'InputTag',
        displayName: 'Input Tag',
        desc: 'Tag that contains Code Legend in support of alarming',
        ioType: 'input',
        dataType: 'TagDef',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'CodeType',
        displayName: 'Code Type',
        desc: 'Specifies whether the Status Code inputs store bitmapped codes or integer codes',
        ioType: 'input',
        dataType: 'String',
        enumEntries: ['INTEGER', 'BITMAPPED'],
        group: 'Inputs',
        required: true,
      },
      {
        name: 'NumBitsPerStatusCode',
        displayName: 'Num Bits Per Status Code',
        desc: 'Specifies the number of bits to track for BITMAPPED Status Codes',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'StatusCodes',
        displayName: 'Status Codes',
        desc: 'Status Code for tracking Alarms',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
        isArray: true,
        minElements: 1,
      },
      {
        name: 'FirstOutCode',
        displayName: 'First Out Code',
        desc: 'The earliest alarm code that was detected',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'FirstOutMessage',
        displayName: 'First Out Message',
        desc: 'The corresponding message for the earliest alarm code that was detected',
        ioType: 'output',
        dataType: 'String',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'LastOutCode',
        displayName: 'Last Out Code',
        desc: 'The latest alarm code that was detected',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'LastOutMessage',
        displayName: 'Last Out Message',
        desc: 'The corresponding message for the latest alarm code that was detected',
        ioType: 'output',
        dataType: 'String',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'NumActiveAlarms',
        displayName: 'Num Active Alarms',
        desc: 'The number of active Alarms',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'ActiveAlarmMessages',
        displayName: 'Active Alarm Messages',
        desc: 'Descriptions for all active Alarms',
        ioType: 'output',
        dataType: 'String',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'TotalAlarms',
        displayName: 'Total Alarms',
        desc: 'The total number of Alarms detected since startup',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      InputTag: {
        bindingType: 'ThisTag',
      },
      CodeType: {
        bindingType: 'String',
        value: 'INTEGER',
      },
      NumBitsPerStatusCode: {
        bindingType: 'Number',
        value: '16',
      },
      StatusCodes: [
        {
          bindingType: 'None',
        },
      ],
      FirstOutCode: {
        bindingType: 'None',
        value: '',
      },
      FirstOutMessage: {
        bindingType: 'None',
        value: '',
      },
      LastOutCode: {
        bindingType: 'None',
        value: '',
      },
      LastOutMessage: {
        bindingType: 'None',
        value: '',
      },
      NumActiveAlarms: {
        bindingType: 'None',
        value: '',
      },
      ActiveAlarmMessages: {
        bindingType: 'None',
        value: '',
      },
      TotalAlarms: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  'Discretizer New': {
    internalName: 'Discretizer New',
    name: 'Discretizer',
    desc: 'Return the bin index the given input value falls into. Bins are defined as monotonically increasing thresholds with the Edge inputs.',
    category: 'Comparative Logic',
    version: '2.2.0',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'InputValue',
        displayName: 'Input Value',
        desc: 'Value to map to a bin',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Edges',
        displayName: 'Edges',
        desc: 'Array of edges',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
        isArray: true,
        minElements: 2,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Bin index the given input value falls into',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      InputValue: {
        bindingType: 'None',
        value: '',
      },
      Edges: [
        {
          bindingType: 'None',
          value: '',
        },
        {
          bindingType: 'None',
          value: '',
        },
      ],
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  Discretizer: {
    internalName: 'Discretizer',
    name: 'Discretizer (Deprecated)',
    desc: "Set the Tag's value to the bin index the given input value falls in. Bins are defined as monotonically increasing thresholds with the Edge inputs.",
    category: 'Comparative Logic',
    version: '1.0.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'InputValue',
        displayName: 'Input Value',
        desc: 'Value to map to a bin',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Edge1',
        displayName: 'Edge 1',
        desc: 'First edge',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Edge2',
        displayName: 'Edge 2',
        desc: 'Second edge',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Edge3',
        displayName: 'Edge 3',
        desc: 'Third edge',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Edge4',
        displayName: 'Edge 4',
        desc: 'Fourth edge',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Edge5',
        displayName: 'Edge 5',
        desc: 'Fifth edge',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Edge6',
        displayName: 'Edge 6',
        desc: 'Sixth edge',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Edge7',
        displayName: 'Edge 7',
        desc: 'Seventh edge',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Edge8',
        displayName: 'Edge 8',
        desc: 'Eighth edge',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Edge9',
        displayName: 'Edge 9',
        desc: 'Ninth edge',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Edge10',
        displayName: 'Edge 10',
        desc: 'Tenth edge',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
    ],
    variableBindings: {
      InputValue: {
        bindingType: 'None',
        value: '',
      },
      Edge1: {
        bindingType: 'None',
        value: '',
      },
      Edge2: {
        bindingType: 'None',
        value: '',
      },
      Edge3: {
        bindingType: 'None',
        value: '',
      },
      Edge4: {
        bindingType: 'None',
        value: '',
      },
      Edge5: {
        bindingType: 'None',
        value: '',
      },
      Edge6: {
        bindingType: 'None',
        value: '',
      },
      Edge7: {
        bindingType: 'None',
        value: '',
      },
      Edge8: {
        bindingType: 'None',
        value: '',
      },
      Edge9: {
        bindingType: 'None',
        value: '',
      },
      Edge10: {
        bindingType: 'None',
        value: '',
      },
    },
    deprecated: true,
  },
  EquipmentRunStatus: {
    internalName: 'EquipmentRunStatus',
    name: 'Equipment Run Status',
    desc: 'Determine the running status of equipment based given Fault and State Codes.',
    category: 'Compressor Analytics',
    version: '1.0.0',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 25,
    variableDefs: [
      {
        name: 'FaultCodeTag',
        displayName: 'Fault Code Tag',
        desc: 'Equipment Fault Code register that stores cause of shutdown',
        dataType: 'TagDef',
        required: true,
      },
      {
        name: 'StateCodeTag',
        displayName: 'State Code Tag',
        desc: 'Equipment State register that stores operating state of the controller',
        dataType: 'TagDef',
        required: true,
      },
      {
        name: 'IsTestMode',
        displayName: 'Is Test Mode',
        desc: 'Indicates if the equipment is in Test mode - prevents annunciation of faults when equipment is being tested',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'ManualStopStateCode',
        displayName: 'Manual Stop State Code',
        desc: 'Numeric code to return when the ascertained state indicates the equipment has been manually stopped',
        dataType: 'Number',
      },
      {
        name: 'RunningStateCode',
        displayName: 'Running State Code',
        desc: 'Numeric code to return when the ascertained state indicates the equipment is running',
        dataType: 'Number',
      },
      {
        name: 'UnknownStateCode',
        displayName: 'Unknown State Code',
        desc: 'Numeric code to return when the running state of the equipment cannot be ascertained',
        dataType: 'Number',
      },
      {
        name: 'NoFaultFaultCode',
        displayName: 'No-Fault Fault Code',
        desc: 'Numeric Fault Code used by equipment controller to indicate there is no active fault',
        dataType: 'Number',
      },
      {
        name: 'ReadyToStartCodes',
        displayName: 'Ready to Start Codes',
        desc: 'List of State Codes that indicate when faults have been cleared and the equipment is ready to be started',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
        isArray: true,
        minElements: 0,
      },
      {
        name: 'ShutdownCodes',
        displayName: 'Shutdown Codes',
        desc: 'List of State Codes that indicate when equipment is shut down due to a fault',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
        isArray: true,
        minElements: 0,
      },
      {
        name: 'RunningCodes',
        displayName: 'Running Codes',
        desc: 'List of State Codes that indicate when equipment is running',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
        isArray: true,
        minElements: 0,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Current running status of equipment',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      FaultCodeTag: {
        bindingType: 'None',
        value: '',
      },
      StateCodeTag: {
        bindingType: 'None',
        value: '',
      },
      IsTestMode: {
        bindingType: 'None',
        value: '',
      },
      ManualStopStateCode: {
        bindingType: 'Number',
        value: '999',
      },
      RunningStateCode: {
        bindingType: 'Number',
        value: '0',
      },
      UnknownStateCode: {
        bindingType: 'Number',
        value: '-1',
      },
      NoFaultFaultCode: {
        bindingType: 'Number',
        value: '0',
      },
      ReadyToStartCodes: [
        {
          bindingType: 'Number',
          value: '0',
        },
      ],
      ShutdownCodes: [
        {
          bindingType: 'Number',
          value: '0',
        },
      ],
      RunningCodes: [
        {
          bindingType: 'Number',
          value: '0',
        },
      ],
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  ExclusionZonesNew: {
    internalName: 'ExclusionZonesNew',
    name: 'Exclusion Zones',
    desc: 'Return the given Target Value as long as it does not fall within any of the specified exclusion zones. If the Target Value falls within an exclusion zone thereturn value is set to the closest endpoint in the range (Min or Max). Used primarily to avoid certain operating RPM zones when setting Engine Speed in an engine controller.',
    category: 'Output Control',
    version: '2.2.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'TargetValue',
        displayName: 'Target Value',
        desc: 'The target value for to apply the Exclusion Zones to',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Enabled',
        displayName: 'Enabled',
        desc: 'Set to zero to temporarily disable the exclusion zones when ramping up the value of an output for steady-state operation. Once the output has reached steady-state set this input to 1 to apply the exclusion zones',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Zones',
        displayName: 'Exclusion Zones',
        desc: 'ZONE DESCRIPTION',
        ioType: 'input',
        dataType: 'Object',
        group: 'Inputs',
        required: true,
        isArray: true,
        minElements: 1,
        members: [
          {
            name: 'Min',
            dataType: 'Number',
          },
          {
            name: 'Max',
            dataType: 'Number',
          },
        ],
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Output Value',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      TargetValue: {
        bindingType: 'None',
        value: '',
      },
      Enabled: {
        bindingType: 'Number',
        value: '1',
      },
      Zones: [
        [
          {
            Min: {
              bindingType: 'None',
              value: '',
            },
          },
          {
            Max: {
              bindingType: 'None',
              value: '',
            },
          },
        ],
      ],
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  ExclusionZones: {
    internalName: 'ExclusionZones',
    name: 'Exclusion Zones (Deprecated)',
    desc: "Set the Tag's value to the given Target Value as long as it does not fall within any of the specified exclusion zones. If the Target Value falls within an exclusion zone the Tag's value is set to the closest endpoint in the range (Min or Max). Used primarily to avoid certain operating RPM zones when setting Engine Speed in an engine controller.",
    category: 'Control',
    version: '1.0.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'TargetValue',
        displayName: 'Target Value',
        desc: 'The target value for to apply the Exclusion Zones to',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Enabled',
        displayName: 'Enabled',
        desc: 'Set to zero to temporarily disable the exclusion zones when ramping up the value of an output for steady-state operation. Once the output has reached steady-state set this input to 1 to apply the exclusion zones',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Zone1Min',
        displayName: 'Zone 1 Min',
        desc: 'Minimum value for first exclusion zone',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Zone1Max',
        displayName: 'Zone 1 Max',
        desc: 'Maximum value for first exclusion zone',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Zone2Min',
        displayName: 'Zone 2 Min',
        desc: 'Minimum value for second exclusion zone',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Zone2Max',
        displayName: 'Zone 2 Max',
        desc: 'Maximum value for second exclusion zone',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Zone3Min',
        displayName: 'Zone 3 Min',
        desc: 'Minimum value for third exclusion zone',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Zone3Max',
        displayName: 'Zone 3 Max',
        desc: 'Maximum value for third exclusion zone',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
    ],
    variableBindings: {
      TargetValue: {
        bindingType: 'None',
        value: '',
      },
      Enabled: {
        bindingType: 'Number',
        value: '1',
      },
      Zone1Min: {
        bindingType: 'None',
        value: '',
      },
      Zone1Max: {
        bindingType: 'None',
        value: '',
      },
      Zone2Min: {
        bindingType: 'None',
        value: '',
      },
      Zone2Max: {
        bindingType: 'None',
        value: '',
      },
      Zone3Min: {
        bindingType: 'None',
        value: '',
      },
      Zone3Max: {
        bindingType: 'None',
        value: '',
      },
    },
    deprecated: true,
  },
  HistoricalLinearLeastSquaresFit: {
    internalName: 'HistoricalLinearLeastSquaresFit',
    name: 'Historical Linear Least Squares Fit',
    desc: 'Perform a linear least squares fit on the historical data for the given Tag input for the given time span.',
    category: 'Statistics and Signal Processing',
    version: '1.2.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 25,
    variableDefs: [
      {
        name: 'SourceDataTag',
        displayName: 'Source Data Tag',
        desc: 'Specifies the Tag for which to apply the linear fit',
        ioType: 'input',
        dataType: 'TagDef',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'TimeSpan',
        displayName: 'Time Span',
        desc: 'Specifies the time span, in minutes, for which to apply the linear fit to',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'TimeScaleSeconds',
        displayName: 'Time Scale Seconds',
        desc: 'Specifies how to scale the slope from the default of Seconds',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'GroupBySeconds',
        displayName: 'Group By Seconds',
        desc: 'Specifies the time period to group data by for the specified Aggregation Function',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'AggregationFunction',
        displayName: 'Aggregation Function',
        desc: 'Specifies the Aggregation Function to use',
        ioType: 'input',
        dataType: 'String',
        enumEntries: ['', 'LAST', 'AVG', 'MIN', 'MAX'],
        group: 'Inputs',
        required: true,
      },
      {
        name: 'InvalidDataThreshold',
        displayName: 'Invalid Data Threshold',
        desc: 'Specifies the maximum difference between one value and the next for it to be considered a valid data point',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'MaxNumInvalidDataPoints',
        displayName: 'Max Num Invalid Data Points',
        desc: 'Specifies the maximum number of invalid data points allowed before stopping the linear fit',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Slope',
        displayName: 'Slope',
        desc: 'The slope of the fitted line, in delta Y per second',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'ScaledSlope',
        displayName: 'Scaled Slope',
        desc: 'The slope of the fitted line, scaled to delta Y per given time scale',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'YIntercept',
        displayName: 'Y Intercept',
        desc: 'The Y Intercept of the fitted line',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'CorrelationCoefficient',
        displayName: 'Correlation Coefficient',
        desc: 'The Correlation Coefficient of the fitted line, which is an indication of how well the fitted line fits the data',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'LatestFittedValue',
        displayName: 'Latest Fitted Value',
        desc: 'The Correlation Coefficient of the fitted line, which is an indication of how well the fitted line fits the data',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'NumPointsProcessed',
        displayName: 'Num Points Processed',
        desc: 'The number of data points included in the curve fit that were not discarded',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'NumInvalidDataPointsFound',
        displayName: 'Num Invalid Data Points Found',
        desc: 'The number of data points that were discarded based on the specified Invalid Data Threhsold',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      SourceDataTag: {
        bindingType: 'None',
      },
      TimeSpan: {
        bindingType: 'None',
      },
      TimeScaleSeconds: {
        bindingType: 'Number',
        value: '86400',
      },
      GroupBySeconds: {
        bindingType: 'Number',
        value: '0',
      },
      AggregationFunction: {
        bindingType: 'String',
        value: '',
      },
      InvalidDataThreshold: {
        bindingType: 'None',
      },
      MaxNumInvalidDataPoints: {
        bindingType: 'None',
      },
      Slope: {
        bindingType: 'None',
      },
      ScaledSlope: {
        bindingType: 'None',
      },
      YIntercept: {
        bindingType: 'None',
      },
      CorrelationCoefficient: {
        bindingType: 'None',
      },
      LatestFittedValue: {
        bindingType: 'None',
      },
      NumPointsProcessed: {
        bindingType: 'None',
      },
      NumInvalidDataPointsFound: {
        bindingType: 'None',
      },
    },
  },
  HistoricalTotal: {
    internalName: 'HistoricalTotal',
    name: 'Historical Total',
    desc: 'Calculate the total count/amount being tracked by the given Totalizer Tag input for the given time span.',
    category: 'Statistics and Signal Processing',
    version: '1.2.0',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 25,
    variableDefs: [
      {
        name: 'TotalizerTag',
        displayName: 'Totalizer Tag',
        desc: 'Specifies the Tag that maintains the total count/amount being tracked',
        ioType: 'input',
        dataType: 'TagDef',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Direction',
        displayName: 'Direction',
        desc: 'Specifies whether the given count/amount being tracked is expected to increase or decrease',
        ioType: 'input',
        dataType: 'String',
        enumEntries: ['INCREASING', 'DECREASING'],
        group: 'Inputs',
        required: true,
      },
      {
        name: 'TimeSpan',
        displayName: 'Time Span',
        desc: 'Specifies the time span, in minutes, for which to calculate the total count/amount',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Total Count/Amount',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      TotalizerTag: {
        bindingType: 'None',
      },
      Direction: {
        bindingType: 'String',
        value: 'INCREASING',
      },
      TimeSpan: {
        bindingType: 'None',
      },
      Result: {
        bindingType: 'None',
      },
    },
  },
  HydrostaticLiquidLevel: {
    internalName: 'HydrostaticLiquidLevel',
    name: 'Hydrostatic Liquid Level',
    desc: 'Convert the given level in units of Water Column to units of Medium Column using the given Specific Gravity. Emits the smoothed level using a moving average as a System Metric.',
    category: 'Specialty',
    version: '1.2.2',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 25,
    variableDefs: [
      {
        name: 'LevelInUnitsOfWaterColumn',
        displayName: 'Level In Units Of Water Column',
        desc: 'Liquid Level In units of water column to be converted to units of the given medium via application of the Specific Gravity',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'SpecificGravity',
        displayName: 'Specific Gravity',
        desc: 'Specific Gravity of the medium',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'SmoothingWindowSize',
        displayName: 'Smoothing Window Size',
        desc: 'Size of the moving window for smoothing',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'FieldCalibrationOffset',
        displayName: 'Field Calibration Offset',
        desc: 'Offset established by performing a field calibration to account for differences in sensor location compared to container bottom',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'LevelInUnitsOfMediumColumn',
        displayName: 'Level In Units of Medium Column',
        desc: 'Liquid level, in units of medium column (i.e., with Specific Gravity applied)',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'LevelInUnitsOfMediumColumnWithOffset',
        displayName: 'Level In Units of Medium Column with Offset',
        desc: 'Liquid level, in units of medium column, with Field Calibration Offset applied',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'SmoothedLevelInUnitsOfMediumColumn',
        displayName: 'Smoothed Level In Units of Medium Column',
        desc: 'Liquid level, in units of medium column, with data smoothing and Field Calibration Offset applied',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      LevelInUnitsOfWaterColumn: {
        bindingType: 'None',
        value: '',
      },
      SpecificGravity: {
        bindingType: 'None',
        value: '',
      },
      SmoothingWindowSize: {
        bindingType: 'Number',
        value: '10',
      },
      FieldCalibrationOffset: {
        bindingType: 'Number',
        value: '0',
      },
      LevelInUnitsOfMediumColumn: {
        bindingType: 'Number',
        value: '0',
      },
      LevelInUnitsOfMediumColumnWithOffset: {
        bindingType: 'Number',
        value: '0',
      },
      SmoothedLevelInUnitsOfMediumColumn: {
        bindingType: 'Number',
        value: '0',
      },
    },
  },
  LatchUnlatchOutput: {
    internalName: 'LatchUnlatchOutput',
    name: 'Latch/Unlatch Output',
    desc: "Latch the Output Value to 1 when the given Latching Input's value goes from 0 to 1. Because it is latched, the Output's value will stay at 1 even if the Latching Input goes to 0. The Output can only be unlatched if the Unlatching Input goes from 0 to 1. Typically used to set a Digital Output state based on a Digital Input state. This is analogous to how PLC OTL/OTU instructions are used in tandem.",
    category: 'Output Control',
    version: '1.2.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'LatchingInput',
        displayName: 'Latching Input',
        desc: "Input that latches this Tag's value to 1 on a rising edge.",
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'UnlatchingInput',
        displayName: 'Unlatching Input',
        desc: "Input that unlatches this Tag's value to 0 on a rising edge.",
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Output Value',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      LatchingInput: {
        bindingType: 'None',
        value: '',
      },
      UnlatchingInput: {
        bindingType: 'None',
        value: '',
      },
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  Limit: {
    internalName: 'Limit',
    name: 'Limit',
    desc: 'Set the Output Value to the Input value clamped to the given Min and Max.',
    category: 'Selectors',
    version: '1.2.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 25,
    variableDefs: [
      {
        name: 'Input',
        displayName: 'Input',
        desc: 'Value to clamp to the given range',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'MinValue',
        displayName: 'Min Value',
        desc: 'Minimum value of the range',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'MaxValue',
        displayName: 'Max Value',
        desc: 'Maximum value of the range',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Output Value',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      Input: {
        bindingType: 'None',
        value: '',
      },
      MinValue: {
        bindingType: 'None',
        value: '',
      },
      MaxValue: {
        bindingType: 'None',
        value: '',
      },
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  LinearRampController: {
    internalName: 'LinearRampController',
    name: 'Linear Ramp Controller',
    desc: 'Scales the given input value linearly given min and max values that define the line.',
    category: 'Output Control',
    version: '1.2.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'InputCurrentValue',
        displayName: 'Input Current Value',
        desc: 'Current input value (X Axis value) to transform linearly',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'InputMin',
        displayName: 'Input Min',
        desc: 'Minimum input value (X Axis min)',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'InputMax',
        displayName: 'Input Max',
        desc: 'Maximum input value (X Axis max)',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'OutputMin',
        displayName: 'Output Min',
        desc: 'Minimum output value (Y Axis min)',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'OutputMax',
        displayName: 'Output Max',
        desc: 'Maximum output value (Y Axis max)',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'OutputDeadband',
        displayName: 'Output Deadband',
        desc: 'Deadband to apply to output to reduce wear and tear on equipment',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Mode',
        displayName: 'Mode',
        desc: 'Either MANUAL (0) or AUTO (1)',
        ioType: 'input',
        dataType: 'String',
        enumEntries: ['MANUAL', 'AUTO'],
        group: 'Inputs',
        required: true,
      },
      {
        name: 'ManualOutputValue',
        displayName: 'Manual Output Value',
        desc: 'Value in engineering units to set output to when the Mode is set to MANUAL',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Output Value',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      InputCurrentValue: {
        bindingType: 'Number',
        value: '',
      },
      InputMin: {
        bindingType: 'Number',
        value: '',
      },
      InputMax: {
        bindingType: 'Number',
        value: '',
      },
      OutputMin: {
        bindingType: 'Number',
        value: '',
      },
      OutputMax: {
        bindingType: 'Number',
        value: '',
      },
      OutputDeadband: {
        bindingType: 'Number',
        value: '0.0',
      },
      Mode: {
        bindingType: 'String',
        value: 'AUTO',
      },
      ManualOutputValue: {
        bindingType: 'Number',
        value: '0',
      },
      Result: {
        bindingType: 'Number',
        value: '0',
      },
    },
  },
  LinearScaler: {
    internalName: 'LinearScaler',
    name: 'Linear Scaler',
    desc: 'Scales the given input value linearly given a Gain (Slope) and Offset (Y Intercept).',
    category: 'Math',
    version: '1.2.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 25,
    variableDefs: [
      {
        name: 'InputValue',
        displayName: 'Input Value',
        desc: 'Value to scale',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Gain',
        displayName: 'Gain',
        desc: 'Multiplicative factor',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Offset',
        displayName: 'Offset',
        desc: 'Additive factor',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Output Value',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      InputValue: {
        bindingType: 'None',
      },
      Gain: {
        bindingType: 'Number',
        value: '1.0',
      },
      Offset: {
        bindingType: 'Number',
        value: '0.0',
      },
      Result: {
        bindingType: 'Number',
        value: '0.0',
      },
    },
  },
  'Max Allowable Engine Load': {
    internalName: 'Max Allowable Engine Load',
    name: 'Max Allowable Engine Load',
    desc: 'Determines Max Allowable Engine Load to be used by Dynamic Control. Outputs the Max Allowable Engine Load as a percentage.',
    category: 'Engine Analytics',
    version: '1.2.0',
    maxScanErrorThreshold: 1,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'MaxDesiredLoad',
        displayName: 'Max Desired Load (%)',
        desc: 'Customer-entered max permissible engine load or [Max Derated HP] from Enalysis',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
        isArray: false,
      },
      {
        name: 'MaxRatedLoad',
        displayName: 'Max Rated Load (%)',
        desc: 'From engine manufacturer spec sheet; Defaults to 100%',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
        isArray: false,
      },
      {
        name: 'LimpHome',
        displayName: 'Limp Home (%)',
        desc: 'Calculated by ESM2; If not available default to 100%',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
        isArray: false,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Max Allowable Engine Load',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      MaxDesiredLoad: {
        bindingType: 'Number',
        value: '',
      },
      MaxRatedLoad: {
        bindingType: 'Number',
        value: '100',
      },
      LimpHome: {
        bindingType: 'Number',
        value: '100',
      },
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  MaxDifferentialNew: {
    internalName: 'MaxDifferentialNew',
    name: 'Max Differential',
    desc: 'Computes the absolute difference between the minimum and maximum input.',
    category: 'Math',
    version: '2.2.0',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 25,
    variableDefs: [
      {
        name: 'Inputs',
        displayName: 'Inputs',
        desc: 'Array of inputs on which to compute Max Differential',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
        isArray: true,
        minElements: 2,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Maximum Differential',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      Inputs: [
        {
          bindingType: 'None',
          value: '',
        },
        {
          bindingType: 'None',
          value: '',
        },
      ],
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  MaxDifferential: {
    internalName: 'MaxDifferential',
    name: 'Max Differential (Deprecated)',
    desc: 'Computes the absolute difference between the minimum and maximum input.',
    category: 'Math',
    version: '1.0.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 25,
    variableDefs: [
      {
        name: 'Input1',
        displayName: 'Input 1',
        desc: 'Input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Input2',
        displayName: 'Input 2',
        desc: 'Input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Input3',
        displayName: 'Input 3',
        desc: 'Input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input4',
        displayName: 'Input 4',
        desc: 'Input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input5',
        displayName: 'Input 5',
        desc: 'Input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input6',
        displayName: 'Input 6',
        desc: 'Input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input7',
        displayName: 'Input 7',
        desc: 'Input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input8',
        displayName: 'Input 8',
        desc: 'Input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input9',
        displayName: 'Input 9',
        desc: 'Input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input10',
        displayName: 'Input 10',
        desc: 'Input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input11',
        displayName: 'Input 11',
        desc: 'Input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input12',
        displayName: 'Input 12',
        desc: 'Input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input13',
        displayName: 'Input 13',
        desc: 'Input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input14',
        displayName: 'Input 14',
        desc: 'Input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input15',
        displayName: 'Input 15',
        desc: 'Input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input16',
        displayName: 'Input 16',
        desc: 'Input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input17',
        displayName: 'Input 17',
        desc: 'Input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input18',
        displayName: 'Input 18',
        desc: 'Input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input19',
        displayName: 'Input 19',
        desc: 'Input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input20',
        displayName: 'Input 20',
        desc: 'Input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
    ],
    variableBindings: {
      Input1: {
        bindingType: 'TagName',
        value: '',
      },
      Input2: {
        bindingType: 'TagName',
        value: '',
      },
      Input3: {
        bindingType: 'None',
        value: '',
      },
      Input4: {
        bindingType: 'None',
        value: '',
      },
      Input5: {
        bindingType: 'None',
        value: '',
      },
      Input6: {
        bindingType: 'None',
        value: '',
      },
      Input7: {
        bindingType: 'None',
        value: '',
      },
      Input8: {
        bindingType: 'None',
        value: '',
      },
      Input9: {
        bindingType: 'None',
        value: '',
      },
      Input10: {
        bindingType: 'None',
        value: '',
      },
      Input11: {
        bindingType: 'None',
        value: '',
      },
      Input12: {
        bindingType: 'None',
        value: '',
      },
      Input13: {
        bindingType: 'None',
        value: '',
      },
      Input14: {
        bindingType: 'None',
        value: '',
      },
      Input15: {
        bindingType: 'None',
        value: '',
      },
      Input16: {
        bindingType: 'None',
        value: '',
      },
      Input17: {
        bindingType: 'None',
        value: '',
      },
      Input18: {
        bindingType: 'None',
        value: '',
      },
      Input19: {
        bindingType: 'None',
        value: '',
      },
      Input20: {
        bindingType: 'None',
        value: '',
      },
    },
    deprecated: true,
  },
  'Moving Stats': {
    internalName: 'Moving Stats',
    name: 'Moving Stats',
    desc: 'Performs aggregation operations on a sliding window of values for the given input.',
    category: 'Statistics and Signal Processing',
    version: '1.2.2',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'InputSignal',
        displayName: 'Input Signal',
        desc: 'Input to which to perform the given aggregation function',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'WindowSize',
        displayName: 'Window Size',
        desc: 'The number of data points to maintain in the data window',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Operation',
        displayName: 'Operation',
        desc: 'Statistic to call to compute Tag value',
        ioType: 'input',
        dataType: 'String',
        enumEntries: [
          'AVERAGE',
          'VARIANCE',
          'STANDARD DEVIATION',
          'MINIMUM',
          'MAXIMUM',
          'RANGE',
        ],
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Average',
        displayName: 'Average',
        desc: 'Moving Average',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'Variance',
        displayName: 'Variance',
        desc: 'Moving Variance',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'StandardDeviation',
        displayName: 'Standard Deviation',
        desc: 'Moving Standard Deviation',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'Minimum',
        displayName: 'Minimum',
        desc: 'Moving Minimum',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'Maximum',
        displayName: 'Maximum',
        desc: 'Moving Maximum',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'Range',
        displayName: 'Range',
        desc: 'Moving Range',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'Count',
        displayName: 'Count',
        desc: 'Number of points in data window',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      InputSignal: {
        bindingType: 'Number',
        value: 'None',
      },
      WindowSize: {
        bindingType: 'Number',
        value: '60',
      },
      Operation: {
        bindingType: 'String',
        value: 'AVERAGE',
      },
      Average: {
        bindingType: 'None',
        value: '',
      },
      Variance: {
        bindingType: 'None',
        value: '',
      },
      StandardDeviation: {
        bindingType: 'None',
        value: '',
      },
      Minimum: {
        bindingType: 'None',
        value: '',
      },
      Maximum: {
        bindingType: 'None',
        value: '',
      },
      Range: {
        bindingType: 'None',
        value: '',
      },
      Count: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  MPUSpeed: {
    internalName: 'MPUSpeed',
    name: 'MPU Speed',
    desc: 'Calculate speed in RPM from a Pulse Input signal in Hertz - typically used with Magnetic Pickups (MPU) .',
    category: 'Compressor Analytics',
    version: '1.0.0',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'PulseInput',
        displayName: 'Pulse Input',
        desc: 'The pulse signal in Hertz',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'NumPulsesPerRevolution',
        displayName: 'Num Pulses Per Revolution',
        desc: 'Number of pulses for each revolution (e.g., number of teeth on flowheel of engine)',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'The speed in RPM',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      PulseInput: {
        bindingType: 'None',
        value: '',
      },
      NumPulsesPerRevolution: {
        bindingType: 'None',
        value: '',
      },
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  MultiplexerNew: {
    internalName: 'MultiplexerNew',
    name: 'Multiplexer',
    desc: 'Sets the Output Value to one of the given Inputs by using the Selector Input as an index.',
    category: 'Selectors',
    version: '2.2.0',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'SelectorInput',
        displayName: 'Selector Input',
        desc: 'Selects which signal to output (1 - Number of Inputs)',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Inputs',
        displayName: 'Inputs',
        desc: 'Array of inputs',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
        isArray: true,
        minElements: 1,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Output Value',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      SelectorInput: {
        bindingType: 'None',
        value: '',
      },
      Inputs: [
        {
          bindingType: 'None',
          value: '',
        },
      ],
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  Multiplexer: {
    internalName: 'Multiplexer',
    name: 'Multiplexer (Deprecated)',
    desc: "Set the Tag's value to one of the given inputs based on a selecting input.",
    category: 'Selectors',
    version: '1.0.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'SelectorInput',
        displayName: 'Selector Input',
        desc: 'Selects which signal to output (1-10)',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Input1',
        displayName: 'Input 1',
        desc: 'First input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Input2',
        displayName: 'Input 2',
        desc: 'Second input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input3',
        displayName: 'Input 3',
        desc: 'Third input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input4',
        displayName: 'Input 4',
        desc: 'Fourth input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input5',
        displayName: 'Input 5',
        desc: 'Fifth input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input6',
        displayName: 'Input 6',
        desc: 'Sixth input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input7',
        displayName: 'Input 7',
        desc: 'Seventh input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input8',
        displayName: 'Input 8',
        desc: 'Eighth input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input9',
        displayName: 'Input 9',
        desc: 'Ninth input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input10',
        displayName: 'Input 10',
        desc: 'Tenth input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
    ],
    variableBindings: {
      SelectorInput: {
        bindingType: 'None',
        value: '',
      },
      Input1: {
        bindingType: 'None',
        value: '',
      },
      Input2: {
        bindingType: 'None',
        value: '',
      },
      Input3: {
        bindingType: 'None',
        value: '',
      },
      Input4: {
        bindingType: 'None',
        value: '',
      },
      Input5: {
        bindingType: 'None',
        value: '',
      },
      Input6: {
        bindingType: 'None',
        value: '',
      },
      Input7: {
        bindingType: 'None',
        value: '',
      },
      Input8: {
        bindingType: 'None',
        value: '',
      },
      Input9: {
        bindingType: 'None',
        value: '',
      },
      Input10: {
        bindingType: 'None',
        value: '',
      },
    },
    deprecated: true,
  },
  NearlyEqual: {
    internalName: 'NearlyEqual',
    name: 'Nearly Equal',
    desc: 'Sets the Output Value to 1 if the absolute difference between the given operands is within the given error threshold, otherwise sets Output Value to 0.',
    category: 'Comparative Logic',
    version: '1.2.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'Operand1',
        displayName: 'Operand 1',
        desc: 'First operand to compare',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Operand2',
        displayName: 'Operand 2',
        desc: 'Second operand to compare',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'ErrorThreshold',
        displayName: 'Error Threshold',
        desc: 'Error threshold within which the given operands are considered to be equal',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Output Value: 1 if equal, else 0',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      Operand1: {
        bindingType: 'None',
        value: '',
      },
      Operand2: {
        bindingType: 'None',
        value: '',
      },
      ErrorThreshold: {
        bindingType: 'None',
        value: '',
      },
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  OffDelayTimer: {
    internalName: 'OffDelayTimer',
    name: 'Off Delay Timer',
    desc: 'Implements a PLC Off Delay Timer (TOF).',
    category: 'Timers',
    version: '1.2.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'Preset',
        displayName: 'Timer Preset',
        desc: 'Duration of Timer in seconds',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Trigger',
        displayName: 'Trigger',
        desc: 'Input signal that controls the starting and stopping of the timer',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Done',
        displayName: 'Done',
        desc: 'Indicates expiration status of Timer',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'TimerTiming',
        displayName: 'Timer Timing',
        desc: 'Indicates if Timer is actively timing',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'ElapsedTime',
        displayName: 'Elapsed Time',
        desc: 'Indicates how much time has elapsed since the Timer started timing',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'RemainingTime',
        displayName: 'Remaining Time',
        desc: 'Indicates how much time is left on Timer before it expires',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      Preset: {
        bindingType: 'None',
        value: '',
      },
      Trigger: {
        bindingType: 'None',
        value: '',
      },
      Done: {
        bindingType: 'None',
        value: '',
      },
      TimerTiming: {
        bindingType: 'None',
        value: '',
      },
      ElapsedTime: {
        bindingType: 'None',
        value: '',
      },
      RemainingTime: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  OnDelayTimer: {
    internalName: 'OnDelayTimer',
    name: 'On Delay Timer',
    desc: 'Implements a PLC On Delay Timer (TON).',
    category: 'Timers',
    version: '1.2.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'Preset',
        displayName: 'Timer Preset',
        desc: 'Duration of Timer in seconds',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Trigger',
        displayName: 'Trigger',
        desc: 'Input signal that controls the starting and stopping of the timer',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Done',
        displayName: 'Done',
        desc: 'Indicates expiration status of Timer',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'TimerTiming',
        displayName: 'Timer Timing',
        desc: 'Indicates if Timer is actively timing',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'ElapsedTime',
        displayName: 'Elapsed Time',
        desc: 'Indicates how much time has elapsed since the Timer started timing',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'RemainingTime',
        displayName: 'Remaining Time',
        desc: 'Indicates how much time is left on Timer before it expires',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      Preset: {
        bindingType: 'None',
        value: '',
      },
      Trigger: {
        bindingType: 'None',
        value: '',
      },
      Done: {
        bindingType: 'None',
        value: '',
      },
      TimerTiming: {
        bindingType: 'None',
        value: '',
      },
      ElapsedTime: {
        bindingType: 'None',
        value: '',
      },
      RemainingTime: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  OperationOnArray: {
    internalName: 'OperationOnArray',
    name: 'Operation On Array',
    desc: 'Sets the Output Value to the result of the selected operation on the given array of inputs.',
    category: 'Selectors',
    version: '1.2.2',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 25,
    variableDefs: [
      {
        name: 'Operation',
        displayName: 'Operation',
        desc: 'Operation to perform',
        ioType: 'input',
        dataType: 'String',
        enumEntries: ['MAX', 'MIN', 'SUM'],
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Inputs',
        displayName: 'Inputs',
        desc: 'Array of inputs upon which to perform operation',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
        isArray: true,
        minElements: 1,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Output Value',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      Inputs: [
        {
          bindingType: 'Number',
          value: '0',
        },
      ],
      Operation: {
        bindingType: 'String',
        value: 'SUM',
      },
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
    deprecated: true,
  },
  PID: {
    internalName: 'PID',
    name: 'PID Controller',
    desc: 'Calculates the Controlled Variable (CV) output using PID Control Loop with the independent equation. The output is in engineering units based on the specified Min/Max.',
    category: 'Output Control',
    version: '2.1.3',
    maxScanErrorThreshold: 10,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'PVTag',
        displayName: 'PV Tag',
        desc: 'Process Variable (PV) Tag',
        ioType: 'input',
        dataType: 'TagDef',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'SP',
        displayName: 'Setpoint (SP)',
        desc: 'Setpoint (SP) value - the target value for the Process Variable in the same engineering units as PV',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'COBias',
        displayName: 'CO Bias',
        desc: 'Initial output value for the PID calculation in same units as the Controlled Variable',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Deadband',
        displayName: 'Deadband',
        desc: 'Deadband to apply to error to reduce wear and tear on controlled equipment in same units as PVTag and SP',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Kp',
        displayName: 'Kp',
        desc: 'Proportional coefficient',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Ki',
        displayName: 'Ki',
        desc: 'Integral coefficient',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Kd',
        displayName: 'Kd',
        desc: 'Derivative coefficient',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Mode',
        displayName: 'Mode',
        desc: 'Either MANUAL (0) or AUTO (1)',
        ioType: 'input',
        dataType: 'String',
        enumEntries: ['MANUAL', 'AUTO'],
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Direction',
        displayName: 'Direction',
        desc: '0=FORWARD (Error=PV-SP) or 1=REVERSE (Error=SP-PV)',
        ioType: 'input',
        dataType: 'String',
        enumEntries: ['FORWARD', 'REVERSE'],
        group: 'Inputs',
        required: true,
      },
      {
        name: 'DerivativeAction',
        displayName: 'Derivative Action',
        desc: '0=PV, 1=ERROR. Determines if derivative term is computed using PV or ERROR',
        ioType: 'input',
        dataType: 'String',
        enumEntries: ['PV', 'ERROR'],
        group: 'Inputs',
        required: true,
      },
      {
        name: 'UpdateTime',
        displayName: 'Update Time',
        desc: 'Loop update time in seconds',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'ManualOutputValue',
        displayName: 'Manual Output Value',
        desc: 'Value in engineering units to set output to when the Mode is set to MANUAL',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Permissive',
        displayName: 'Permissive',
        desc: '0=DISABLED, 1=ENABLED. Determines when the PID is allowed to be executed.',
        ioType: 'input',
        dataType: 'String',
        enumEntries: ['DISABLED', 'ENABLED'],
        group: 'Inputs',
        required: true,
      },
      {
        name: 'COLowLimit',
        displayName: 'CV Low Limit',
        desc: 'Lower limit for the Controlled Variable in engineering units',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'COHighLimit',
        displayName: 'CV High Limit',
        desc: 'Upper limit for the Controlled Variable in engineering units',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'BumplessTransfer',
        displayName: 'Bumpless Transfer',
        desc: '0=DISABLED, 1=ENABLED. If enabled, sets the Controller Bias to the Manual Output when switching from Manual Mode to Auto Mode',
        ioType: 'input',
        dataType: 'String',
        enumEntries: ['DISABLED', 'ENABLED'],
        group: 'Inputs',
        required: false,
      },
      {
        name: 'CV',
        displayName: 'CV',
        desc: 'The Controlled Variable (CV) output value',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'Integral',
        displayName: 'Integral',
        desc: 'The current Integral sum',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'Bias',
        displayName: 'Bias',
        desc: 'The current Bias value',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'UpdateTimerRemainingTime',
        displayName: 'Update Timer Remaining Time',
        desc: 'The time remaining before the PID will be updated',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      PVTag: {
        bindingType: 'TagID',
        value: '',
      },
      SP: {
        bindingType: 'Number',
        value: '30',
      },
      COBias: {
        bindingType: 'Number',
        value: '0',
      },
      Deadband: {
        bindingType: 'Number',
        value: '0',
      },
      Kp: {
        bindingType: 'Number',
        value: '1.0',
      },
      Ki: {
        bindingType: 'Number',
        value: '0',
      },
      Kd: {
        bindingType: 'Number',
        value: '0',
      },
      Mode: {
        bindingType: 'String',
        value: 'MANUAL',
      },
      Direction: {
        bindingType: 'String',
        value: 'FORWARD',
      },
      DerivativeAction: {
        bindingType: 'String',
        value: 'PV',
      },
      UpdateTime: {
        bindingType: 'Number',
        value: '1',
      },
      ManualOutputValue: {
        bindingType: 'TagID',
        value: '',
      },
      Permissive: {
        bindingType: 'String',
        value: 'ENABLED',
      },
      COLowLimit: {
        bindingType: 'None',
        value: '',
      },
      COHighLimit: {
        bindingType: 'None',
        value: '',
      },
      BumplessTransfer: {
        bindingType: 'String',
        value: 'DISABLED',
      },
      CV: {
        bindingType: 'None',
        value: '',
      },
      Integral: {
        bindingType: 'None',
        value: '',
      },
      Bias: {
        bindingType: 'None',
        value: '',
      },
      UpdateTimerRemainingTime: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  PulseRate: {
    internalName: 'PulseRate',
    name: 'Pulse Rate',
    desc: 'Sets the Output Value to the average number of pulses (rising edges) detected in the given input signal in the given amount of time. Uses a sliding window to maintain an average count.',
    category: 'Statistics and Signal Processing',
    version: '1.2.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'PulseInput',
        displayName: 'Pulse Input',
        desc: 'Input that contains pulse signal',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'PulseSamplingWindowSize',
        displayName: 'Pulse Sampling Window Size',
        desc: 'Size of sliding data window, in seconds, within which to count pulses',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'NumPulseSamplingWindows',
        displayName: 'Num Pulse Sampling Windows',
        desc: 'Number of sampling windows to use for averaging the pulse counts',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'NormalizationPeriod',
        displayName: 'Normalization Period',
        desc: 'Period, in seconds, to which to normalize the pulse counts',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Current pulse rate',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      PulseInput: {
        bindingType: 'None',
      },
      PulseSamplingWindowSize: {
        bindingType: 'Number',
        value: '10',
      },
      NumPulseSamplingWindows: {
        bindingType: 'Number',
        value: '6',
      },
      NormalizationPeriod: {
        bindingType: 'Number',
        value: '60',
      },
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  PulseTrainStats: {
    internalName: 'PulseTrainStats',
    name: 'Pulse Train Stats',
    desc: 'Calculates Period, Frequency, On-Time, Off-Time, and Duty Cycle for the given input pulse.',
    category: 'Statistics and Signal Processing',
    version: '1.2.0',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'PulseInput',
        displayName: 'Pulse Input',
        desc: 'Input that contains digital pulse to analyze',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'PulseInputBitNumber',
        displayName: 'Pulse Input Bit Number',
        desc: 'In cases where the pulse is encoded in a single bit in a word, this defines the bit number (1-based - set to zero if Pulse Input is a digital input)',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'TimeoutPeriod',
        displayName: 'Timeout Period',
        desc: 'Maximum time, in seconds, to wait before raising the Timeout Flag',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Characteristic',
        displayName: 'Characteristic',
        desc: 'Wave characteristic to compute',
        ioType: 'input',
        dataType: 'String',
        enumEntries: [
          'FREQUENCY',
          'PERIOD',
          'DUTY CYCLE',
          'ON TIME',
          'OFF TIME',
          'TIMEOUT FLAG',
        ],
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Frequency',
        displayName: 'Frequency',
        desc: 'Frequency of the pulse train',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'Period',
        displayName: 'Period',
        desc: 'Period of the pulse train',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'DutyCycle',
        displayName: 'Duty Cycle',
        desc: 'Duty Cycle of the pulse train',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'Count',
        displayName: 'Pulse Count',
        desc: 'Number of pulses detected since startup',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'OnTime',
        displayName: 'On-Time',
        desc: 'On-Time of the pulse train',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'OffTime',
        displayName: 'Off-Time',
        desc: 'Off-Time of the pulse train',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'TimeoutFlag',
        displayName: 'Timeout Flag',
        desc: 'Indicates if Timeout Period has expired - flag is set if no pulse has been detected within the defined period',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'NumTimeouts',
        displayName: 'Num Timeouts',
        desc: 'Number of timeouts detected since startup',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      PulseInput: {
        bindingType: 'None',
        value: '',
      },
      PulseInputBitNumber: {
        bindingType: 'Number',
        value: '0',
      },
      TimeoutPeriod: {
        bindingType: 'Number',
        value: '0',
      },
      Characteristic: {
        bindingType: 'String',
        value: 'PERIOD',
      },
      Frequency: {
        bindingType: 'None',
        value: '',
      },
      Period: {
        bindingType: 'None',
        value: '',
      },
      DutyCycle: {
        bindingType: 'None',
        value: '',
      },
      Count: {
        bindingType: 'None',
        value: '',
      },
      OnTime: {
        bindingType: 'None',
        value: '',
      },
      OffTime: {
        bindingType: 'None',
        value: '',
      },
      TimeoutFlag: {
        bindingType: 'None',
        value: '',
      },
      NumTimeouts: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  PumpStrokeCounter: {
    internalName: 'PumpStrokeCounter',
    name: 'Pump Stroke Counter',
    desc: "Maintains a running total of strokes detected in the given input signal generated by a positive displacement pump's stroking action or by a proximity sensor.",
    category: 'Statistics and Signal Processing',
    version: '1.2.0',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 25,
    variableDefs: [
      {
        name: 'InputSignal',
        displayName: 'Input Signal',
        desc: 'Input signal from a hydrostatic pressure sensor or proximity sensor',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'DigitizingThreshold',
        displayName: 'Digitizing Threshold',
        desc: 'Digitization threshold to use for detecting pump strokes',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'InputSignalMovingAverageWindowSize',
        displayName: 'Input Signal Moving Average Window Size',
        desc: 'Size of sliding data window, in seconds, for maintaining moving average of input signal that forms the baseline for the digitization',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'TotalStrokeCount',
        displayName: 'Total Stroke Count',
        desc: 'The running stroke count',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'DigitizedPulseValue',
        displayName: 'Digitized Pulse Value',
        desc: 'The digitized value of the input',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      InputSignal: {
        bindingType: 'None',
      },
      DigitizingThreshold: {
        bindingType: 'None',
      },
      InputSignalMovingAverageWindowSize: {
        bindingType: 'Number',
        value: '10',
      },
      TotalStrokeCount: {
        bindingType: 'None',
        value: '',
      },
      DigitizedPulseValue: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  PumpStrokeCounterCalibrator: {
    internalName: 'PumpStrokeCounterCalibrator',
    name: 'Pump Stroke Counter Calibrator',
    desc: "Calculates the threshold determined by examining the dips in the given input signal generated by a positive displacement pump's stroking action.",
    category: 'Statistics and Signal Processing',
    version: '1.2.0',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 25,
    variableDefs: [
      {
        name: 'InputSignal',
        displayName: 'Input Signal',
        desc: 'Input signal from a hydrostatic pressure sensor or proximity sensor',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Mode',
        displayName: 'Mode',
        desc: 'Mode - either 0=RUN or 1=CALIBRATE',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'CalibrationDuration',
        displayName: 'Calibration Duration',
        desc: 'Duration of pump stroke counting calibration process',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'CalibrationTimeSlice',
        displayName: 'Calibration Time Slice',
        desc: 'Time slice to use for pump stroke counting calibration',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'CalibrationOffset',
        displayName: 'Calibration Offset',
        desc: 'Offset to subtract from calibrated ditigization threshold',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'ThresholdManualOverride',
        displayName: 'Threshold Manual Override',
        desc: 'Selects between using calibrated value or manually entered value for digitization threshold',
        ioType: 'input',
        dataType: 'String',
        enumEntries: ['DISABLED', 'ENABLED'],
        group: 'Inputs',
        required: true,
      },
      {
        name: 'ManualDigitizingThreshold',
        displayName: 'Manual Threshold',
        desc: 'Digitization threshold to use when Manual Override is engaged',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'InputSignalMovingAverageWindowSize',
        displayName: 'Input Signal Moving Average Window Size',
        desc: 'Size of sliding data window, in seconds, for maintaining moving average of input signal that forms the baseline for the digitization',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Digitization threshold - either calculated during calibration or manually entered',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      InputSignal: {
        bindingType: 'None',
      },
      Mode: {
        bindingType: 'None',
      },
      CalibrationDuration: {
        bindingType: 'Number',
        value: '10',
      },
      CalibrationTimeSlice: {
        bindingType: 'Number',
        value: '2',
      },
      CalibrationOffset: {
        bindingType: 'Number',
        value: '0.05',
      },
      ThresholdManualOverride: {
        bindingType: 'String',
        value: 'DISABLED',
      },
      ManualDigitizingThreshold: {
        bindingType: 'None',
      },
      InputSignalMovingAverageWindowSize: {
        bindingType: 'Number',
        value: '10',
      },
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  Range: {
    internalName: 'Range',
    name: 'Range',
    desc: 'Sets the Output Value as follows: a) If Min <= Max: 1 if the given Input is in the given range (inclusive), and 0 otherwise, b) If Min > Max:  1 if the given Input is outside the given range (inclusive), and 0 otherwise.',
    category: 'Comparative Logic',
    version: '1.2.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 25,
    variableDefs: [
      {
        name: 'Operand',
        displayName: 'Operand',
        desc: 'Value to check if in range',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'MinValue',
        displayName: 'Min Value',
        desc: 'Minimum value of the range',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'MaxValue',
        displayName: 'Max Value',
        desc: 'Maximum value of the range',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Output Value',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      Operand: {
        bindingType: 'None',
        value: '',
      },
      MinValue: {
        bindingType: 'None',
        value: '',
      },
      MaxValue: {
        bindingType: 'None',
        value: '',
      },
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  RetentiveOnDelayTimer: {
    internalName: 'RetentiveOnDelayTimer',
    name: 'Retentive On Delay Timer',
    desc: 'Implements a PLC Retentive On Delay Timer (RTO).',
    category: 'Timers',
    version: '1.2.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'Preset',
        displayName: 'Timer Preset',
        desc: 'Duration of Timer in seconds',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Trigger',
        displayName: 'Trigger',
        desc: 'Input signal that controls the starting and stopping of the timer',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Reset',
        displayName: 'Reset',
        desc: 'Input signal that controls the resetting of the timer once it completes timing',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Done',
        displayName: 'Done',
        desc: 'Indicates expiration status of Timer',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'TimerTiming',
        displayName: 'Timer Timing',
        desc: 'Indicates if Timer is actively timing',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'ElapsedTime',
        displayName: 'Elapsed Time',
        desc: 'Indicates how much time has elapsed since the Timer started timing',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'RemainingTime',
        displayName: 'Remaining Time',
        desc: 'Indicates how much time is left on Timer before it expires',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      Preset: {
        bindingType: 'None',
        value: '',
      },
      Trigger: {
        bindingType: 'None',
        value: '',
      },
      Reset: {
        bindingType: 'None',
        value: '',
      },
      Done: {
        bindingType: 'None',
        value: '',
      },
      TimerTiming: {
        bindingType: 'None',
        value: '',
      },
      ElapsedTime: {
        bindingType: 'None',
        value: '',
      },
      RemainingTime: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  SelectorNew: {
    internalName: 'SelectorNew',
    name: 'Selector',
    desc: 'Sets the Output Value to the result of the given aggregation function on the given inputs.',
    category: 'Selectors',
    version: '2.2.0',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 25,
    variableDefs: [
      {
        name: 'Operation',
        displayName: 'Operation',
        desc: 'Operation to perform on the given operands',
        ioType: 'input',
        dataType: 'String',
        enumEntries: [
          'MIN',
          'MAX',
          'SUM',
          'AVERAGE',
          'VARIANCE',
          'STANDARD DEVIATION',
          'RANGE',
        ],
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Inputs',
        displayName: 'Inputs',
        desc: 'Array of inputs',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
        isArray: true,
        minElements: 1,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Output Value',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      Operation: {
        bindingType: 'String',
        value: 'MIN',
      },
      Inputs: [
        {
          bindingType: 'None',
          value: '',
        },
      ],
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  Selector: {
    internalName: 'Selector',
    name: 'Selector (Deprecated)',
    desc: "Set the Tag's value to the result of the given aggregation function on the given inputs. If you need to perform Input Selection on more than the provided number of inputs, you can cascade multiple Input Selectors.",
    category: 'Selectors',
    version: '1.0.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 25,
    variableDefs: [
      {
        name: 'Operation',
        displayName: 'Operation',
        desc: 'Operation to perform on the given operands',
        ioType: 'input',
        dataType: 'String',
        enumEntries: ['MIN', 'MAX'],
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Input1',
        displayName: 'Input 1',
        desc: 'First input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Input2',
        displayName: 'Input 2',
        desc: 'Second input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input3',
        displayName: 'Input 3',
        desc: 'Third input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input4',
        displayName: 'Input 4',
        desc: 'Fourth input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input5',
        displayName: 'Input 5',
        desc: 'Fifth input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input6',
        displayName: 'Input 6',
        desc: 'Sixth input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input7',
        displayName: 'Input 7',
        desc: 'Seventh input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input8',
        displayName: 'Input 8',
        desc: 'Eighth input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input9',
        displayName: 'Input 9',
        desc: 'Ninth input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Input10',
        displayName: 'Input 10',
        desc: 'Tenth input',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
    ],
    variableBindings: {
      Operation: {
        bindingType: 'String',
        value: 'MIN',
      },
      Input1: {
        bindingType: 'None',
        value: '',
      },
      Input2: {
        bindingType: 'None',
        value: '',
      },
      Input3: {
        bindingType: 'None',
        value: '',
      },
      Input4: {
        bindingType: 'None',
        value: '',
      },
      Input5: {
        bindingType: 'None',
        value: '',
      },
      Input6: {
        bindingType: 'None',
        value: '',
      },
      Input7: {
        bindingType: 'None',
        value: '',
      },
      Input8: {
        bindingType: 'None',
        value: '',
      },
      Input9: {
        bindingType: 'None',
        value: '',
      },
      Input10: {
        bindingType: 'None',
        value: '',
      },
    },
    deprecated: true,
  },
  SquareWaveOutput: {
    internalName: 'SquareWaveOutput',
    name: 'Square Wave Output',
    desc: 'Emits a square wave defined by the given Duty Cycle and Period.',
    category: 'Output Control',
    version: '1.2.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'DutyCycle',
        displayName: 'Duty Cycle',
        desc: 'Square wave\u00e2\u20ac\u2122s Duty Cycle as a percentage',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Period',
        displayName: 'Period',
        desc: 'Square wave\u00e2\u20ac\u2122s Period in seconds',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Output Value',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      DutyCycle: {
        bindingType: 'None',
        value: '',
      },
      Period: {
        bindingType: 'None',
        value: '',
      },
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  StaticRodLoadNonTandem: {
    internalName: 'StaticRodLoadNonTandem',
    name: 'Static Rod Load (Non-Tandem)',
    desc: 'WARNING-Static Rod Loads are approximate and should not be used to program kills. This is a basic Static Rod Load calculation for non-tandem cylinders. All fields are required.',
    category: 'Compressor Analytics',
    version: '2.2.2',
    maxScanErrorThreshold: 10,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'CalculationType',
        displayName: 'Calculation Type',
        desc: 'Force to calculate',
        dataType: 'String',
        enumEntries: ['Compression', 'Tension'],
      },
      {
        name: 'SuctionPressure',
        displayName: 'Suction Pressure',
        desc: 'Stage Suction Pressure as reported by pressure transducer (psig)',
        dataType: 'Number',
      },
      {
        name: 'DischargePressure',
        displayName: 'Discharge Pressure',
        desc: 'Stage Discharge Pressure as reported by pressure transducer (psig)',
        dataType: 'Number',
      },
      {
        name: 'SuctionPressureDrop',
        displayName: 'Suction Pressure Drop',
        desc: 'Approximate pressure drop between suction transducer location and internals of cylinder (%)',
        dataType: 'Number',
      },
      {
        name: 'DischargePressureDrop',
        displayName: 'Discharge Pressure Drop',
        desc: 'Approximate pressure drop between discharge transducer location and internals of cylinder (%)',
        dataType: 'Number',
      },
      {
        name: 'CylinderDiameter',
        displayName: 'Cylinder Diameter',
        desc: 'Diameter of Cylinder (in)',
        dataType: 'Number',
      },
      {
        name: 'PistonRodDiameter',
        displayName: 'Piston Rod Diameter',
        desc: 'Diameter of Piston Rod (in)',
        dataType: 'Number',
      },
      {
        name: 'CylinderAction',
        displayName: 'Cylinder Action',
        desc: 'Cylinder action',
        dataType: 'String',
        enumEntries: ['Single-CE', 'Single-HE', 'Double'],
      },
      {
        name: 'Elevation',
        displayName: 'Elevation',
        desc: 'Elevation (ft)',
        dataType: 'Number',
        required: true,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Calculated Rod Load',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      CalculationType: {
        bindingType: 'None',
        value: '',
      },
      SuctionPressure: {
        bindingType: 'None',
        value: '',
      },
      DischargePressure: {
        bindingType: 'None',
        value: '',
      },
      SuctionPressureDrop: {
        bindingType: 'Number',
        value: '6',
      },
      DischargePressureDrop: {
        bindingType: 'Number',
        value: '6',
      },
      CylinderDiameter: {
        bindingType: 'None',
        value: '',
      },
      PistonRodDiameter: {
        bindingType: 'None',
        value: '',
      },
      CylinderAction: {
        bindingType: 'None',
        value: '',
      },
      Elevation: {
        bindingType: 'Number',
        value: '0',
      },
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  StaticRodLoadTandem: {
    internalName: 'StaticRodLoadTandem',
    name: 'Static Rod Load (Tandem)',
    desc: 'WARNING-Static Rod Loads are approximate and should not be used to program kills. This is a basic Static Rod Load calculation for tandem cylinders. All fields are required.',
    category: 'Compressor Analytics',
    version: '2.2.2',
    maxScanErrorThreshold: 10,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'CalculationType',
        displayName: 'Calculation Type',
        desc: 'Force to calculate',
        dataType: 'String',
        enumEntries: ['Compression', 'Tension'],
      },
      {
        name: 'SuctionPressureHeadEnd',
        displayName: 'Suction Pressure (Head End)',
        desc: 'Stage Suction Pressure as reported by pressure transducer (psig)',
        dataType: 'Number',
      },
      {
        name: 'SuctionPressureCrankEnd',
        displayName: 'Suction Pressure (Crank End)',
        desc: 'Stage Suction Pressure as reported by pressure transducer (psig)',
        dataType: 'Number',
      },
      {
        name: 'DischargePressureHeadEnd',
        displayName: 'Discharge Pressure (Head End)',
        desc: 'Stage Discharge Pressure as reported by pressure transducer (psig)',
        dataType: 'Number',
      },
      {
        name: 'DischargePressureCrankEnd',
        displayName: 'Discharge Pressure (Crank End)',
        desc: 'Stage Discharge Pressure as reported by pressure transducer (psig)',
        dataType: 'Number',
      },
      {
        name: 'InternalPressure',
        displayName: 'Internal Pressure',
        desc: 'Internal Pressure (psig)',
        dataType: 'Number',
      },
      {
        name: 'SuctionPressureDrop',
        displayName: 'Suction Pressure Drop',
        desc: 'Approximate pressure drop between suction transducer location and internals of cylinder (%)',
        dataType: 'Number',
      },
      {
        name: 'DischargePressureDrop',
        displayName: 'Discharge Pressure Drop',
        desc: 'Approximate pressure drop between discharge transducer location and internals of cylinder (%)',
        dataType: 'Number',
      },
      {
        name: 'CylinderDiameterHeadEnd',
        displayName: 'Cylinder Diameter (Head End)',
        desc: 'Diameter of Cylinder (in)',
        dataType: 'Number',
      },
      {
        name: 'CylinderDiameterCrankEnd',
        displayName: 'Cylinder Diameter (Crank End)',
        desc: 'Diameter of Cylinder (in)',
        dataType: 'Number',
      },
      {
        name: 'PistonRodDiameter',
        displayName: 'Piston Rod Diameter',
        desc: 'Diameter of Piston Rod (in)',
        dataType: 'Number',
      },
      {
        name: 'CylinderAction',
        displayName: 'Cylinder Action',
        desc: 'Cylinder action',
        dataType: 'String',
        enumEntries: ['Single-CE', 'Single-HE', 'Double'],
      },
      {
        name: 'Elevation',
        displayName: 'Elevation',
        desc: 'Elevation (ft)',
        dataType: 'Number',
        required: true,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Calculated Rod Load',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      CalculationType: {
        bindingType: 'None',
        value: '',
      },
      SuctionPressureHeadEnd: {
        bindingType: 'None',
        value: '',
      },
      SuctionPressureCrankEnd: {
        bindingType: 'None',
        value: '',
      },
      DischargePressureHeadEnd: {
        bindingType: 'None',
        value: '',
      },
      DischargePressureCrankEnd: {
        bindingType: 'None',
        value: '',
      },
      InternalPressure: {
        bindingType: 'None',
        value: '',
      },
      SuctionPressureDrop: {
        bindingType: 'Number',
        value: '6',
      },
      DischargePressureDrop: {
        bindingType: 'Number',
        value: '6',
      },
      CylinderDiameterHeadEnd: {
        bindingType: 'None',
        value: '',
      },
      CylinderDiameterCrankEnd: {
        bindingType: 'None',
        value: '',
      },
      PistonRodDiameter: {
        bindingType: 'None',
        value: '',
      },
      CylinderAction: {
        bindingType: 'None',
        value: '',
      },
      Elevation: {
        bindingType: 'Number',
        value: '0',
      },
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  TaskCommandTrigger: {
    internalName: 'TaskCommandTrigger',
    name: 'Task Command Trigger',
    desc: 'Executes the specified Task Command on a rising edge of the Trigger input.',
    category: 'Specialty',
    version: '1.2.2',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'Trigger',
        displayName: 'Trigger',
        desc: 'Input signal that controls the triggering of the specified command',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'TaskName',
        displayName: 'Task Name',
        desc: 'Name of Task to send Command to',
        ioType: 'input',
        dataType: 'String',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Command',
        displayName: 'Command',
        desc: 'Name of Command to send to Task',
        ioType: 'input',
        dataType: 'String',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Parameters',
        displayName: 'Parameters',
        desc: 'Parameters to pass along with the Command',
        ioType: 'input',
        dataType: 'String',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Current Trigger value',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      Trigger: {
        bindingType: 'None',
      },
      TaskName: {
        bindingType: 'String',
        value: '',
      },
      Command: {
        bindingType: 'String',
        value: '',
      },
      Parameters: {
        bindingType: 'String',
        value: '',
      },
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  TimedPulseTimer: {
    internalName: 'TimedPulseTimer',
    name: 'Timed Pulse Timer',
    desc: 'Implements a PLC Timed Pulse (TP).',
    category: 'Timers',
    version: '1.2.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'Preset',
        displayName: 'Timer Preset',
        desc: 'Duration of Timer in seconds',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Trigger',
        displayName: 'Trigger',
        desc: 'Input signal that controls the starting and stopping of the timer',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'IsExtendedTimer',
        displayName: 'Is Extended Timer',
        desc: 'Set to YES to allow unexpired timer to be reset on a trigger change and NO otherwise',
        ioType: 'input',
        dataType: 'String',
        enumEntries: ['NO', 'YES'],
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Output',
        displayName: 'Output',
        desc: 'Indicates expiration status of Timer',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'ElapsedTime',
        displayName: 'Elapsed Time',
        desc: 'Indicates how much time has elapsed since the Timer started timing',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'RemainingTime',
        displayName: 'Remaining Time',
        desc: 'Indicates how much time is left on Timer before it expires',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      Preset: {
        bindingType: 'None',
        value: '',
      },
      Trigger: {
        bindingType: 'None',
        value: '',
      },
      IsExtendedTimer: {
        bindingType: 'String',
        value: 'NO',
      },
      Output: {
        bindingType: 'None',
        value: '',
      },
      ElapsedTime: {
        bindingType: 'None',
        value: '',
      },
      RemainingTime: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  'Timed Ramp Output': {
    internalName: 'Timed Ramp Output',
    name: 'Timed Ramp Output',
    desc: 'Sets the Output Value to the given Target Value at the given rate. Primary use is for interfacing an Analog Output with proportional valves to control the rate at which the valve changes position to avoid slamming open/shut.',
    category: 'Output Control',
    version: '1.2.2',
    maxScanErrorThreshold: 1,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'TargetValue',
        displayName: 'Target Value',
        desc: 'Value to set Output to',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'RateOfChange',
        displayName: 'Rate of Change',
        desc: 'Rate in engineering units per millisecond at which to change the Output until it reaches Target Value',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'MinValue',
        displayName: 'Min Value',
        desc: 'Minimum allowable Output Value',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'MaxValue',
        displayName: 'Max Value',
        desc: 'Maximum allowable Output Value',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Output Value',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      TargetValue: {
        bindingType: 'None',
      },
      RateOfChange: {
        bindingType: 'Number',
        value: '5',
      },
      MinValue: {
        bindingType: 'ThisTag',
        bindingValueSource: 'MinValue',
      },
      MaxValue: {
        bindingType: 'ThisTag',
        bindingValueSource: 'MaxValue',
      },
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  ToggleOutput: {
    internalName: 'ToggleOutput',
    name: 'Toggle Output',
    desc: "Toggle the Output Value when the given Toggling Input's value goes from 0 to 1. The Output Value will stay latched at its current value until the Toggling Input again goes from 0 to 1. Typically used to implement a toggling pushbutton. Two toggling inputs are provided \u00e2\u20ac\u201c the second one is optional and can be used to implement a combination of physical and virtual pushbuttons to control the state of an output.",
    category: 'Output Control',
    version: '1.2.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'TogglingInput1',
        displayName: 'Toggling Input 1',
        desc: "Input that toggles this Tag's value on a rising edge.",
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'TogglingInput2',
        displayName: 'Toggling Input 2',
        desc: "Input that toggles this Tag's value on a rising edge.",
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Output Value',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      TogglingInput1: {
        bindingType: 'None',
        value: '',
      },
      TogglingInput2: {
        bindingType: 'None',
        value: '',
      },
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  TTDTDXMRunStatus: {
    internalName: 'TTDTDXMRunStatus',
    name: 'TTD/TDXM Run Status',
    desc: 'Maps the fault state of one or two TDXMs into the Run Status of a TTD.',
    category: 'Compressor Analytics',
    version: '2.2.1',
    maxScanErrorThreshold: 2,
    requiredAccessLevel: 25,
    variableDefs: [
      {
        name: 'TTDStatusTag',
        displayName: 'TTD Status Tag',
        desc: 'TTD Status Modbus Register',
        dataType: 'TagDef',
      },
      {
        name: 'TDXM1StatusTag',
        displayName: 'TDXM1 Status Tag',
        desc: 'TDXM1 Status Modbus Register',
        dataType: 'TagDef',
      },
      {
        name: 'TDXM1Channel',
        displayName: 'TDXM1 Channel',
        desc: 'Channel Number on TTD that TDXM1 is connected to',
        dataType: 'Number',
      },
      {
        name: 'TDXM1CodeOffset',
        displayName: 'TDXM1 Code Offset',
        desc: 'Offset to use for TDXM1 codes in shutdown legend',
        dataType: 'Number',
      },
      {
        name: 'TDXM2StatusTag',
        displayName: 'TDXM2 Status Tag',
        desc: 'TDXM2 Status Modbus Register',
        dataType: 'TagDef',
      },
      {
        name: 'TDXM2Channel',
        displayName: 'TDXM2 Channel',
        desc: 'Channel Number on TTD that TDXM2 is connected to',
        dataType: 'Number',
      },
      {
        name: 'TDXM2CodeOffset',
        displayName: 'TDXM2 Code Offset',
        desc: 'Offset to use for TDXM2 codes in shutdown legend',
        dataType: 'Number',
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Status Code',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      TTDStatusTag: {
        bindingType: 'None',
      },
      TDXM1StatusTag: {
        bindingType: 'None',
      },
      TDXM1Channel: {
        bindingType: 'Number',
        value: '1',
      },
      TDXM1CodeOffset: {
        bindingType: 'Number',
        value: '100',
      },
      TDXM2StatusTag: {
        bindingType: 'None',
      },
      TDXM2Channel: {
        bindingType: 'Number',
        value: '0',
      },
      TDXM2CodeOffset: {
        bindingType: 'Number',
        value: '200',
      },
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  UnaryBitwiseOperation: {
    internalName: 'UnaryBitwiseOperation',
    name: 'Unary Bitwise Operation',
    desc: 'Set the Output Value to the result of the selected operation on the given operand.',
    category: 'Bitwise Logic',
    version: '1.2.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 25,
    variableDefs: [
      {
        name: 'Operation',
        displayName: 'Operation',
        desc: 'Operation to perform on the given operands',
        ioType: 'input',
        dataType: 'String',
        enumEntries: ['NOT'],
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Operand',
        displayName: 'Operand',
        desc: 'Operand',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Output Value',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      Operation: {
        bindingType: 'String',
        value: 'NOT',
      },
      Operand: {
        bindingType: 'None',
        value: '',
      },
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  UnaryConditionalOperation: {
    internalName: 'UnaryConditionalOperation',
    name: 'Unary Conditional Operation',
    desc: 'Set the Output Value to the result of the selected operation on the given operand. The Output Value is set to 0 if the condition is False and 1 if True. To determine the Boolean value of an operand for evaluation, if the operand is 0 then it is considered False, and if the operand is non-zero it is considered True.',
    category: 'Conditional Logic',
    version: '1.2.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 25,
    variableDefs: [
      {
        name: 'Operation',
        displayName: 'Operation',
        desc: 'Operation to perform on the given operands',
        ioType: 'input',
        dataType: 'String',
        enumEntries: ['NOT'],
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Operand',
        displayName: 'Operand',
        desc: 'Operand',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Output Value',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      Operation: {
        bindingType: 'String',
        value: 'NOT',
      },
      Operand: {
        bindingType: 'None',
        value: '',
      },
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  UnaryMathOperation: {
    internalName: 'UnaryMathOperation',
    name: 'Unary Math Operation',
    desc: 'Set the Output Value to the result of the selected operation on the given operand.',
    category: 'Math',
    version: '1.2.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 25,
    variableDefs: [
      {
        name: 'Operation',
        displayName: 'Operation',
        desc: 'Operation to perform on the given operands',
        ioType: 'input',
        dataType: 'String',
        enumEntries: [
          'ABS',
          'INV',
          'SQRT',
          'NEG',
          'FLOOR',
          'CEIL',
          'MOVE',
          'LOG',
          'LOG10',
          'SIN',
          'COS',
          'TAN',
          'SEC',
          'CSC',
          'COT',
          'ASIN',
          'ACOS',
          'ATAN',
          'TO RADIANS',
          'TO DEGREES',
        ],
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Operand',
        displayName: 'Operand',
        desc: 'Operand',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Output Value',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      Operation: {
        bindingType: 'String',
        value: 'ABS',
      },
      Operand: {
        bindingType: 'None',
        value: '',
      },
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  WaterMark: {
    internalName: 'WaterMark',
    name: 'Water Mark',
    desc: 'Set the Output Value to the low or high water mark for the given input.',
    category: 'Selectors',
    version: '1.2.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 25,
    variableDefs: [
      {
        name: 'InputValue',
        displayName: 'Input Value',
        desc: 'Input value for which to determine low or high water mark',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'WaterMarkType',
        displayName: 'Water Mark Type',
        desc: 'Determines whether to calculate the low or high water mark',
        ioType: 'input',
        dataType: 'String',
        enumEntries: ['LOW', 'HIGH'],
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Reset',
        displayName: 'Reset',
        desc: 'Signal that resets the water mark on a rising edge',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Result',
        displayName: 'Result',
        desc: 'Output Value',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      InputValue: {
        bindingType: 'None',
        value: '',
      },
      WaterMarkType: {
        bindingType: 'String',
        value: 'LOW',
      },
      Reset: {
        bindingType: 'None',
        value: '',
      },
      Result: {
        bindingType: 'None',
        value: '',
      },
    },
  },
  WaveAnalyzer: {
    internalName: 'WaveAnalyzer',
    name: 'Wave Analyzer',
    desc: "Set the Tag's value to a selected characteristic of the given digital (square) or analog (sinusoidal) wave input. Maintains a sliding window of waves and calculates the moving average of the requested characteristic.",
    category: 'Statistics and Signal Processing',
    version: '1.2.1',
    maxScanErrorThreshold: 0,
    requiredAccessLevel: 75,
    variableDefs: [
      {
        name: 'WaveInput',
        displayName: 'Wave Input',
        desc: 'Input that contains digital square wave signal or sinusoidal analog wave',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'WaveType',
        displayName: 'Wave Type',
        desc: 'Defines the kind of wave to expect for the Wave Input',
        ioType: 'input',
        dataType: 'String',
        enumEntries: ['DIGITAL', 'ANALOG'],
        group: 'Inputs',
        required: true,
      },
      {
        name: 'NumSamples',
        displayName: 'Num Samples',
        desc: 'Number of wave samples to maintain in sliding window',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Characteristic',
        displayName: 'Characteristic',
        desc: 'Wave characteristic to compute',
        ioType: 'input',
        dataType: 'String',
        enumEntries: [
          'FREQUENCY',
          'PERIOD',
          'DUTY CYCLE',
          'ON TIME',
          'OFF TIME',
          'COUNT',
        ],
        group: 'Inputs',
        required: true,
      },
      {
        name: 'Reset',
        displayName: 'Reset',
        desc: 'Signal that resets the statistics',
        ioType: 'input',
        dataType: 'Number',
        group: 'Inputs',
        required: false,
      },
      {
        name: 'Frequency',
        displayName: 'Frequency',
        desc: 'Frequency of the periodic signal ([1] / [Period])',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'Period',
        displayName: 'Period',
        desc: 'Period of the periodic signal ([1] / [Frequency])',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'DutyCycle',
        displayName: 'Duty Cycle',
        desc: 'Duty Cycle of the periodic signal ([On Time] / [Period])',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'Count',
        displayName: 'Count',
        desc: 'Number of waves/pulses detected since startup',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'OnTime',
        displayName: 'On Time',
        desc: 'Average length of time that signal is on',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
      {
        name: 'OffTime',
        displayName: 'Off Time',
        desc: 'Average length of time that signal is off',
        ioType: 'output',
        dataType: 'Number',
        group: 'Outputs',
        required: false,
      },
    ],
    variableBindings: {
      WaveInput: {
        bindingType: 'None',
        value: '',
      },
      WaveType: {
        bindingType: 'String',
        value: 'DIGITAL',
      },
      Characteristic: {
        bindingType: 'String',
        value: 'PERIOD',
      },
      NumSamples: {
        bindingType: 'Number',
        value: '10',
      },
      Reset: {
        bindingType: 'None',
        value: '',
      },
      Frequency: {
        bindingType: 'None',
      },
      Period: {
        bindingType: 'None',
      },
      DutyCycle: {
        bindingType: 'None',
      },
      Count: {
        bindingType: 'None',
      },
      OnTime: {
        bindingType: 'None',
      },
      OffTime: {
        bindingType: 'None',
      },
    },
  },
};
