import DragModal from './DragModal';
import {
  Divider,
  DialogContent,
  Box,
  Typography,
  Autocomplete,
  TextField,
  CircularProgress,
  createFilterOptions,
  LinearProgress,
} from '@mui/material';
import { CodeLegendContext } from '../contexts/CodeLegendContext';
import { useContext, useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { getCodeLegendEntriesById } from '../helpers/APIHelpers';

const CodeLegendSelector = ({ open, sel, setSel, onClose }) => {
  const codeLegends = useContext(CodeLegendContext);

  const [entryTableData, setEntryTableData] = useState([]);
  const [loadingEntries, setLoadingEntries] = useState(false);

  useEffect(() => {
    if (!sel || (sel && sel.id === -1)) {
      return;
    } else {
      const getEntries = async () => {
        setLoadingEntries(true);
        setEntryTableData([]);
        let codeLegendEntries = await getCodeLegendEntriesById(sel.id);
        setEntryTableData(codeLegendEntries);
        setLoadingEntries(false);
      };
      getEntries();
    }
  }, [sel]);

  const codeLegendEntryColumns = [
    {
      field: 'StatusValue',
      headerName: 'Code',
      flex: 0.5,
      type: 'string',
      editable: false,
      hideable: false,
    },
    {
      field: 'Description',
      headerName: 'Description',
      flex: 0.5,
      type: 'string',
      editable: false,
      hideable: false,
    },
    {
      field: 'AlarmStateName',
      headerName: 'Alarm State',
      flex: 0.5,
      type: 'string',
      editable: false,
      hideable: false,
    },
    {
      field: 'SendAlerts',
      headerName: 'Send Alerts?',
      flex: 0.5,
      type: 'boolean',
      editable: false,
      hideable: false,
    },
  ];

  return (
    <DragModal
      open={open}
      onClose={() => {
        onClose(sel);
      }}
      fullWidth
      maxWidth="md"
      disablefocus="true"
      titlecomponent={
        <Typography variant="h5" color="primary">
          Select Code Legend
        </Typography>
      }
    >
      <Divider />
      <DialogContent>
        <Box sx={{ minWidth: 300 }}>
          <Autocomplete
            value={
              sel ?? {
                label: 'None Selected',
                value: 'None Selected',
                id: -1,
              }
            }
            onChange={(_, newValue) => {
              setSel(newValue);
            }}
            options={[
              ...codeLegends,
              { label: 'None Selected', value: 'None Selected', id: -1 },
            ]}
            getOptionLabel={(legend) => legend.label}
            getOptionKey={(legend) => legend.id}
            isOptionEqualToValue={(option, value) => {
              return option.value === value.value;
            }}
            disabled={codeLegends.length <= 1}
            filterOptions={createFilterOptions({
              matchFrom: 'any',
              limit: 50,
            })}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Code Legends"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>{codeLegends.length <= 1 ? <CircularProgress /> : null}</>
                  ),
                }}
              />
            )}
          />
          <br></br>
          {sel && sel.id !== -1 ? (
            <div style={{ height: 350, width: '100%' }}>
              <DataGrid
                columns={codeLegendEntryColumns}
                rows={entryTableData}
                getRowId={(row) => row.CodeLegendEntryId}
              />
              {loadingEntries && <LinearProgress />}
            </div>
          ) : (
            <></>
          )}
        </Box>
      </DialogContent>
    </DragModal>
  );
};

export default CodeLegendSelector;
