import { Divider, Paper, Typography } from '@mui/material';

export default function Error404Page(props) {
  return (
    <Paper sx={{ textAlign: 'center', pt: 5, px: 4, minHeight: 800 }}>
      <Typography sx={{ fontSize: '5em' }} color="primary">
        404 Error
      </Typography>
      <Divider />
      <Typography variant="h5">
        Oops! The resource you requested does not exist. If you think this is an
        error please contact the administrator at support@detechtion.com
      </Typography>
    </Paper>
  );
}
