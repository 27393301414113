export const insertElement = (arr, item, at) => {
  if (!Array.isArray(item) && typeof item === 'object') {
    arr.splice(at, 0, { ...item });
  } else if (Array.isArray(item)) {
    arr.splice(at, 0, [...item]);
  } else {
    arr.splice(at, 0, item);
  }
};

export const deleteElement = (arr, at) => {
  arr.splice(at, 1);
};

export const updateIndexList = (list, at, by) => {
  const newList = list.map((i) => {
    if (i >= at) {
      return i + by;
    } else return i;
  });
  return newList;
};
