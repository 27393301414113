import DragModal from './DragModal';
import {
  DialogContent,
  Divider,
  Typography,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  Button,
} from '@mui/material';
import { TagListContext } from '../contexts/TagListContext';
import { useContext, useEffect, useState } from 'react';
import { deleteTagListAndTags } from '../helpers/APIHelpers';

export const ManageTagListsModal = ({ open, onClose, setInfoMessages }) => {
  const availableTagLists = useContext(TagListContext);
  const [tagLists, setTagLists] = useState([
    { Name: 'Loading...', TCTagListId: -1 },
  ]);

  useEffect(() => {
    if (availableTagLists.TagLists && availableTagLists.TagLists.length > 0) {
      setTagLists(availableTagLists.TagLists);
    }
  }, [availableTagLists]);

  return (
    <DragModal
      open={open}
      title="editTagsSource"
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      titlecomponent={
        <Typography variant="h5" color="primary">
          Manage Tag Lists
        </Typography>
      }
    >
      <Divider />
      <DialogContent>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Tag List</TableCell>
                <TableCell align="right">Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tagLists.map((row) => (
                <TableRow
                  key={row.TCTagListId}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.Name}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      onClick={async () => {
                        try {
                          setInfoMessages({
                            type: 'snackbar',
                            severity: 'info',
                            message: 'Tag List has been queued for delete.',
                            open: true,
                          });
                          await deleteTagListAndTags(row.TCTagListId);
                          setInfoMessages({
                            message:
                              'Tag List deleted successfully. You may need to refresh for it to disappear from the available lists.',
                            severity: 'success',
                            type: 'snackbar',
                            open: true,
                          });
                        } catch (error) {
                          setInfoMessages({
                            type: 'snackbar',
                            message:
                              'There was an issue deleting the tag list. Please try again or contact administrator.',
                            severity: 'error',
                            open: true,
                          });
                        }
                      }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </DragModal>
  );
};
