import { InfoRounded } from '@mui/icons-material';
import {
  Select,
  MenuItem,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
} from '@mui/material';

const SelectCollisionMode = ({
  collisionMode,
  setCollisionMode,
  showCollisionInfo,
}) => {
  return (
    <>
      {collisionMode === 'None Set' && (
        <IconButton
          onClick={() => {
            showCollisionInfo();
          }}
        >
          <InfoRounded />
        </IconButton>
      )}
      <FormControl sx={{ width: 200 }}>
        <InputLabel id="collision-mode-label">Collision Mode</InputLabel>
        <Select
          labelId="collision-mode-label"
          value={collisionMode}
          onChange={(e) => {
            setCollisionMode(e.target.value);
            localStorage.setItem('collisionMode', e.target.value);
          }}
          size={'small'}
          input={<OutlinedInput label="Collision Mode" />}
        >
          <MenuItem value={'None Set'}>None Set</MenuItem>
          <MenuItem value={'Ignore'}>Ignore</MenuItem>
          <MenuItem value={'Overwrite'}>Overwrite</MenuItem>
          <MenuItem value={'Skip'}>Skip</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default SelectCollisionMode;
