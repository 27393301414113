import AssetFilter from './AssetFilter';
import DragModal from './DragModal';
import { Box, DialogContent, Divider, Typography } from '@mui/material';

export default function EditTabSourceModal({
  open,
  onClose,
  setSourceFilter,
  sourceFilter,
}) {
  const handleAssetFilterActions = (action) => {
    if (action.filter) {
      setSourceFilter(action.filter);
    }
    if (action.modalState === 'closed') {
      onClose();
    }
  };

  return (
    <DragModal
      open={open}
      title="editTagsSource"
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      titlecomponent={
        <Typography variant="h5" color="primary">
          Filter
        </Typography>
      }
    >
      <Divider />
      <DialogContent>
        <Box sx={{ minWidth: 300 }}>
          <AssetFilter
            presetFilter={sourceFilter}
            assetFilterActions={handleAssetFilterActions}
          />
        </Box>
      </DialogContent>
    </DragModal>
  );
}
