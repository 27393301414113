import { FileUploadOutlined, ContentPaste } from '@mui/icons-material';
import DragModal from './DragModal';
import {
  Box,
  DialogContent,
  Divider,
  TextField,
  Typography,
  Grid,
  Button,
} from '@mui/material';
import { useState } from 'react';

export default function LoadXMLModal({ open, onClose, setOpen, loadXML }) {
  const [xmlToParse, setXMLToParse] = useState('');

  return (
    <DragModal
      open={open}
      title="editTagsSource"
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      titlecomponent={
        <Typography variant="h5" color="primary">
          Load Tags from XML
        </Typography>
      }
    >
      <Divider />
      <DialogContent>
        <Grid container columns={1} spacing={1}>
          <Grid item xs={1}>
            <Box sx={{ minWidth: 'auto' }}>
              <Typography>
                Paste one or more WriteTagConfigRequests below to import tags
                with XML:
              </Typography>
              <TextField
                multiline
                fullWidth
                minRows={4}
                maxRows={6}
                value={xmlToParse}
                onChange={(e) => {
                  setXMLToParse(e.target.value);
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                loadXML(xmlToParse);
                setXMLToParse('');
                setOpen(false);
              }}
              startIcon={<ContentPaste />}
            >
              Load Pasted XML
            </Button>
          </Grid>
          <Grid item xs={1}>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<FileUploadOutlined />}
            >
              Upload XML File
              <input
                type="file"
                onChange={(event) => {
                  for (const file of event.target.files) {
                    file.text().then((text) => {
                      loadXML(text);
                    });
                  }
                  setOpen(false);
                }}
                hidden
                multiple
              />
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </DragModal>
  );
}
