import { createContext, useReducer, useContext } from 'react';

// Theme and colors stuff
export const DarkModeTheme = createContext(false);
export const DarkModeThemeDispatch = createContext(null); // for modifying DarkModeTheme

export function ThemeColorModeProvider({ children }) {
  const [colorMode, dispatchColorMode] = useReducer(
    themeColorModeReducer,
    false,
  );

  return (
    <DarkModeTheme.Provider value={colorMode}>
      <DarkModeThemeDispatch.Provider value={dispatchColorMode}>
        {children}
      </DarkModeThemeDispatch.Provider>
    </DarkModeTheme.Provider>
  );
}

export function useDarkMode() {
  return useContext(DarkModeTheme);
}

export function useDarkModeDispatch() {
  return useContext(DarkModeThemeDispatch);
}

function themeColorModeReducer(state, action) {
  switch (action.type) {
    case 'TOGGLE': {
      console.log('in toggle, current state: ' + state);
      return !state;
    }
    default: {
      return state;
    }
  }
}

// global constants
export const FilterModel = {
  LocationName: {
    type: 'string',
    label: 'Location Name',
    fieldSource: 'Asset',
  },
  Lease: { type: 'string', label: 'Lease', fieldSource: 'Asset' },
  ActivationStatus: {
    type: 'string',
    label: 'Activation Status',
    fieldSource: 'Asset',
  },
  AssetNumber: {
    type: 'string',
    label: 'Asset Number',
    fieldSource: 'Asset',
  },
  AssetTagId: {
    type: 'int',
    label: 'Tag Id',
    fieldSource: 'Tag',
  },
  TagIdentifier: {
    type: 'int',
    label: 'Global Tag Identifier',
    fieldSource: 'Tag',
  },
  AssetId: {
    type: 'int',
    label: 'Asset ID',
    fieldSource: 'Tag',
  },
  AlarmArmingTypeId: {
    label: 'Alarm Arming Type',
    fieldSource: 'Tag',
    type: 'int',
  },
  IsAlarmEnabled: {
    label: 'Alarm Enabled',
    fieldSource: 'Tag',
    type: 'bool',
  },
  AlarmHi: {
    label: 'Alarm Hi',
    fieldSource: 'Tag',
    type: 'double',
  },
  AlarmHiHi: {
    label: 'Alarm HiHi',
    fieldSource: 'Tag',
    type: 'double',
  },
  AlarmLo: {
    label: 'Alarm Lo',
    fieldSource: 'Tag',
    type: 'double',
  },
  AlarmLoLo: {
    label: 'Alarm LoLo',
    fieldSource: 'Tag',
    type: 'double',
  },
  AlarmDeadband: {
    label: 'Alarm Deadband',
    fieldSource: 'Tag',
    type: 'double',
  },
  ChannelNumber: {
    label: 'Channel/Register',
    fieldSource: 'Tag',
    type: 'int',
  },
  DataTypeId: {
    label: 'Data Type',
    fieldSource: 'Tag',
    type: 'int',
  },
  Description: {
    label: 'Description',
    fieldSource: 'Tag',
    type: 'string',
  },
  DeviceParams: {
    label: 'Device Params',
    fieldSource: 'Tag',
    type: 'string',
  },
  DisplayFormat: {
    label: 'Display Format',
    fieldSource: 'Tag',
    type: 'string',
  },
  DisplayOrder: {
    label: 'Display Order',
    fieldSource: 'Tag',
    type: 'int',
  },
  Gain: {
    label: 'Gain',
    fieldSource: 'Tag',
    type: 'double',
  },
  GroupName: {
    label: 'Group Name',
    fieldSource: 'Tag',
    type: 'string',
  },
  LoggingFrequency: {
    label: 'Logging Frequency',
    fieldSource: 'Tag',
    type: 'double',
  },
  MaxValue: {
    label: 'Maximum Value',
    fieldSource: 'Tag',
    type: 'double',
  },
  MinValue: {
    label: 'Minimum Value',
    fieldSource: 'Tag',
    type: 'double',
  },
  Offset: {
    label: 'Offset',
    fieldSource: 'Tag',
    type: 'double',
  },
  ShortDisplayName: {
    label: 'Short Display Name',
    fieldSource: 'Tag',
    type: 'string',
  },
  SourceName: {
    label: 'Source Name',
    fieldSource: 'Tag',
    type: 'string',
  },
  SourceTypeId: {
    label: 'Source Type',
    fieldSource: 'Tag',
    type: 'int',
  },
  AlarmTypeId: {
    label: 'Tag Alarm Type',
    fieldSource: 'Tag',
    type: 'int',
  },
  TagName: {
    label: 'Tag Name',
    fieldSource: 'Tag',
    type: 'string',
  },
  TagTypeId: {
    label: 'Tag Type',
    fieldSource: 'Tag',
    type: 'int',
  },
  UnitOfMeasure: {
    label: 'Unit of Measure',
    fieldSource: 'Tag',
    type: 'string',
  },
};
