import {
  Autocomplete,
  Button,
  Box,
  Collapse,
  Alert,
  Container,
  TextField,
  IconButton,
  Select,
  MenuItem,
  Grid,
  Typography,
  Divider,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useMemo, useState, Fragment } from 'react';
import { FilterModel } from '../contexts/GlobalContext';

const AssetFilter = ({ assetFilterActions, presetFilter }) => {
  const [dynamicFilterRows, setDynamicFilterRows] = useState([
    {
      rowId: 0,
      operator: '',
      fieldName: null,
      comparator: '',
      value: '',
      fieldSource: '',
      addButton: true,
    },
  ]);

  const [alertDisplayState, setAlertDisplayState] = useState({
    open: false,
    severity: 'warning',
    message: '',
  });

  const tagFieldOptions = useMemo(() => Object.keys(FilterModel), []);

  useEffect(() => {
    if (presetFilter?.length) {
      setDynamicFilterRows(presetFilter);
    }
  }, [presetFilter]);

  const handleFieldUpdate = (value, rowNum, field) => {
    const currentVals = [...dynamicFilterRows];
    currentVals[rowNum][field] = value;
    // find the fieldSource
    if (field === 'fieldName') {
      currentVals[rowNum].fieldSource = FilterModel[value]?.fieldSource;
    }
    setDynamicFilterRows(currentVals);
  };

  const handleAmendFilterButton = (step) => {
    const newRows = [...dynamicFilterRows];
    const numRows = newRows.length;
    if (step === -1) {
      newRows.pop();
      if (newRows.length) newRows[newRows.length - 1].addButton = true;
      setDynamicFilterRows(newRows);
    } else {
      if (newRows.length) newRows[numRows - 1].addButton = false;
      newRows.push({
        rowId: numRows,
        operator: 'AND',
        fieldName: 'AssetNumber',
        comparator: '=',
        value: '',
        fieldSource: FilterModel['AssetNumber'].fieldSource,
        addButton: true,
      });
      setDynamicFilterRows(newRows);
    }
  };

  const handleApplyFilterButton = () => {
    // do validation here
    if (
      !dynamicFilterRows.length ||
      !dynamicFilterRows[0].fieldName ||
      !dynamicFilterRows[0].value
    ) {
      handleAlertState(
        true,
        'error',
        'At least one filter criteria in relation to AssetId or AssetNumber must be specified',
      );
    } else if (dynamicFilterRows.length > 1) {
      const emptyFields = dynamicFilterRows.find(
        (item) =>
          item.rowId > 0 &&
          (!item.operator ||
            !item.comparator ||
            !item.fieldName ||
            !item.value),
      );
      if (emptyFields) {
        handleAlertState(
          true,
          'error',
          'All exisiting filter rows must be filled out. Please remove filter rows if you do not plan to use them.',
        );
      } else {
        assetFilterActions({ modalState: 'closed', filter: dynamicFilterRows });
      }
    } else {
      assetFilterActions({ modalState: 'closed', filter: dynamicFilterRows });
    }
  };

  const handleAlertState = (open, type, message) => {
    if (open === false)
      setAlertDisplayState({ open: false, severity: 'info', message: '' });
    else {
      setAlertDisplayState({ open: true, severity: type, message });
    }
  };

  return (
    <Container>
      <Box>
        <Collapse in={alertDisplayState.open}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => handleAlertState(false)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
            severity={alertDisplayState.severity}
          >
            {alertDisplayState.message}
          </Alert>
        </Collapse>
      </Box>
      <Grid container spacing={1} columns={{ xs: 5 }}>
        <Grid item xs={1}>
          <Typography variant="h6">Operator</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h6">Field</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h6">Comparator</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h6">Value</Typography>
        </Grid>
        <Grid item xs={1}>
          &nbsp;
        </Grid>
        <Grid item xs={5}>
          <Divider />
        </Grid>
        {dynamicFilterRows.map((item) => {
          return (
            <Fragment key={item.rowId}>
              <Grid item xs={1} key={'row' + item.rowId + '-operator'}>
                <Select
                  disabled={item.rowId === 0}
                  title={`operator-${item.rowId}`}
                  id={`operator-${item.rowId}`}
                  name={`operator-${item.rowId}`}
                  aria-label={`operator input row ${item.rowId}`}
                  value={item.rowId === 0 ? '' : item.operator}
                  displayEmpty={item.rowId === 0}
                  onChange={(event) =>
                    handleFieldUpdate(
                      event.target.value,
                      item.rowId,
                      'operator',
                    )
                  }
                  sx={{ width: '100%' }}
                  data-testid={`test-operator-${item.rowId}`}
                >
                  {item.rowId === 0 && <MenuItem value="">--</MenuItem>}
                  <MenuItem value="AND">AND</MenuItem>
                  <MenuItem value="OR">OR</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={1} key={'row' + item.rowId + '-fieldName'}>
                <Autocomplete
                  title={`fieldName-${item.rowId}`}
                  id={`fieldName-${item.rowId}`}
                  name={`fieldName-${item.rowId}`}
                  aria-label={`fieldName input row ${item.rowId}`}
                  options={tagFieldOptions}
                  getOptionLabel={(opt) => FilterModel[opt].label ?? opt}
                  groupBy={(option) => FilterModel[option]?.fieldSource}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{ width: '100%' }}
                  value={item.fieldName}
                  onChange={(event, value) =>
                    handleFieldUpdate(value, item.rowId, 'fieldName')
                  }
                  data-testid={`test-fieldName-${item.rowId}`}
                />
              </Grid>
              <Grid item xs={1} key={'row' + item.rowId + '-comparator'}>
                <Select
                  title={`comparator-${item.rowId}`}
                  id={`comparator-${item.rowId}`}
                  name={`comparator-${item.rowId}`}
                  aria-label={`comparator input row ${item.rowId}`}
                  value={item.comparator}
                  sx={{ width: '100%' }}
                  onChange={(event) =>
                    handleFieldUpdate(
                      event.target.value,
                      item.rowId,
                      'comparator',
                    )
                  }
                  //data-testid={`test-comparator-${item.rowId}`}
                  inputProps={{ 'data-testid': 'test-comparator-0' }}
                >
                  <MenuItem value="<">&lt;</MenuItem>
                  <MenuItem value=">">&gt;</MenuItem>
                  <MenuItem value="=">=</MenuItem>
                  <MenuItem value="<=">&le;</MenuItem>
                  <MenuItem value=">=">&ge;</MenuItem>
                  <MenuItem value="!=">&ne;</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={1} key={'row' + item.rowId + '-value'}>
                <TextField
                  variant="outlined"
                  disabled={!item.fieldName}
                  title={`value-${item.rowId}`}
                  id={`value-${item.rowId}`}
                  name={`value-${item.rowId}`}
                  aria-label={`value input row ${item.rowId}`}
                  value={item.value}
                  sx={{ width: '100%' }}
                  onKeyDown={(event) => {
                    // enforce numerical stuff
                    const fieldType = FilterModel[item.fieldName].type;
                    const allowedKeys = [
                      '0',
                      '1',
                      '2',
                      '3',
                      '4',
                      '5',
                      '6',
                      '7',
                      '8',
                      '9',
                      '-',
                      '.',
                      'Backspace',
                      'Delete',
                      'ArrowLeft',
                      'ArrowRight',
                      'ArrowUp',
                      'ArrowDown',
                    ];
                    if (
                      fieldType === 'int' &&
                      event.key !== '.' &&
                      !allowedKeys.includes(event.key)
                    ) {
                      event.preventDefault();
                    } else if (
                      ['float', 'double', 'decimal'].includes(fieldType) &&
                      !allowedKeys.includes(event.key)
                    ) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(event) => {
                    handleFieldUpdate(event.target.value, item.rowId, 'value');
                  }}
                  data-testid={`test-value-${item.rowId}`}
                />
              </Grid>
              <Grid item xs={1} key={'row' + item.rowId + '-addButton'}>
                {item.addButton ? (
                  <IconButton
                    aria-label="add row"
                    onClick={() => handleAmendFilterButton()}
                    sx={{ verticalAlign: 'bottom', mb: -3.5 }}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                ) : (
                  ' '
                )}
                {item.addButton && item.rowId > 0 ? (
                  <IconButton
                    aria-label="remove row"
                    onClick={() => handleAmendFilterButton(-1)}
                    sx={{ verticalAlign: 'bottom', mb: -3.5 }}
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                ) : (
                  ' '
                )}
              </Grid>
            </Fragment>
          );
        })}
        <Grid item xs={5} marginTop={2}>
          <Button
            variant="outlined"
            onClick={handleApplyFilterButton}
            data-testid="apply-filter-button"
          >
            Apply Filter
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AssetFilter;
