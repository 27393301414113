import {
  Alert,
  Button,
  Dialog,
  Snackbar,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
  Typography,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

/** A convience wrapper function to display errors to the users. It can either be a modal or a toast/snackbar. If type is 'dialog' it will
 * accept  modal api params as well.
 * @param {object} props - Properties of the modal
 * @param {boolean} props.open Whether to open the dialog
 * @param {string} props.type The type of modal or toast
 * @param {string} [props.title] The title of the dialog
 * @param {string} [props.severity] The severity of the dialog (toast only)
 * @param {string | React.JSX.Element[]} props.message The message to display. Either string or DOM elements array.
 * @param {function} props.onClose handler for when the dialog is closed
 * @param {object} [props.anchorOrigin] The display location of the snackbar. {vertical, horizontal}
 * @param {object} [props.DialogProps] The props to be passed to the base MUI Dialog component/modal.
 * @param {object} [props.SnackbarProps] The props to be passed to the base MUI snackbar.
 * @example <Box>
              <Button
                variant="outlined"
                color="primary"
                onClick={() =>
                  setErrorFeedbackState({
                    open: true,
                    type: 'dialog',
                    title: (
                      <Typography variant="h5" component="div" color="primary">
                        ERROR
                      </Typography>
                    ),
                    message: 'An error occurred',
                    actions: [
                      <Button
                        variant="text"
                        color="primary"
                        onClick={() =>
                          setErrorFeedbackState({
                            ...errorFeedbackState,
                            open: false,
                          })
                        }
                      >
                        Acknowledge
                      </Button>,
                    ],
                  })
                }
              >
                OpenModal
              </Button>
            </Box>
            <FeedbackModal
              open={errorFeedbackState.open}
              onClose={() =>
                setErrorFeedbackState({ ...errorFeedbackState, open: false })
              }
              actions={errorFeedbackState.actions}
              message={errorFeedbackState.message}
              type={errorFeedbackState.type}
              title={errorFeedbackState.title}
            />
 */
export default function FeedbackModal(props) {
  const {
    open,
    type,
    title,
    severity,
    message,
    actions,
    onClose,
    anchorOrigin,
  } = props;
  if (type === 'modal' || type === 'dialog') {
    return (
      <Dialog open={open} {...props.DialogProps}>
        <DialogTitle id="feedback-modal-title" sx={{ textAlign: 'center' }}>
          {title || (
            <Typography variant="h5" color="primary" gutterBottom>
              Alert
            </Typography>
          )}
        </DialogTitle>
        <Divider />
        <DialogContent>{message}</DialogContent>
        <DialogActions>
          {Array.isArray(actions) && actions.length ? (
            actions.map((i) => i)
          ) : (
            <Button onClick={onClose} variant="text" color="primary">
              OK
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  } else if (type === 'snackbar' || type === 'toast') {
    return (
      <Snackbar
        onClose={onClose}
        open={open}
        anchorOrigin={
          anchorOrigin ?? { horizontal: 'center', vertical: 'middle' }
        }
        autoHideDuration={
          props.autoHideDuration >= 0
            ? props.autoHideDuration
            : props.autoHideDuration === false
              ? undefined
              : 3500
        }
        {...props.SnackbarProps}
      >
        <Alert
          onClose={onClose}
          severity={severity || 'info'}
          variant="filled"
          action={
            Array.isArray(actions) && actions.legnth ? (
              actions.map((i) => i)
            ) : (
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => onClose()}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )
          }
        >
          {message}
        </Alert>
      </Snackbar>
    );
  } else throw new Error('Unknown type usage for FeedbackModal: ' + type);
}
