export const fl109 = {
    "Analog Control Output": {
        "internalName": "Analog Control Output",
        "name": "Analog Control Output",
        "desc": "Sets the Tag's value to the given Target Value at the given rate. Supports switching to Manual Mode to override the Target Value and use the given Manual Output Value",
        "category": "Controllers",
        "version": "1.0.1",
        "maxScanErrorThreshold": "1",
        "requiredAccessLevel": 75,
        "variableDefs": [
            {
                "name": "TargetValue",
                "displayName": "Target Value",
                "desc": "Value to set Output to",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "RateOfChange",
                "displayName": "Rate of Change",
                "desc": "Rate in engineering units per millisecond at which to change the Output until it reaches Target Value",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Mode",
                "displayName": "Mode",
                "desc": "Either MANUAL (0) or AUTO (1)",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "ManualOutputValue",
                "displayName": "Manual Output Value",
                "desc": "Value in engineering units to set output to when the Mode is set to MANUAL",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            }
        ],
        "variableBindings": {
            "TargetValue": {
                "bindingType": "None",
                "value": ""
            },
            "RateOfChange": {
                "bindingType": "Number",
                "value": "0"
            },
            "Mode": {
                "bindingType": "Number",
                "value": "0"
            },
            "ManualOutputValue": {
                "bindingType": "None",
                "value": ""
            }
        }
    },
    "AnalogSignalDigitizer": {
        "internalName": "AnalogSignalDigitizer",
        "name": "Analog Signal Digitizer",
        "desc": "Convert the given Analog Signal Input into a digitized Square Wave based on given Logic High and Logic Low thresholds.",
        "category": "Statistics and Signal Processing",
        "version": "1.0.2",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 75,
        "variableDefs": [
            {
                "name": "AnalogSignalInput",
                "displayName": "Analog Signal Input",
                "desc": "Input that contains analog signal to digitize",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "LogicHighThreshold",
                "displayName": "Logic High Threshold",
                "desc": "Minimum value of the Analog Signal Input that is to be considered a Logic High (1)",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "LogicLowThreshold",
                "displayName": "Logic Low Threshold",
                "desc": "Maximum value of the Analog Signal Input that is to be considered a Logic Low (0)",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            }
        ],
        "variableBindings": {
            "AnalogSignalInput": {
                "bindingType": "None",
                "value": ""
            },
            "LogicHighThreshold": {
                "bindingType": "None",
                "value": ""
            },
            "LogicLowThreshold": {
                "bindingType": "None",
                "value": ""
            }
        }
    },
    "BinaryBitwiseOperation": {
        "internalName": "BinaryBitwiseOperation",
        "name": "Binary Bitwise Operation",
        "desc": "Set the Tag's value to the result of the defined operation on the given operands. The operands are converted to integers before executing the operation. The result of the operation is converted from integer to float when setting the Tag's value.",
        "category": "Bitwise Logic",
        "version": "1.0.1",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 25,
        "variableDefs": [
            {
                "name": "Operation",
                "displayName": "Operation",
                "desc": "Operation to perform on the given operands",
                "ioType": "input",
                "dataType": "String",
                "enumEntries": [
                    "AND",
                    "NAND",
                    "OR",
                    "XOR",
                    "NOR",
                    "XNOR",
                    "LSHIFT",
                    "RSHIFT"
                ],
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Operand1",
                "displayName": "Operand 1",
                "desc": "First operand",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Operand2",
                "displayName": "Operand 2",
                "desc": "Second operand",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            }
        ],
        "variableBindings": {
            "Operation": {
                "bindingType": "String",
                "value": "AND"
            },
            "Operand1": {
                "bindingType": "None",
                "value": ""
            },
            "Operand2": {
                "bindingType": "None",
                "value": ""
            }
        }
    },
    "BinaryConditionalOperation": {
        "internalName": "BinaryConditionalOperation",
        "name": "Binary Conditional Operation",
        "desc": "Set the Tag's value to the result of the defined operation on the given operands. Note that this will result in a value of either 0 (false) or 1 (true). To determine the Boolean value of an operand for evaluation, if the operand is 0 then it is considered false, and if the operand is non-zero it is considered true.",
        "category": "Conditional Logic",
        "version": "1.0.1",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 25,
        "variableDefs": [
            {
                "name": "Operation",
                "displayName": "Operation",
                "desc": "Operation to perform on the given operands",
                "ioType": "input",
                "dataType": "String",
                "enumEntries": [
                    "AND",
                    "OR",
                    "XOR",
                    "NAND",
                    "NOR",
                    "XNOR"
                ],
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Operand1",
                "displayName": "Operand 1",
                "desc": "First operand",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Operand2",
                "displayName": "Operand 2",
                "desc": "Second operand",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            }
        ],
        "variableBindings": {
            "Operation": {
                "bindingType": "String",
                "value": "AND"
            },
            "Operand1": {
                "bindingType": "None",
                "value": ""
            },
            "Operand2": {
                "bindingType": "None",
                "value": ""
            }
        }
    },
    "BinaryMathOperation": {
        "internalName": "BinaryMathOperation",
        "name": "Binary Math Operation",
        "desc": "Set the Tag's value to the result of the selected operation on the given operands.",
        "category": "Math",
        "version": "1.1.1",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 25,
        "variableDefs": [
            {
                "name": "Operation",
                "displayName": "Operation",
                "desc": "Operation to perform on the given operands",
                "ioType": "input",
                "dataType": "String",
                "enumEntries": [
                    "Add [=A+B]",
                    "Subtract [=A-B]",
                    "Subtract Absolute [=abs(A-B)]",
                    "Multiply [=A*B]",
                    "Divide [=A/B]",
                    "Percentage [=(A/B)*100]",
                    "Modulo [=A%B]",
                    "Exponent [=A^B]"
                ],
                "group": "Inputs",
                "required": true
            },
            {
                "name": "OperandA",
                "displayName": "Operand A",
                "desc": "First operand",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "OperandB",
                "displayName": "Operand B",
                "desc": "Second operand",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            }
        ],
        "variableBindings": {
            "Operation": {
                "bindingType": "String",
                "value": "Add [=A+B]"
            },
            "OperandA": {
                "bindingType": "TagName",
                "value": ""
            },
            "OperandB": {
                "bindingType": "TagName",
                "value": ""
            }
        }
    },
    "CenturionRunStatus": {
        "internalName": "CenturionRunStatus",
        "name": "Centurion Run Status",
        "desc": "Calculates a Run Status value by looking at the values of several state registers in the Centurion.",
        "category": "Compressor Analytics",
        "version": "2.0.2",
        "maxScanErrorThreshold": "2",
        "requiredAccessLevel": 25,
        "variableDefs": [
            {
                "name": "FaultTag",
                "displayName": "Fault Code Tag",
                "desc": "Centurion Fault Modbus Register that stores cause of shutdown (1-128)",
                "dataType": "TagDef",
                "required": true
            },
            {
                "name": "StateTag",
                "displayName": "State Code Tag",
                "desc": "Centurion State Modbus Register that stores operating state of the controller",
                "dataType": "TagDef",
                "required": true
            },
            {
                "name": "TestTimerTag",
                "displayName": "Test Timer Tag",
                "desc": "Centurion Modbus Register that stores Test Timer accumulator value",
                "dataType": "TagDef"
            },
            {
                "name": "PanelType",
                "displayName": "Panel Type",
                "desc": "Centurion Controller Type - one of C3, C4, C5, C+, C5/ASC",
                "dataType": "String",
                "enumEntries": [
                    "C3",
                    "C4",
                    "C5",
                    "C+",
                    "C5/ASC"
                ],
                "required": true
            },
            {
                "name": "ManualStopCode",
                "displayName": "Manual Stop Code",
                "desc": "Numeric code to return when the panel has been Manually Stopped",
                "dataType": "Number"
            },
            {
                "name": "PanelReadyCode",
                "displayName": "Panel Ready Code",
                "desc": "Numeric code to return when the panel is in Panel Ready but it is not known how it got there (Manual Stop, cleared fault, restart)",
                "dataType": "Number"
            }
        ],
        "variableBindings": {
            "FaultTag": {
                "bindingType": "TagName",
                "value": "FAULT CODE"
            },
            "StateTag": {
                "bindingType": "TagName",
                "value": "STATE CODE"
            },
            "TestTimerTag": {
                "bindingType": "TagName",
                "value": "TEST TIMER"
            },
            "PanelType": {
                "bindingType": "String",
                "value": "C4"
            },
            "ManualStopCode": {
                "bindingType": "Number",
                "value": "999"
            },
            "PanelReadyCode": {
                "bindingType": "Number",
                "value": "998"
            }
        }
    },
    "ComparisonOperation": {
        "internalName": "ComparisonOperation",
        "name": "Comparison Operation",
        "desc": "Set the Tag's value to the result of the selected comparison operation on the given operands. The Tag's value is set to 0 if the condition is False and 1 if True.",
        "category": "Comparative Logic",
        "version": "1.0.1",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 25,
        "variableDefs": [
            {
                "name": "Operation",
                "displayName": "Operation",
                "desc": "Operation to perform on the given operands",
                "ioType": "input",
                "dataType": "String",
                "enumEntries": [
                    "=",
                    "<>",
                    ">=",
                    ">",
                    "<=",
                    "<"
                ],
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Operand1",
                "displayName": "Operand 1",
                "desc": "First operand",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Operand2",
                "displayName": "Operand 2",
                "desc": "Second operand",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            }
        ],
        "variableBindings": {
            "Operation": {
                "bindingType": "String",
                "value": "="
            },
            "Operand1": {
                "bindingType": "None",
                "value": ""
            },
            "Operand2": {
                "bindingType": "None",
                "value": ""
            }
        }
    },
    "Control Selector": {
        "internalName": "Control Selector",
        "name": "Control Selector",
        "desc": "Set the Tag's value to the result of the given aggregation function on the given inputs. Supports disabling given inputs. If you need to perform Input Selection on more than the provided number of inputs, you can cascade multiple Input Selectors.",
        "category": "Selectors",
        "version": "1.0.1",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 75,
        "variableDefs": [
            {
                "name": "Operation",
                "displayName": "Operation",
                "desc": "Operation to perform on the given operands",
                "ioType": "input",
                "dataType": "String",
                "enumEntries": [
                    "MIN",
                    "MAX"
                ],
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Input1",
                "displayName": "Input 1",
                "desc": "First input",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Input2",
                "displayName": "Input 2",
                "desc": "Second input",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": false
            },
            {
                "name": "Input3",
                "displayName": "Input 3",
                "desc": "Third input",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": false
            },
            {
                "name": "Input4",
                "displayName": "Input 4",
                "desc": "Fourth input",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": false
            },
            {
                "name": "Input5",
                "displayName": "Input 5",
                "desc": "Fifth input",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": false
            },
            {
                "name": "Input6",
                "displayName": "Input 6",
                "desc": "Sixth input",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": false
            },
            {
                "name": "Input7",
                "displayName": "Input 7",
                "desc": "Seventh input",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": false
            },
            {
                "name": "Input8",
                "displayName": "Input 8",
                "desc": "Eighth input",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": false
            },
            {
                "name": "Input9",
                "displayName": "Input 9",
                "desc": "Ninth input",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": false
            },
            {
                "name": "Input10",
                "displayName": "Input 10",
                "desc": "Tenth input",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": false
            },
            {
                "name": "EnableInput1",
                "displayName": "Enable Input 1",
                "desc": "Select DISABLE to exclude Input 1 from the selection process",
                "ioType": "input",
                "dataType": "String",
                "enumEntries": [
                    "DISABLED",
                    "ENABLED"
                ],
                "group": "Inputs",
                "required": false
            },
            {
                "name": "EnableInput2",
                "displayName": "Enable Input 2",
                "desc": "Select DISABLE to exclude Input 2 from the selection process",
                "ioType": "input",
                "dataType": "String",
                "enumEntries": [
                    "DISABLED",
                    "ENABLED"
                ],
                "group": "Inputs",
                "required": false
            },
            {
                "name": "EnableInput3",
                "displayName": "Enable Input 3",
                "desc": "Select DISABLE to exclude Input 3 from the selection process",
                "ioType": "input",
                "dataType": "String",
                "enumEntries": [
                    "DISABLED",
                    "ENABLED"
                ],
                "group": "Inputs",
                "required": false
            },
            {
                "name": "EnableInput4",
                "displayName": "Enable Input 4",
                "desc": "Select DISABLE to exclude Input 4 from the selection process",
                "ioType": "input",
                "dataType": "String",
                "enumEntries": [
                    "DISABLED",
                    "ENABLED"
                ],
                "group": "Inputs",
                "required": false
            },
            {
                "name": "EnableInput5",
                "displayName": "Enable Input 5",
                "desc": "Select DISABLE to exclude Input 5 from the selection process",
                "ioType": "input",
                "dataType": "String",
                "enumEntries": [
                    "DISABLED",
                    "ENABLED"
                ],
                "group": "Inputs",
                "required": false
            },
            {
                "name": "EnableInput6",
                "displayName": "Enable Input 6",
                "desc": "Select DISABLE to exclude Input 6 from the selection process",
                "ioType": "input",
                "dataType": "String",
                "enumEntries": [
                    "DISABLED",
                    "ENABLED"
                ],
                "group": "Inputs",
                "required": false
            },
            {
                "name": "EnableInput7",
                "displayName": "Enable Input 7",
                "desc": "Select DISABLE to exclude Input 7 from the selection process",
                "ioType": "input",
                "dataType": "String",
                "enumEntries": [
                    "DISABLED",
                    "ENABLED"
                ],
                "group": "Inputs",
                "required": false
            },
            {
                "name": "EnableInput8",
                "displayName": "Enable Input 8",
                "desc": "Select DISABLE to exclude Input 8 from the selection process",
                "ioType": "input",
                "dataType": "String",
                "enumEntries": [
                    "DISABLED",
                    "ENABLED"
                ],
                "group": "Inputs",
                "required": false
            },
            {
                "name": "EnableInput9",
                "displayName": "Enable Input 9",
                "desc": "Select DISABLE to exclude Input 9 from the selection process",
                "ioType": "input",
                "dataType": "String",
                "enumEntries": [
                    "DISABLED",
                    "ENABLED"
                ],
                "group": "Inputs",
                "required": false
            },
            {
                "name": "EnableInput10",
                "displayName": "Enable Input 10",
                "desc": "Select DISABLE to exclude Input 10 from the selection process",
                "ioType": "input",
                "dataType": "String",
                "enumEntries": [
                    "DISABLED",
                    "ENABLED"
                ],
                "group": "Inputs",
                "required": false
            }
        ],
        "variableBindings": {
            "Operation": {
                "bindingType": "String",
                "value": "MIN"
            },
            "Input1": {
                "bindingType": "None",
                "value": ""
            },
            "Input2": {
                "bindingType": "None",
                "value": ""
            },
            "Input3": {
                "bindingType": "None",
                "value": ""
            },
            "Input4": {
                "bindingType": "None",
                "value": ""
            },
            "Input5": {
                "bindingType": "None",
                "value": ""
            },
            "Input6": {
                "bindingType": "None",
                "value": ""
            },
            "Input7": {
                "bindingType": "None",
                "value": ""
            },
            "Input8": {
                "bindingType": "None",
                "value": ""
            },
            "Input9": {
                "bindingType": "None",
                "value": ""
            },
            "Input10": {
                "bindingType": "None",
                "value": ""
            },
            "EnableInput1": {
                "bindingType": "String",
                "value": "ENABLED"
            },
            "EnableInput2": {
                "bindingType": "String",
                "value": "ENABLED"
            },
            "EnableInput3": {
                "bindingType": "String",
                "value": "ENABLED"
            },
            "EnableInput4": {
                "bindingType": "String",
                "value": "ENABLED"
            },
            "EnableInput5": {
                "bindingType": "String",
                "value": "ENABLED"
            },
            "EnableInput6": {
                "bindingType": "String",
                "value": "ENABLED"
            },
            "EnableInput7": {
                "bindingType": "String",
                "value": "ENABLED"
            },
            "EnableInput8": {
                "bindingType": "String",
                "value": "ENABLED"
            },
            "EnableInput9": {
                "bindingType": "String",
                "value": "ENABLED"
            },
            "EnableInput10": {
                "bindingType": "String",
                "value": "ENABLED"
            }
        }
    },
    "CountDownCounter": {
        "internalName": "CountDownCounter",
        "name": "Count Down Counter",
        "desc": "Sets the Tag's value based on the result of the PLC Count Down (CTD) counter.",
        "category": "Counters",
        "version": "1.0.1",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 75,
        "variableDefs": [
            {
                "name": "Preset",
                "displayName": "Counter Preset",
                "desc": "The starting value for the count",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Trigger",
                "displayName": "Trigger",
                "desc": "Input signal that triggers the decremet of the counter on every rising edge",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Reset",
                "displayName": "Reset",
                "desc": "Input signal that resets the count to the Preset",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            }
        ],
        "variableBindings": {
            "Preset": {
                "bindingType": "None",
                "value": ""
            },
            "Trigger": {
                "bindingType": "None",
                "value": ""
            },
            "Reset": {
                "bindingType": "None",
                "value": ""
            }
        }
    },
    "CountUpCounter": {
        "internalName": "CountUpCounter",
        "name": "Count Up Counter",
        "desc": "Sets the Tag's value based on the result of the PLC Count Up (CTU) counter.",
        "category": "Counters",
        "version": "1.0.1",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 75,
        "variableDefs": [
            {
                "name": "Preset",
                "displayName": "Counter Preset",
                "desc": "The amount to count up to",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Trigger",
                "displayName": "Trigger",
                "desc": "Input signal that triggers the increment of the counter on every rising edge",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Reset",
                "displayName": "Reset",
                "desc": "Input signal that resets the count to zero",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            }
        ],
        "variableBindings": {
            "Preset": {
                "bindingType": "None",
                "value": ""
            },
            "Trigger": {
                "bindingType": "None",
                "value": ""
            },
            "Reset": {
                "bindingType": "None",
                "value": ""
            }
        }
    },
    "CountUpDownCounter": {
        "internalName": "CountUpDownCounter",
        "name": "Count Up/Down Counter",
        "desc": "Sets the Tag's value based on the result of the PLC Count Up/Down (CTUD) counter.",
        "category": "Counters",
        "version": "1.0.1",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 75,
        "variableDefs": [
            {
                "name": "Preset",
                "displayName": "Counter Preset",
                "desc": "The amount to count up to",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "CountUpTrigger",
                "displayName": "Trigger",
                "desc": "Input signal that triggers the increment of the counter on every rising edge",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "CountDownTrigger",
                "displayName": "Count Down Trigger",
                "desc": "Input signal that triggers the decrement of the counter on every rising edge",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "ResetToZero",
                "displayName": "Reset to Zero",
                "desc": "Input signal that resets the count to zero",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "ResetToPreset",
                "displayName": "Reset to Preset",
                "desc": "Input signal that resets the count to the Preset value",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            }
        ],
        "variableBindings": {
            "Preset": {
                "bindingType": "None",
                "value": ""
            },
            "CountUpTrigger": {
                "bindingType": "None",
                "value": ""
            },
            "CountDownTrigger": {
                "bindingType": "None",
                "value": ""
            },
            "ResetToZero": {
                "bindingType": "None",
                "value": ""
            },
            "ResetToPreset": {
                "bindingType": "None",
                "value": ""
            }
        }
    },
    "DE3000RunStatus": {
        "internalName": "DE3000RunStatus",
        "name": "DE-3000 Run Status",
        "desc": "Calculates a Run Status value by looking at the values of two state registers in the DE-3000 to determine if a shutdown is due to a Hi or Lo Fault.",
        "category": "Compressor Analytics",
        "version": "2.0.1",
        "maxScanErrorThreshold": "2",
        "requiredAccessLevel": 25,
        "variableDefs": [
            {
                "name": "StatusTag",
                "displayName": "Status Tag",
                "desc": "Tag for DE-3000 Status Modbus Register 40004",
                "dataType": "TagDef"
            },
            {
                "name": "FaultTag",
                "displayName": "Fault Tag",
                "desc": "DE-3000 Fault Modbus Register 40006",
                "dataType": "TagDef"
            },
            {
                "name": "ChannelFaultBase",
                "displayName": "Channel Fault Base",
                "desc": "Base value for mapping channel faults",
                "dataType": "Number"
            },
            {
                "name": "ControllerType",
                "displayName": "Controller Type",
                "desc": "Either DE-3000 or DE-4000",
                "dataType": "String",
                "enumEntries": [
                    "DE-3000",
                    "DE-4000"
                ]
            }
        ],
        "variableBindings": {
            "StatusTag": {
                "bindingType": "TagID",
                "value": ""
            },
            "FaultTag": {
                "bindingType": "TagID",
                "value": ""
            },
            "ChannelFaultBase": {
                "bindingType": "Number",
                "value": "0"
            },
            "ControllerType": {
                "bindingType": "String",
                "value": "DE-3000"
            }
        }
    },
    "DeviceStatusCodeMapper": {
        "internalName": "DeviceStatusCodeMapper",
        "name": "Device Status Code Mapper",
        "desc": "Maps the status codes of secondary devices into the status code range of the primary device to assemble a single combined status code for all devices. A corresponding Shutdown Legend must be created that maps the status codes into text descriptions for all devices.",
        "category": "Specialty",
        "version": "1.0.1",
        "maxScanErrorThreshold": "2",
        "requiredAccessLevel": 25,
        "variableDefs": [
            {
                "name": "PrimaryDeviceStatus",
                "displayName": "Primary Device Status",
                "desc": "Status indicator for Primary Device",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "SecondaryDevice1Status",
                "displayName": "Secondary Device 1 Status",
                "desc": "Status indicator for this Secondary Device",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "SecondaryDevice1StatusKey",
                "displayName": "Secondary Device 1 Status Key",
                "desc": "Value of the primary device status that indicates a fault in this secondary device",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "SecondaryDevice1StatusCodeOffset",
                "displayName": "Secondary Device 1 Status Code Offset",
                "desc": "Offset to apply to this secondary device status codes",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "SecondaryDevice2Status",
                "displayName": "Secondary Device 2 Status",
                "desc": "Status indicator for this Secondary Device",
                "dataType": "Number",
                "group": "Inputs",
                "required": false
            },
            {
                "name": "SecondaryDevice2StatusKey",
                "displayName": "Secondary Device 2 Status Key",
                "desc": "Value of the primary device status that indicates a fault in this secondary device",
                "dataType": "Number",
                "group": "Inputs",
                "required": false
            },
            {
                "name": "SecondaryDevice2StatusCodeOffset",
                "displayName": "Secondary Device 2 Status Code Offset",
                "desc": "Offset to apply to this secondary device status codes",
                "dataType": "Number",
                "group": "Inputs",
                "required": false
            },
            {
                "name": "SecondaryDevice3Status",
                "displayName": "Secondary Device 3 Status",
                "desc": "Status indicator for this Secondary Device",
                "dataType": "Number",
                "group": "Inputs",
                "required": false
            },
            {
                "name": "SecondaryDevice3StatusKey",
                "displayName": "Secondary Device 3 Status Key",
                "desc": "Value of the primary device status that indicates a fault in this secondary device",
                "dataType": "Number",
                "group": "Inputs",
                "required": false
            },
            {
                "name": "SecondaryDevice3StatusCodeOffset",
                "displayName": "Secondary Device 3 Status Code Offset",
                "desc": "Offset to apply to this secondary device status codes",
                "dataType": "Number",
                "group": "Inputs",
                "required": false
            },
            {
                "name": "SecondaryDevice4Status",
                "displayName": "Secondary Device 4 Status",
                "desc": "Status indicator for Secondary Device",
                "dataType": "Number",
                "group": "Inputs",
                "required": false
            },
            {
                "name": "SecondaryDevice4StatusKey",
                "displayName": "Secondary Device 4 Status Key",
                "desc": "Value of the primary device status that indicates a fault in this secondary device",
                "dataType": "Number",
                "group": "Inputs",
                "required": false
            },
            {
                "name": "SecondaryDevice4StatusCodeOffset",
                "displayName": "Secondary Device 4 Status Code Offset",
                "desc": "Offset to apply to this secondary device status codes",
                "dataType": "Number",
                "group": "Inputs",
                "required": false
            },
            {
                "name": "SecondaryDevice5Status",
                "displayName": "Secondary Device 5 Status",
                "desc": "Status indicator for Secondary Device",
                "dataType": "Number",
                "group": "Inputs",
                "required": false
            },
            {
                "name": "SecondaryDevice5StatusKey",
                "displayName": "Secondary Device 5 Status Key",
                "desc": "Value of the primary device status that indicates a fault in this secondary device",
                "dataType": "Number",
                "group": "Inputs",
                "required": false
            },
            {
                "name": "SecondaryDevice5StatusCodeOffset",
                "displayName": "Secondary Device 5 Status Code Offset",
                "desc": "Offset to apply to this secondary device status codes",
                "dataType": "Number",
                "group": "Inputs",
                "required": false
            }
        ],
        "variableBindings": {
            "PrimaryDeviceStatus": {
                "bindingType": "None"
            },
            "SecondaryDevice1Status": {
                "bindingType": "None"
            },
            "SecondaryDevice1StatusKey": {
                "bindingType": "None"
            },
            "SecondaryDevice1StatusCodeOffset": {
                "bindingType": "None"
            },
            "SecondaryDevice2Status": {
                "bindingType": "None"
            },
            "SecondaryDevice2StatusKey": {
                "bindingType": "None"
            },
            "SecondaryDevice2StatusCodeOffset": {
                "bindingType": "None"
            },
            "SecondaryDevice3Status": {
                "bindingType": "None"
            },
            "SecondaryDevice3StatusKey": {
                "bindingType": "None"
            },
            "SecondaryDevice3StatusCodeOffset": {
                "bindingType": "None"
            },
            "SecondaryDevice4Status": {
                "bindingType": "None"
            },
            "SecondaryDevice4StatusKey": {
                "bindingType": "None"
            },
            "SecondaryDevice4StatusCodeOffset": {
                "bindingType": "None"
            },
            "SecondaryDevice5Status": {
                "bindingType": "None"
            },
            "SecondaryDevice5StatusKey": {
                "bindingType": "None"
            },
            "SecondaryDevice5StatusCodeOffset": {
                "bindingType": "None"
            }
        }
    },
    "DeviceStatusTracker": {
        "internalName": "DeviceStatusTracker",
        "name": "Device Status Tracker",
        "desc": "Generate Alarm Events for warnings/shutdowns indicated by the given Status Codes, and set the Tag\u00e2\u20ac\u2122s value to the First Out code value.",
        "category": "Compression",
        "version": "1.0.0",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 25,
        "variableDefs": [
            {
                "name": "CodeType",
                "displayName": "Code Type",
                "desc": "Specifies whether the Status Code inputs store bitmapped codes or integer codes",
                "ioType": "input",
                "dataType": "String",
                "enumEntries": [
                    "INTEGER",
                    "BITMAPPED"
                ],
                "group": "Inputs",
                "required": true
            },
            {
                "name": "NumBitsPerStatusCode",
                "displayName": "Num Bits Per Status Code",
                "desc": "Specifies the number of bits to track for BITMAPPED Status Codes",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "StatusCodes",
                "displayName": "Status Codes",
                "desc": "Status Code for tracking Alarms",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true,
                "isArray": true,
                "minElements": 1
            }
        ],
        "variableBindings": {
            "StatusType": {
                "bindingType": "String",
                "value": "WARNING"
            },
            "CodeType": {
                "bindingType": "String",
                "value": "INTEGER"
            },
            "NumBitsPerStatusCode": {
                "bindingType": "Number",
                "value": "16"
            },
            "StatusCodes": [
                {
                    "bindingType": "None"
                }
            ]
        }
    },
    "Discretizer": {
        "internalName": "Discretizer",
        "name": "Discretizer",
        "desc": "Set the Tag's value to the bin index the given input value falls in. Bins are defined as monotonically increasing thresholds with the Edge inputs.",
        "category": "Comparative Logic",
        "version": "1.0.2",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 75,
        "variableDefs": [
            {
                "name": "InputValue",
                "displayName": "Input Value",
                "desc": "Value to map to a bin",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Edges",
                "displayName": "Edges",
                "desc": "Array of edges",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true,
                "isArray": true,
                "minElements": 2
            }
        ],
        "variableBindings": {
            "InputValue": {
                "bindingType": "None",
                "value": ""
            },
            "Edges": [
                {
                    "bindingType": "None",
                    "value": ""
                },
                {
                    "bindingType": "None",
                    "value": ""
                }
            ]
        }
    },
    "ExclusionZones": {
        "internalName": "ExclusionZones",
        "name": "Exclusion Zones",
        "desc": "Set the Tag's value to the given Target Value as long as it does not fall within any of the specified exclusion zones. If the Target Value falls within an exclusion zone the Tag's value is set to the closest endpoint in the range (Min or Max). Used primarily to avoid certain operating RPM zones when setting Engine Speed in an engine controller.",
        "category": "Control",
        "version": "1.0.1",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 75,
        "variableDefs": [
            {
                "name": "TargetValue",
                "displayName": "Target Value",
                "desc": "The target value for to apply the Exclusion Zones to",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Enabled",
                "displayName": "Enabled",
                "desc": "Set to zero to temporarily disable the exclusion zones when ramping up the value of an output for steady-state operation. Once the output has reached steady-state set this input to 1 to apply the exclusion zones",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": false
            },
            {
                "name": "Zone1Min",
                "displayName": "Zone 1 Min",
                "desc": "Minimum value for first exclusion zone",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Zone1Max",
                "displayName": "Zone 1 Max",
                "desc": "Maximum value for first exclusion zone",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Zone2Min",
                "displayName": "Zone 2 Min",
                "desc": "Minimum value for second exclusion zone",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": false
            },
            {
                "name": "Zone2Max",
                "displayName": "Zone 2 Max",
                "desc": "Maximum value for second exclusion zone",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": false
            },
            {
                "name": "Zone3Min",
                "displayName": "Zone 3 Min",
                "desc": "Minimum value for third exclusion zone",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": false
            },
            {
                "name": "Zone3Max",
                "displayName": "Zone 3 Max",
                "desc": "Maximum value for third exclusion zone",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": false
            }
        ],
        "variableBindings": {
            "TargetValue": {
                "bindingType": "None",
                "value": ""
            },
            "Enabled": {
                "bindingType": "Number",
                "value": "1"
            },
            "Zone1Min": {
                "bindingType": "None",
                "value": ""
            },
            "Zone1Max": {
                "bindingType": "None",
                "value": ""
            },
            "Zone2Min": {
                "bindingType": "None",
                "value": ""
            },
            "Zone2Max": {
                "bindingType": "None",
                "value": ""
            },
            "Zone3Min": {
                "bindingType": "None",
                "value": ""
            },
            "Zone3Max": {
                "bindingType": "None",
                "value": ""
            }
        }
    },
    "HistoricalLinearLeastSquaresFit": {
        "internalName": "HistoricalLinearLeastSquaresFit",
        "name": "Historical Linear Least Squares Fit",
        "desc": "Set the Tag\u00e2\u20ac\u2122s value to the rate calculated using a linear least squares fit for the data being tracked by the given RawData Tag input for the given time span.",
        "category": "Statistical Algorithms and Digital Signal Processing",
        "version": "1.0.0",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 25,
        "variableDefs": [
            {
                "name": "SourceDataTag",
                "displayName": "Source Data Tag",
                "desc": "Specifies the Tag for which to apply the linear fit",
                "ioType": "input",
                "dataType": "TagDef",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "TimeSpan",
                "displayName": "Time Span",
                "desc": "Specifies the time span, in minutes, for which to apply the linear fit to",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "GroupBySeconds",
                "displayName": "Group By Seconds",
                "desc": "Specifies the time period to group data by for the specified Aggregation Function",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "AggregationFunction",
                "displayName": "Aggregation Function",
                "desc": "Specifies the Aggregation Function to use",
                "ioType": "input",
                "dataType": "String",
                "enumEntries": [
                    "",
                    "LAST",
                    "AVERAGE",
                    "MIN",
                    "MAX"
                ],
                "group": "Inputs",
                "required": true
            },
            {
                "name": "InvalidDataThreshold",
                "displayName": "Invalid Data Threshold",
                "desc": "Specifies the maximum difference between one value and the next for it to be considered a valid data point",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "MaxNumInvalidDataPoints",
                "displayName": "Max Num Invalid Data Points",
                "desc": "Specifies the maximum number of invalid data points allowed before stopping the linear fit",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            }
        ],
        "variableBindings": {
            "SourceDataTag": {
                "bindingType": "None"
            },
            "TimeSpan": {
                "bindingType": "None"
            },
            "GroupBySeconds": {
                "bindingType": "Number",
                "value": "0"
            },
            "AggregationFunction": {
                "bindingType": "String",
                "value": ""
            },
            "InvalidDataThreshold": {
                "bindingType": "None"
            },
            "MaxNumInvalidDataPoints": {
                "bindingType": "None"
            }
        }
    },
    "HistoricalTotal": {
        "internalName": "HistoricalTotal",
        "name": "Historical Total",
        "desc": "Set the Tag\u00e2\u20ac\u2122s value to the total count/amount being tracked by the given Totalizer Tag input for the given time span.",
        "category": "Compression",
        "version": "1.0.0",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 25,
        "variableDefs": [
            {
                "name": "TotalizerTag",
                "displayName": "Totalizer Tag",
                "desc": "Specifies the Tag that maintains the total count/amount being tracked",
                "ioType": "input",
                "dataType": "TagDef",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Direction",
                "displayName": "Direction",
                "desc": "Specifies whether the given count/amount being tracked is expected to increase or decrease",
                "ioType": "input",
                "dataType": "String",
                "enumEntries": [
                    "INCREASING",
                    "DECREASING"
                ],
                "group": "Inputs",
                "required": true
            },
            {
                "name": "TimeSpan",
                "displayName": "Time Span",
                "desc": "Specifies the time span, in minutes, for which to calculate the total count/amount",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            }
        ],
        "variableBindings": {
            "TotalizerTag": {
                "bindingType": "None"
            },
            "Direction": {
                "bindingType": "String",
                "value": "INCREASING"
            },
            "TimeSpan": {
                "bindingType": "None"
            }
        }
    },
    "HydrostaticLiquidLevel": {
        "internalName": "HydrostaticLiquidLevel",
        "name": "Hydrostatic Liquid Level",
        "desc": "Convert the given level in units of Water Column to units of Medium Column using the given Specific Gravity. Emits the smoothed level using a moving average as a System Metric.",
        "category": "Specialty",
        "version": "1.0.2",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 25,
        "variableDefs": [
            {
                "name": "LevelInUnitsOfWaterColumn",
                "displayName": "Level In Units Of Water Column",
                "desc": "Liquid Level In units of water column to be converted to units of the given medium via application of the Specific Gravity",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "SpecificGravity",
                "displayName": "Specific Gravity",
                "desc": "Specific Gravity of the medium",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "SmoothingWindowSize",
                "displayName": "Smoothing Window Size",
                "desc": "Size of the moving window for smoothing",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": false
            },
            {
                "name": "FieldCalibrationOffset",
                "displayName": "Field Calibration Offset",
                "desc": "Offset established by performing a field calibration",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": false
            }
        ],
        "variableBindings": {
            "LevelInUnitsOfWaterColumn": {
                "bindingType": "None",
                "value": ""
            },
            "SpecificGravity": {
                "bindingType": "None",
                "value": ""
            },
            "SmoothingWindowSize": {
                "bindingType": "Number",
                "value": "10"
            },
            "FieldCalibrationOffset": {
                "bindingType": "Number",
                "value": "0"
            }
        }
    },
    "LatchUnlatchOutput": {
        "internalName": "LatchUnlatchOutput",
        "name": "Latch/Unlatch Output",
        "desc": "Latch the Tag's value to 1 when the given Latching Input's value goes from 0 to 1. Because it is latched, the Tag's value will stay at 1 even if the Latching Input goes to 0. The Tag can only be unlatched if the Unlatching Input goes from 0 to 1. Typically used to set a Digital Output state based on a Digital Input state. This is analogous to how PLC OTL/OTU instructions are used in tandem.",
        "category": "Control",
        "version": "1.0.1",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 75,
        "variableDefs": [
            {
                "name": "LatchingInput",
                "displayName": "Latching Input",
                "desc": "Input that latches this Tag's value to 1 on a rising edge.",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "UnlatchingInput",
                "displayName": "Unlatching Input",
                "desc": "Input that unlatches this Tag's value to 0 on a rising edge.",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            }
        ],
        "variableBindings": {
            "LatchingInput": {
                "bindingType": "None",
                "value": ""
            },
            "UnlatchingInput": {
                "bindingType": "None",
                "value": ""
            }
        }
    },
    "Limit": {
        "internalName": "Limit",
        "name": "Limit",
        "desc": "Set the Tag's value to the result MIN (MAX (Input 1, Min value), Max Value).",
        "category": "Selectors",
        "version": "1.0.1",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 25,
        "variableDefs": [
            {
                "name": "Input",
                "displayName": "Input",
                "desc": "Value to clamp to the given range",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "MinValue",
                "displayName": "Min Value",
                "desc": "Minimum value of the range",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "MaxValue",
                "displayName": "Max Value",
                "desc": "Maximum value of the range",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            }
        ],
        "variableBindings": {
            "Input": {
                "bindingType": "None",
                "value": ""
            },
            "MinValue": {
                "bindingType": "None",
                "value": ""
            },
            "MaxValue": {
                "bindingType": "None",
                "value": ""
            }
        }
    },
    "LinearRampController": {
        "internalName": "LinearRampController",
        "name": "Linear Ramp Controller",
        "desc": "Scales the given input value linearly given min and max values that define the line.",
        "category": "Controllers",
        "version": "1.0.1",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 75,
        "variableDefs": [
            {
                "name": "InputMin",
                "displayName": "Input Min",
                "desc": "Minimum input value (X Axis min)",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "InputMax",
                "displayName": "Input Max",
                "desc": "Maximum input value (X Axis max)",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "InputCurrentValue",
                "displayName": "Input Current Value",
                "desc": "Current input value (X Axis value) to transform linearly",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "OutputMin",
                "displayName": "Output Min",
                "desc": "Minimum output value (Y Axis min)",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "OutputMax",
                "displayName": "Output Max",
                "desc": "Maximum output value (Y Axis max)",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "OutputDeadband",
                "displayName": "Output Deadband",
                "desc": "Deadband to apply to output to reduce wear and tear on equipment",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Permissive",
                "displayName": "Permissive",
                "desc": "0=Disabled 1=Enabled",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Mode",
                "displayName": "Mode",
                "desc": "Either MANUAL (0) or AUTO (1)",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            }
        ],
        "variableBindings": {
            "InputMin": {
                "bindingType": "Number",
                "value": ""
            },
            "InputMax": {
                "bindingType": "Number",
                "value": ""
            },
            "InputCurrentValue": {
                "bindingType": "Number",
                "value": ""
            },
            "OutputMin": {
                "bindingType": "Number",
                "value": ""
            },
            "OutputMax": {
                "bindingType": "Number",
                "value": ""
            },
            "OutputDeadband": {
                "bindingType": "Number",
                "value": "0.0"
            },
            "Permissive": {
                "bindingType": "Number",
                "value": "1.0"
            },
            "Mode": {
                "bindingType": "Number",
                "value": "1"
            }
        }
    },
    "LinearScaler": {
        "internalName": "LinearScaler",
        "name": "Linear Scaler",
        "desc": "Scales the given input value linearly given a Gain and Offset.",
        "category": "Math",
        "version": "1.0.1",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 25,
        "variableDefs": [
            {
                "name": "InputValue",
                "displayName": "Input Value",
                "desc": "Value to scale",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Gain",
                "displayName": "Gain",
                "desc": "Multiplicative factor",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Offset",
                "displayName": "Offset",
                "desc": "Additive factor",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            }
        ],
        "variableBindings": {
            "InputValue": {
                "bindingType": "None"
            },
            "Gain": {
                "bindingType": "Number",
                "value": "1.0"
            },
            "Offset": {
                "bindingType": "Number",
                "value": "0.0"
            }
        }
    },
    "Max Allowable Engine Load": {
        "internalName": "Max Allowable Engine Load",
        "name": "Max Allowable Engine Load",
        "desc": "Determines Max Allowable Engine Load to be used by Dynamic Control. Outputs the Max Allowable Engine Load as a percentage.",
        "category": "Engine Analytics",
        "version": "1.0.0",
        "maxScanErrorThreshold": "1",
        "requiredAccessLevel": 75,
        "variableDefs": [
            {
                "name": "MaxDesiredLoad",
                "displayName": "Max Desired Load (%)",
                "desc": "Customer-entered max permissible engine load or [Max Derated HP] from Enalysis",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true,
                "isArray": false
            },
            {
                "name": "MaxRatedLoad",
                "displayName": "Max Rated Load (%)",
                "desc": "From engine manufacturer spec sheet; Defaults to 100%",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": false,
                "isArray": false
            },
            {
                "name": "LimpHome",
                "displayName": "Limp Home (%)",
                "desc": "Calculated by ESM2; If not available default to 100%",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": false,
                "isArray": false
            }
        ],
        "variableBindings": {
            "MaxDesiredLoad": {
                "bindingType": "Number",
                "value": ""
            },
            "MaxRatedLoad": {
                "bindingType": "Number",
                "value": "100"
            },
            "LimpHome": {
                "bindingType": "Number",
                "value": "100"
            }
        }
    },
    "MaxDifferential": {
        "internalName": "MaxDifferential",
        "name": "Max Differential",
        "desc": "Computes the absolute difference between the minimum and maximum input.",
        "category": "Math",
        "version": "1.0.2",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 25,
        "variableDefs": [
            {
                "name": "Inputs",
                "displayName": "Inputs",
                "desc": "Array of inputs",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true,
                "isArray": true,
                "minElements": 2
            }
        ],
        "variableBindings": {
            "Inputs": [
                {
                    "bindingType": "None",
                    "value": ""
                },
                {
                    "bindingType": "None",
                    "value": ""
                }
            ]
        }
    },
    "Moving Stats": {
        "internalName": "Moving Stats",
        "name": "Moving Stats",
        "desc": "Set the Tag's value to the result of the defined aggregation operation on a sliding window of data values for the given input.",
        "category": "Statistics and Signal Processing",
        "version": "1.0.2",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 75,
        "variableDefs": [
            {
                "name": "InputSignal",
                "displayName": "Input Signal",
                "desc": "Input to which to perform the given aggregation function",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "WindowSize",
                "displayName": "Window Size",
                "desc": "The number of data points to maintain in the data window",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Operation",
                "displayName": "Operation",
                "desc": "Statistic to call to compute Tag value",
                "ioType": "input",
                "dataType": "String",
                "enumEntries": [
                    "AVERAGE",
                    "VARIANCE",
                    "STANDARD DEVIATION",
                    "MINIMUM",
                    "MAXIMUM",
                    "RANGE"
                ],
                "group": "Inputs",
                "required": true
            }
        ],
        "variableBindings": {
            "InputSignal": {
                "bindingType": "Number",
                "value": "None"
            },
            "WindowSize": {
                "bindingType": "Number",
                "value": "60"
            },
            "Operation": {
                "bindingType": "String",
                "value": "AVERAGE"
            }
        }
    },
    "Multiplexer": {
        "internalName": "Multiplexer",
        "name": "Multiplexer",
        "desc": "Set the Tag's value to one of the given inputs based on a selecting input.",
        "category": "Selector",
        "version": "1.0.2",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 75,
        "variableDefs": [
            {
                "name": "SelectorInput",
                "displayName": "Selector Input",
                "desc": "Selects which signal to output (1-10)",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Inputs",
                "displayName": "Inputs",
                "desc": "Array of inputs",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true,
                "isArray": true,
                "minElements": 1
            }
        ],
        "variableBindings": {
            "SelectorInput": {
                "bindingType": "None",
                "value": ""
            },
            "Inputs": [
                {
                    "bindingType": "None",
                    "value": ""
                }
            ]
        }
    },
    "NearlyEqual": {
        "internalName": "NearlyEqual",
        "name": "Nearly Equal",
        "desc": "Set the Tag's value to 1 if the absolute difference between the given operands is within the given error threshold.",
        "category": "Comparative Logic",
        "version": "1.0.1",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 75,
        "variableDefs": [
            {
                "name": "Operand1",
                "displayName": "Operand 1",
                "desc": "First operand to compare",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Operand2",
                "displayName": "Operand 2",
                "desc": "Second operand to compare",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "ErrorThreshold",
                "displayName": "Error Threshold",
                "desc": "Error threshold within which the given operands are considered to be equal",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            }
        ],
        "variableBindings": {
            "Operand1": {
                "bindingType": "None",
                "value": ""
            },
            "Operand2": {
                "bindingType": "None",
                "value": ""
            },
            "ErrorThreshold": {
                "bindingType": "None",
                "value": ""
            }
        }
    },
    "OffDelayTimer": {
        "internalName": "OffDelayTimer",
        "name": "Off Delay Timer",
        "desc": "Sets the Tag's value based on the Done state of a Timer that Implements the PLC Off Delay Timer (TOF).",
        "category": "Timers",
        "version": "1.0.1",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 75,
        "variableDefs": [
            {
                "name": "Preset",
                "displayName": "Timer Preset",
                "desc": "Duration of Timer in seconds",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Trigger",
                "displayName": "Trigger",
                "desc": "Input signal that controls the starting and stopping of the timer",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            }
        ],
        "variableBindings": {
            "Preset": {
                "bindingType": "None",
                "value": ""
            },
            "Trigger": {
                "bindingType": "None",
                "value": ""
            }
        }
    },
    "OnDelayTimer": {
        "internalName": "OnDelayTimer",
        "name": "On Delay Timer",
        "desc": "Sets the Tag's value based on the Done state of a Timer that Implements the PLC On Delay Timer (TON).",
        "category": "Timers",
        "version": "1.0.1",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 75,
        "variableDefs": [
            {
                "name": "Preset",
                "displayName": "Timer Preset",
                "desc": "Duration of Timer in seconds",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Trigger",
                "displayName": "Trigger",
                "desc": "Input signal that controls the starting and stopping of the timer",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            }
        ],
        "variableBindings": {
            "Preset": {
                "bindingType": "None",
                "value": ""
            },
            "Trigger": {
                "bindingType": "None",
                "value": ""
            }
        }
    },
    "OperationOnArray": {
        "internalName": "OperationOnArray",
        "name": "Operation On Array",
        "desc": "Performs a selected operation on an array of inputs.",
        "category": "Math",
        "version": "1.0.0",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 25,
        "variableDefs": [
            {
                "name": "Inputs",
                "displayName": "Inputs",
                "desc": "Array of inputs",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true,
                "isArray": true,
                "minElements": 1
            },
            {
                "name": "Operation",
                "displayName": "Operation",
                "desc": "Operation to perform",
                "ioType": "input",
                "dataType": "String",
                "enumEntries": [
                    "MAX",
                    "MIN",
                    "SUM"
                ],
                "group": "Inputs",
                "required": true
            }
        ],
        "variableBindings": {
            "Inputs": [
                {
                    "bindingType": "Number",
                    "value": "0"
                }
            ],
            "Operation": {
                "bindingType": "String",
                "value": "SUM"
            }
        }
    },
    "PID": {
        "internalName": "PID",
        "name": "PID Controller",
        "desc": "Sets the Tag's value to the Controlled Variable output calculated by a PID Control Loop using the independent equation. The output is in engineering units based on the Min/Max defined for the Tag. For proper operation make sure the PID's Loop Update Time is longer than the Tag's Scan Interval.",
        "category": "Controllers",
        "version": "2.0.3",
        "maxScanErrorThreshold": "10",
        "requiredAccessLevel": 75,
        "variableDefs": [
            {
                "name": "PVTag",
                "displayName": "PV Tag",
                "desc": "Process Variable (PV) Tag",
                "ioType": "input",
                "dataType": "TagDef",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "SP",
                "displayName": "SP",
                "desc": "Setpoint (SP) value - the target value the PID Loop is trying to move PV to in engineering units",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "COBias",
                "displayName": "Controller Bias",
                "desc": "Initial output value for the PID calculation in same units as the Controlled Variable",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Deadband",
                "displayName": "Deadband",
                "desc": "Deadband to apply to error to reduce wear and tear on controlled equipment in same units as PVTag and SP",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Kp",
                "displayName": "Kp",
                "desc": "Proportional coefficient",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Ki",
                "displayName": "Ki",
                "desc": "Integral coefficient",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Kd",
                "displayName": "Kd",
                "desc": "Derivative coefficient",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Mode",
                "displayName": "Mode",
                "desc": "Either MANUAL (0) or AUTO (1)",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Direction",
                "displayName": "Direction",
                "desc": "Either FORWARD (Error=PV-SP) or REVERSE (Error=SP-PV)",
                "ioType": "input",
                "dataType": "String",
                "enumEntries": [
                    "FORWARD",
                    "REVERSE"
                ],
                "group": "Inputs",
                "required": true
            },
            {
                "name": "DerivativeAction",
                "displayName": "Derivative Action",
                "desc": "Determines if derivative term is computed using PV or ERROR",
                "ioType": "input",
                "dataType": "String",
                "enumEntries": [
                    "PV",
                    "ERROR"
                ],
                "group": "Inputs",
                "required": true
            },
            {
                "name": "UpdateTime",
                "displayName": "Update Time",
                "desc": "Loop update time in seconds",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "ManualOutputValue",
                "displayName": "Manual Output Value",
                "desc": "Value in engineering units to set output to when the Mode is set to MANUAL",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Permissive",
                "displayName": "Permissive",
                "desc": "0=Disabled, 1=Enabled. Determines when the PID should be executed.",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "COLowLimit",
                "displayName": "CV Low Limit",
                "desc": "Lower limit for the Controlled Variable, in engineering units",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": false
            },
            {
                "name": "COHighLimit",
                "displayName": "CV High Limit",
                "desc": "Upper limit for the Controlled Variable, in engineering units",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": false
            },
            {
                "name": "BumplessTransfer",
                "displayName": "Bumpless Transfer",
                "desc": "If enabled, sets the Controller Bias to the Manual Output when switching from Manual Mode to Auto Mode",
                "ioType": "input",
                "dataType": "String",
                "enumEntries": [
                    "DISABLED",
                    "ENABLED"
                ],
                "group": "Inputs",
                "required": false
            }
        ],
        "variableBindings": {
            "PVTag": {
                "bindingType": "TagID",
                "value": ""
            },
            "SP": {
                "bindingType": "Number",
                "value": "30"
            },
            "COBias": {
                "bindingType": "Number",
                "value": "0"
            },
            "Deadband": {
                "bindingType": "Number",
                "value": "0"
            },
            "Kp": {
                "bindingType": "Number",
                "value": "1.0"
            },
            "Ki": {
                "bindingType": "Number",
                "value": "0"
            },
            "Kd": {
                "bindingType": "Number",
                "value": "0"
            },
            "Mode": {
                "bindingType": "Number",
                "value": "0"
            },
            "Direction": {
                "bindingType": "String",
                "value": "FORWARD"
            },
            "DerivativeAction": {
                "bindingType": "String",
                "value": "PV"
            },
            "UpdateTime": {
                "bindingType": "Number",
                "value": "1"
            },
            "ManualOutputValue": {
                "bindingType": "TagID",
                "value": ""
            },
            "Permissive": {
                "bindingType": "Number",
                "value": "1"
            },
            "COLowLimit": {
                "bindingType": "None",
                "value": ""
            },
            "COHighLimit": {
                "bindingType": "None",
                "value": ""
            },
            "BumplessTransfer": {
                "bindingType": "String",
                "value": "DISABLED"
            }
        }
    },
    "PulseRate": {
        "internalName": "PulseRate",
        "name": "Pulse Rate",
        "desc": "Set the Tag's value to the average number of pulses (rising edges) detected in the given input signal in the given amount of time. Uses a sliding window to maintain an average count.",
        "category": "Statistics and Signal Processing",
        "version": "1.0.1",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 75,
        "variableDefs": [
            {
                "name": "PulseInput",
                "displayName": "Pulse Input",
                "desc": "Input that contains pulse signal",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "PulseSamplingWindowSize",
                "displayName": "Pulse Sampling Window Size",
                "desc": "Size of sliding data window, in seconds, within which to count pulses",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "NumPulseSamplingWindows",
                "displayName": "Num Pulse Sampling Windows",
                "desc": "Number of sampling windows to use for averaging the pulse counts",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "NormalizationPeriod",
                "displayName": "Normalization Period",
                "desc": "Period, in seconds, to which to normalize the pulse counts",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            }
        ],
        "variableBindings": {
            "PulseInput": {
                "bindingType": "None"
            },
            "PulseSamplingWindowSize": {
                "bindingType": "Number",
                "value": "10"
            },
            "NumPulseSamplingWindows": {
                "bindingType": "Number",
                "value": "6"
            },
            "NormalizationPeriod": {
                "bindingType": "Number",
                "value": "60"
            }
        }
    },
    "PumpStrokeCounter": {
        "internalName": "PumpStrokeCounter",
        "name": "Pump Stroke Counter",
        "desc": "Set the Tag\u00e2\u20ac\u2122s value to the running total of strokes detected in the given input signal generated by a positive displacement pump's stroking action or by a proximity sensor.",
        "category": "Statistics and Signal Processing",
        "version": "1.1.0",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 25,
        "variableDefs": [
            {
                "name": "InputSignal",
                "displayName": "Input Signal",
                "desc": "Input signal from a hydrostatic pressure sensor or proximity sensor",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "DigitizingThreshold",
                "displayName": "Digitizing Threshold",
                "desc": "Digitization threshold to use for detecting pump strokes",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "InputSignalMovingAverageWindowSize",
                "displayName": "Input Signal Moving Average Window Size",
                "desc": "Size of sliding data window, in seconds, for maintaining moving average of input signal that forms the baseline for the digitization",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            }
        ],
        "variableBindings": {
            "InputSignal": {
                "bindingType": "None"
            },
            "DigitizingThreshold": {
                "bindingType": "None"
            },
            "InputSignalMovingAverageWindowSize": {
                "bindingType": "Number",
                "value": "10"
            }
        }
    },
    "PumpStrokeCounterCalibrator": {
        "internalName": "PumpStrokeCounterCalibrator",
        "name": "Pump Stroke Counter Calibrator",
        "desc": "Set the Tag\u00e2\u20ac\u2122s value to the threshold determined by examining the dips in the given input signal generated by a positive displacement pump's stroking action.",
        "category": "Statistics and Signal Processing",
        "version": "1.0.0",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 25,
        "variableDefs": [
            {
                "name": "InputSignal",
                "displayName": "Input Signal",
                "desc": "Input signal from a hydrostatic pressure sensor or proximity sensor",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Mode",
                "displayName": "Mode",
                "desc": "Mode - either 0=RUN or 1=CALIBRATE",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "CalibrationDuration",
                "displayName": "Calibration Duration",
                "desc": "Duration of pump stroke counting calibration process",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "CalibrationTimeSlice",
                "displayName": "Calibration Time Slice",
                "desc": "Time slice to use for pump stroke counting calibration",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "CalibrationOffset",
                "displayName": "Calibration Offset",
                "desc": "Offset to subract from calibrated ditigization threshold",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "ThresholdManualOverride",
                "displayName": "Threshold Manual Override",
                "desc": "Selects between using calibrated value or manually entered value for digitization threshold",
                "ioType": "input",
                "dataType": "String",
                "enumEntries": [
                    "DISABLED",
                    "ENABLED"
                ],
                "group": "Inputs",
                "required": true
            },
            {
                "name": "ManualDigitizingThreshold",
                "displayName": "Manual Threshold",
                "desc": "Digitization threshold to use when Manual Override is engaged",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "InputSignalMovingAverageWindowSize",
                "displayName": "Input Signal Moving Average Window Size",
                "desc": "Size of sliding data window, in seconds, for maintaining moving average of input signal that forms the baseline for the digitization",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            }
        ],
        "variableBindings": {
            "InputSignal": {
                "bindingType": "None"
            },
            "Mode": {
                "bindingType": "None"
            },
            "CalibrationDuration": {
                "bindingType": "Number",
                "value": "10"
            },
            "CalibrationTimeSlice": {
                "bindingType": "Number",
                "value": "2"
            },
            "CalibrationOffset": {
                "bindingType": "Number",
                "value": "0.05"
            },
            "ThresholdManualOverride": {
                "bindingType": "String",
                "value": "DISABLED"
            },
            "ManualDigitizingThreshold": {
                "bindingType": "None"
            },
            "InputSignalMovingAverageWindowSize": {
                "bindingType": "Number",
                "value": "10"
            }
        }
    },
    "Range": {
        "internalName": "Range",
        "name": "Range",
        "desc": "Set the Tag's value as follows: a) If Min <= Max: 1 if the given Input is in the given range (inclusive), and 0 otherwise, b) If Min > Max:  1 if the given Input is outside the given range (inclusive), and 0 otherwise.",
        "category": "Comparative Logic",
        "version": "1.0.1",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 25,
        "variableDefs": [
            {
                "name": "Operand",
                "displayName": "Operand",
                "desc": "Value to check if in range",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "MinValue",
                "displayName": "Min Value",
                "desc": "Minimum value of the range",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "MaxValue",
                "displayName": "Max Value",
                "desc": "Maximum value of the range",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            }
        ],
        "variableBindings": {
            "Operand": {
                "bindingType": "None",
                "value": ""
            },
            "MinValue": {
                "bindingType": "None",
                "value": ""
            },
            "MaxValue": {
                "bindingType": "None",
                "value": ""
            }
        }
    },
    "RetentiveOnDelayTimer": {
        "internalName": "RetentiveOnDelayTimer",
        "name": "Retentive On Delay Timer",
        "desc": "Sets the Tag's value based on the Done state of a Timer that Implements the PLC Retentive On Delay Timer (RTO).",
        "category": "Timers",
        "version": "1.0.1",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 75,
        "variableDefs": [
            {
                "name": "Preset",
                "displayName": "Timer Preset",
                "desc": "Duration of Timer in seconds",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Trigger",
                "displayName": "Trigger",
                "desc": "Input signal that controls the starting and stopping of the timer",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Reset",
                "displayName": "Reset",
                "desc": "Input signal that controls the resetting of the timer once it completes timing",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            }
        ],
        "variableBindings": {
            "Preset": {
                "bindingType": "None",
                "value": ""
            },
            "Trigger": {
                "bindingType": "None",
                "value": ""
            },
            "Reset": {
                "bindingType": "None",
                "value": ""
            }
        }
    },
    "Selector": {
        "internalName": "Selector",
        "name": "Selector",
        "desc": "Set the Tag's value to the result of the given aggregation function on the given inputs.",
        "category": "Selectors",
        "version": "1.0.2",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 25,
        "variableDefs": [
            {
                "name": "Operation",
                "displayName": "Operation",
                "desc": "Operation to perform on the given operands",
                "ioType": "input",
                "dataType": "String",
                "enumEntries": [
                    "MIN",
                    "MAX"
                ],
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Inputs",
                "displayName": "Inputs",
                "desc": "Array of inputs",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true,
                "isArray": true,
                "minElements": 1
            }
        ],
        "variableBindings": {
            "Operation": {
                "bindingType": "String",
                "value": "MIN"
            },
            "Inputs": [
                {
                    "bindingType": "None",
                    "value": ""
                }
            ]
        }
    },
    "SquareWaveOutput": {
        "internalName": "SquareWaveOutput",
        "name": "Square Wave Output",
        "desc": "Set the Tag's value to emit a square wave defined by the given Duty Cycle and Period. When the Tag's value is plotted over time the result is a square wave. Used, for example, to control power going to a pump to control dosage rate.",
        "category": "Controllers",
        "version": "1.0.1",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 75,
        "variableDefs": [
            {
                "name": "DutyCycle",
                "displayName": "Duty Cycle",
                "desc": "Square wave\u00e2\u20ac\u2122s Duty Cycle as a percentage",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Period",
                "displayName": "Period",
                "desc": "Square wave\u00e2\u20ac\u2122s Period in seconds",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            }
        ],
        "variableBindings": {
            "DutyCycle": {
                "bindingType": "None",
                "value": ""
            },
            "Period": {
                "bindingType": "None",
                "value": ""
            }
        }
    },
    "StaticRodLoadNonTandem": {
        "internalName": "StaticRodLoadNonTandem",
        "name": "Static Rod Load (Non-Tandem)",
        "desc": "WARNING-Static Rod Loads are approximate and should not be used to program kills. This is a basic Static Rod Load calculation for non-tandem cylinders. All fields are required.",
        "category": "Compressor Analytics",
        "version": "2.1.1",
        "maxScanErrorThreshold": 10,
        "requiredAccessLevel": 25,
        "variableDefs": [
            {
                "name": "CalculationType",
                "displayName": "Calculation Type",
                "desc": "Force to calculate",
                "dataType": "String",
                "enumEntries": [
                    "Compression",
                    "Tension"
                ]
            },
            {
                "name": "SuctionPressure",
                "displayName": "Suction Pressure",
                "desc": "Stage Suction Pressure as reported by pressure transducer (psig)",
                "dataType": "Number"
            },
            {
                "name": "DischargePressure",
                "displayName": "Discharge Pressure",
                "desc": "Stage Discharge Pressure as reported by pressure transducer (psig)",
                "dataType": "Number"
            },
            {
                "name": "SuctionPressureDrop",
                "displayName": "Suction Pressure Drop",
                "desc": "Approximate pressure drop between suction transducer location and internals of cylinder (%)",
                "dataType": "Number"
            },
            {
                "name": "DischargePressureDrop",
                "displayName": "Discharge Pressure Drop",
                "desc": "Approximate pressure drop between discharge transducer location and internals of cylinder (%)",
                "dataType": "Number"
            },
            {
                "name": "CylinderDiameter",
                "displayName": "Cylinder Diameter",
                "desc": "Diameter of Cylinder (in)",
                "dataType": "Number"
            },
            {
                "name": "PistonRodDiameter",
                "displayName": "Piston Rod Diameter",
                "desc": "Diameter of Piston Rod (in)",
                "dataType": "Number"
            },
            {
                "name": "CylinderAction",
                "displayName": "Cylinder Action",
                "desc": "Cylinder action",
                "dataType": "String",
                "enumEntries": [
                    "Single-CE",
                    "Single-HE",
                    "Double"
                ]
            },
            {
                "name": "Elevation",
                "displayName": "Elevation",
                "desc": "Elevation (ft)",
                "dataType": "Number",
                "required": true
            }
        ],
        "variableBindings": {
            "CalculationType": {
                "bindingType": "None",
                "value": ""
            },
            "SuctionPressure": {
                "bindingType": "None",
                "value": ""
            },
            "DischargePressure": {
                "bindingType": "None",
                "value": ""
            },
            "SuctionPressureDrop": {
                "bindingType": "Number",
                "value": "6"
            },
            "DischargePressureDrop": {
                "bindingType": "Number",
                "value": "6"
            },
            "CylinderDiameter": {
                "bindingType": "None",
                "value": ""
            },
            "PistonRodDiameter": {
                "bindingType": "None",
                "value": ""
            },
            "CylinderAction": {
                "bindingType": "None",
                "value": ""
            },
            "Elevation": {
                "bindingType": "Number",
                "value": "0"
            }
        }
    },
    "StaticRodLoadTandem": {
        "internalName": "StaticRodLoadTandem",
        "name": "Static Rod Load (Tandem)",
        "desc": "WARNING-Static Rod Loads are approximate and should not be used to program kills. This is a basic Static Rod Load calculation for tandem cylinders. All fields are required.",
        "category": "Compressor Analytics",
        "version": "2.1.1",
        "maxScanErrorThreshold": 10,
        "requiredAccessLevel": 25,
        "variableDefs": [
            {
                "name": "CalculationType",
                "displayName": "Calculation Type",
                "desc": "Force to calculate",
                "dataType": "String",
                "enumEntries": [
                    "Compression",
                    "Tension"
                ]
            },
            {
                "name": "SuctionPressureHeadEnd",
                "displayName": "Suction Pressure (Head End)",
                "desc": "Stage Suction Pressure as reported by pressure transducer (psig)",
                "dataType": "Number"
            },
            {
                "name": "SuctionPressureCrankEnd",
                "displayName": "Suction Pressure (Crank End)",
                "desc": "Stage Suction Pressure as reported by pressure transducer (psig)",
                "dataType": "Number"
            },
            {
                "name": "DischargePressureHeadEnd",
                "displayName": "Discharge Pressure (Head End)",
                "desc": "Stage Discharge Pressure as reported by pressure transducer (psig)",
                "dataType": "Number"
            },
            {
                "name": "DischargePressureCrankEnd",
                "displayName": "Discharge Pressure (Crank End)",
                "desc": "Stage Discharge Pressure as reported by pressure transducer (psig)",
                "dataType": "Number"
            },
            {
                "name": "InternalPressure",
                "displayName": "Internal Pressure",
                "desc": "Internal Pressure (psig)",
                "dataType": "Number"
            },
            {
                "name": "SuctionPressureDrop",
                "displayName": "Suction Pressure Drop",
                "desc": "Approximate pressure drop between suction transducer location and internals of cylinder (%)",
                "dataType": "Number"
            },
            {
                "name": "DischargePressureDrop",
                "displayName": "Discharge Pressure Drop",
                "desc": "Approximate pressure drop between discharge transducer location and internals of cylinder (%)",
                "dataType": "Number"
            },
            {
                "name": "CylinderDiameterHeadEnd",
                "displayName": "Cylinder Diameter (Head End)",
                "desc": "Diameter of Cylinder (in)",
                "dataType": "Number"
            },
            {
                "name": "CylinderDiameterCrankEnd",
                "displayName": "Cylinder Diameter (Crank End)",
                "desc": "Diameter of Cylinder (in)",
                "dataType": "Number"
            },
            {
                "name": "PistonRodDiameter",
                "displayName": "Piston Rod Diameter",
                "desc": "Diameter of Piston Rod (in)",
                "dataType": "Number"
            },
            {
                "name": "CylinderAction",
                "displayName": "Cylinder Action",
                "desc": "Cylinder action",
                "dataType": "String",
                "enumEntries": [
                    "Single-CE",
                    "Single-HE",
                    "Double"
                ]
            },
            {
                "name": "Elevation",
                "displayName": "Elevation",
                "desc": "Elevation (ft)",
                "dataType": "Number",
                "required": true
            }
        ],
        "variableBindings": {
            "CalculationType": {
                "bindingType": "None",
                "value": ""
            },
            "SuctionPressureHeadEnd": {
                "bindingType": "None",
                "value": ""
            },
            "SuctionPressureCrankEnd": {
                "bindingType": "None",
                "value": ""
            },
            "DischargePressureHeadEnd": {
                "bindingType": "None",
                "value": ""
            },
            "DischargePressureCrankEnd": {
                "bindingType": "None",
                "value": ""
            },
            "InternalPressure": {
                "bindingType": "None",
                "value": ""
            },
            "SuctionPressureDrop": {
                "bindingType": "Number",
                "value": "6"
            },
            "DischargePressureDrop": {
                "bindingType": "Number",
                "value": "6"
            },
            "CylinderDiameterHeadEnd": {
                "bindingType": "None",
                "value": ""
            },
            "CylinderDiameterCrankEnd": {
                "bindingType": "None",
                "value": ""
            },
            "PistonRodDiameter": {
                "bindingType": "None",
                "value": ""
            },
            "CylinderAction": {
                "bindingType": "None",
                "value": ""
            },
            "Elevation": {
                "bindingType": "Number",
                "value": "0"
            }
        }
    },
    "TaskCommandTrigger": {
        "internalName": "TaskCommandTrigger",
        "name": "Task Command Trigger",
        "desc": "Executes the specified Task Command on a rising edge of the Trigger input.",
        "category": "Specialty",
        "version": "1.0.2",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 75,
        "variableDefs": [
            {
                "name": "Trigger",
                "displayName": "Trigger",
                "desc": "Input signal that controls the triggering of the specified command",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "TaskName",
                "displayName": "Task Name",
                "desc": "Name of Task to send Command to",
                "ioType": "input",
                "dataType": "String",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Command",
                "displayName": "Command",
                "desc": "Name of Command to send to Task",
                "ioType": "input",
                "dataType": "String",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Parameters",
                "displayName": "Parameters",
                "desc": "Parameters to pass along with the Command",
                "ioType": "input",
                "dataType": "String",
                "group": "Inputs",
                "required": false
            }
        ],
        "variableBindings": {
            "Trigger": {
                "bindingType": "None"
            },
            "TaskName": {
                "bindingType": "String",
                "value": ""
            },
            "Command": {
                "bindingType": "String",
                "value": ""
            },
            "Parameters": {
                "bindingType": "String",
                "value": ""
            }
        }
    },
    "TimedPulseTimer": {
        "internalName": "TimedPulseTimer",
        "name": "Timed Pulse Timer",
        "desc": "Sets the Tag's value based on the Done state of a Timer that Implements the PLC Timed Pulse (TP).",
        "category": "Timers",
        "version": "1.0.1",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 75,
        "variableDefs": [
            {
                "name": "Preset",
                "displayName": "Timer Preset",
                "desc": "Duration of Timer in seconds",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Trigger",
                "displayName": "Trigger",
                "desc": "Input signal that controls the starting and stopping of the timer",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            }
        ],
        "variableBindings": {
            "Preset": {
                "bindingType": "None",
                "value": ""
            },
            "Trigger": {
                "bindingType": "None",
                "value": ""
            }
        }
    },
    "Timed Ramp Output": {
        "internalName": "Timed Ramp Output",
        "name": "Timed Ramp Output",
        "desc": "Sets the Output to the given Target Value at the given rate. Primary use is for interfacing an Analog Output with proportional valves to control the rate at which the valve changes position to avoid slamming open/shut.",
        "category": "Controllers",
        "version": "1.0.2",
        "maxScanErrorThreshold": "1",
        "requiredAccessLevel": 75,
        "variableDefs": [
            {
                "name": "TargetValue",
                "displayName": "Target Value",
                "desc": "Value to set Output to",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "RateOfChange",
                "displayName": "Rate of Change",
                "desc": "Rate in engineering units per millisecond at which to change the Output until it reaches Target Value",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            }
        ],
        "variableBindings": {
            "TargetValue": {
                "bindingType": "Number",
                "value": "50"
            },
            "RateOfChange": {
                "bindingType": "Number",
                "value": "5"
            }
        }
    },
    "ToggleOutput": {
        "internalName": "ToggleOutput",
        "name": "Toggle Output",
        "desc": "Toggle the Tag's value when the given Toggling Input's value goes from 0 to 1. The Tag's value will stay latched at its current value until the Toggling Input again goes from 0 to 1. Typically used to implement a toggling pushbutton. Two toggling inputs are provided \u00e2\u20ac\u201c the second one is optional and can be used to implement a combination of physical and virtual pushbuttons to control the state of an output.",
        "category": "Control",
        "version": "1.0.1",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 75,
        "variableDefs": [
            {
                "name": "TogglingInput1",
                "displayName": "Toggling Input 1",
                "desc": "Input that toggles this Tag's value on a rising edge.",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "TogglingInput2",
                "displayName": "Toggling Input 2",
                "desc": "Input that toggles this Tag's value on a rising edge.",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": false
            }
        ],
        "variableBindings": {
            "TogglingInput1": {
                "bindingType": "None",
                "value": ""
            },
            "TogglingInput2": {
                "bindingType": "None",
                "value": ""
            }
        }
    },
    "TTDTDXMRunStatus": {
        "internalName": "TTDTDXMRunStatus",
        "name": "TTD/TDXM Run Status",
        "desc": "Maps the fault state of one or two TDXMs into the Run Status of a TTD.",
        "category": "Compressor Analytics",
        "version": "2.0.1",
        "maxScanErrorThreshold": "2",
        "requiredAccessLevel": 25,
        "variableDefs": [
            {
                "name": "TTDStatusTag",
                "displayName": "TTD Status Tag",
                "desc": "TTD Status Modbus Register",
                "dataType": "TagDef"
            },
            {
                "name": "TDXM1StatusTag",
                "displayName": "TDXM1 Status Tag",
                "desc": "TDXM1 Status Modbus Register",
                "dataType": "TagDef"
            },
            {
                "name": "TDXM1Channel",
                "displayName": "TDXM1 Channel",
                "desc": "Channel Number on TTD that TDXM1 is connected to",
                "dataType": "Number"
            },
            {
                "name": "TDXM1CodeOffset",
                "displayName": "TDXM1 Code Offset",
                "desc": "Offset to use for TDXM1 codes in shutdown legend",
                "dataType": "Number"
            },
            {
                "name": "TDXM2StatusTag",
                "displayName": "TDXM2 Status Tag",
                "desc": "TDXM2 Status Modbus Register",
                "dataType": "TagDef"
            },
            {
                "name": "TDXM2Channel",
                "displayName": "TDXM2 Channel",
                "desc": "Channel Number on TTD that TDXM2 is connected to",
                "dataType": "Number"
            },
            {
                "name": "TDXM2CodeOffset",
                "displayName": "TDXM2 Code Offset",
                "desc": "Offset to use for TDXM2 codes in shutdown legend",
                "dataType": "Number"
            }
        ],
        "variableBindings": {
            "TTDStatusTag": {
                "bindingType": "None"
            },
            "TDXM1StatusTag": {
                "bindingType": "None"
            },
            "TDXM1Channel": {
                "bindingType": "Number",
                "value": "1"
            },
            "TDXM1CodeOffset": {
                "bindingType": "Number",
                "value": "100"
            },
            "TDXM2StatusTag": {
                "bindingType": "None"
            },
            "TDXM2Channel": {
                "bindingType": "Number",
                "value": "0"
            },
            "TDXM2CodeOffset": {
                "bindingType": "Number",
                "value": "200"
            }
        }
    },
    "UnaryBitwiseOperation": {
        "internalName": "UnaryBitwiseOperation",
        "name": "Unary Bitwise Operation",
        "desc": "Set the Tag's value to the result of the selected operation on the given operand.",
        "category": "Bitwise Logic",
        "version": "1.0.1",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 25,
        "variableDefs": [
            {
                "name": "Operation",
                "displayName": "Operation",
                "desc": "Operation to perform on the given operands",
                "ioType": "input",
                "dataType": "String",
                "enumEntries": [
                    "NOT"
                ],
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Operand",
                "displayName": "Operand",
                "desc": "Operand",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            }
        ],
        "variableBindings": {
            "Operation": {
                "bindingType": "String",
                "value": "NOT"
            },
            "Operand": {
                "bindingType": "None",
                "value": ""
            }
        }
    },
    "UnaryConditionalOperation": {
        "internalName": "UnaryConditionalOperation",
        "name": "Unary Conditional Operation",
        "desc": "Set the Tag's value to the result of the selected operation on the given operand. The Tag's value is set to 0 if the condition is False and 1 if True. To determine the Boolean value of an operand for evaluation, if the operand is 0 then it is considered False, and if the operand is non-zero it is considered True.",
        "category": "Conditional Logic",
        "version": "1.0.1",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 25,
        "variableDefs": [
            {
                "name": "Operation",
                "displayName": "Operation",
                "desc": "Operation to perform on the given operands",
                "ioType": "input",
                "dataType": "String",
                "enumEntries": [
                    "NOT"
                ],
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Operand",
                "displayName": "Operand",
                "desc": "Operand",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            }
        ],
        "variableBindings": {
            "Operation": {
                "bindingType": "String",
                "value": "NOT"
            },
            "Operand": {
                "bindingType": "None",
                "value": ""
            }
        }
    },
    "UnaryMathOperation": {
        "internalName": "UnaryMathOperation",
        "name": "Unary Math Operation",
        "desc": "Set the Tag's value to the result of the selected operation on the given operand.",
        "category": "Math",
        "version": "1.0.1",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 25,
        "variableDefs": [
            {
                "name": "Operation",
                "displayName": "Operation",
                "desc": "Operation to perform on the given operands",
                "ioType": "input",
                "dataType": "String",
                "enumEntries": [
                    "ABS",
                    "INV",
                    "SQRT",
                    "NEG",
                    "FLOOR",
                    "CEIL",
                    "MOVE",
                    "LOG",
                    "LOG10",
                    "SIN",
                    "COS",
                    "TAN",
                    "SEC",
                    "CSC",
                    "COT",
                    "ASIN",
                    "ACOS",
                    "ATAN",
                    "TO RADIANS",
                    "TO DEGREES"
                ],
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Operand",
                "displayName": "Operand",
                "desc": "Operand",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            }
        ],
        "variableBindings": {
            "Operation": {
                "bindingType": "String",
                "value": "ABS"
            },
            "Operand": {
                "bindingType": "None",
                "value": ""
            }
        }
    },
    "WaterMark": {
        "internalName": "WaterMark",
        "name": "Water Mark",
        "desc": "Set the Tag's value to the low or high water mark for the given input.",
        "category": "Selectors",
        "version": "1.0.1",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 25,
        "variableDefs": [
            {
                "name": "InputValue",
                "displayName": "Input Value",
                "desc": "Input value for which to determine low or high water mark",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "WaterMarkType",
                "displayName": "Water Mark Type",
                "desc": "Determines whether to calculate the low or high water mark",
                "ioType": "input",
                "dataType": "String",
                "enumEntries": [
                    "LOW",
                    "HIGH"
                ],
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Reset",
                "displayName": "Reset",
                "desc": "Signal that resets the water mark on a rising edge",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            }
        ],
        "variableBindings": {
            "InputValue": {
                "bindingType": "None",
                "value": ""
            },
            "WaterMarkType": {
                "bindingType": "String",
                "value": "LOW"
            },
            "Reset": {
                "bindingType": "None",
                "value": ""
            }
        }
    },
    "WaveAnalyzer": {
        "internalName": "WaveAnalyzer",
        "name": "Wave Analyzer",
        "desc": "Set the Tag's value to a selected characteristic of the given digital (square) or analog (sinusoidal) wave input. Maintains a sliding window of waves and calculates the moving average of the requested characteristic.",
        "category": "Statistics and Signal Processing",
        "version": "1.0.1",
        "maxScanErrorThreshold": "0",
        "requiredAccessLevel": 75,
        "variableDefs": [
            {
                "name": "WaveInput",
                "displayName": "Wave Input",
                "desc": "Input that contains digital square wave signal or sinusoidal analog wave",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "WaveType",
                "displayName": "Wave Type",
                "desc": "Defines the kind of wave to expect for the Wave Input",
                "ioType": "input",
                "dataType": "String",
                "enumEntries": [
                    "DIGITAL",
                    "ANALOG"
                ],
                "group": "Inputs",
                "required": true
            },
            {
                "name": "NumSamples",
                "displayName": "Num Samples",
                "desc": "Number of wave samples to maintain in sliding window",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Characteristic",
                "displayName": "Characteristic",
                "desc": "Wave characteristic to compute",
                "ioType": "input",
                "dataType": "String",
                "enumEntries": [
                    "FREQUENCY",
                    "PERIOD",
                    "DUTY CYCLE",
                    "ON TIME",
                    "OFF TIME"
                ],
                "group": "Inputs",
                "required": true
            },
            {
                "name": "Reset",
                "displayName": "Reset",
                "desc": "Signal that resets the statistics",
                "ioType": "input",
                "dataType": "Number",
                "group": "Inputs",
                "required": false
            }
        ],
        "variableBindings": {
            "WaveInput": {
                "bindingType": "None",
                "value": ""
            },
            "WaveType": {
                "bindingType": "String",
                "value": "DIGITAL"
            },
            "Characteristic": {
                "bindingType": "String",
                "value": "PERIOD"
            },
            "NumSamples": {
                "bindingType": "Number",
                "value": "10"
            },
            "Reset": {
                "bindingType": "None",
                "value": ""
            }
        }
    }
};
